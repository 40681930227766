@import '../base/_color';

$BodyBgColour: $lightwhite;
$ContainerBgColor: $PureWhite;
$label: $DarkOrange;
$SelectionBgColor:$black;
$SelectionColor:$accent;
$color-header:$black;
$color-container-bg: $PureWhite;
$color-header-bg: $lightsemiBlack;
$color-header-text: $PureWhite;
$color-bg-row-header: $lightsemigrey;
$color-text: $wetapsat;
$color-colum-bg: $lightwhite;
$color-logo: $PureWhite;
$color-border-gold:$gold;
$color-border-blue:$blue;
$color-border-red:$DarkOrange;
$color-row-border:$lightraygrey;
$color-base: $pureblack; //Base Color of application
$color-address: $lightgrey;
$color-steeper-bg:$stepper-color;
$color-subheader: $semigrey; //Using Setting Header
$color-Setting-boder: $semilightgreyWhite; //Using Setting Page border
$color-setting-button: $HexaGrey;
$color-button-color: $PureWhite; //Using Setting button color
$color-inner-Header: $HexaLightWhite; //Using Setting Inner Header
$color-online: $StrongGreen;
$color-offline: $StrongRed;
$color-active: $Green;
$color-inactive: $Red;
$color-table-black: $TableBlack; //Using Setting table
$color-header-bg-gold:$Darkgold;
$color-secondary-button-hover: $lightgoldcircle;
$color-secondary-button-pressed: $secondaryButtonPressed;

$setup-header-day-bg: $lightSilverGrey; //Next four colors are Used in for Lunch-setup
$setup-hover-bg: $darkSilver;
$setup-header-bg: $brightGrey;
$color-table-header-bg: $lightSeaBlue;
$color-missing-service: $StrongRed;
$color-up-arrow: $darkGreen;
$color-down-arrow: $darkRed;
$color-yield1-bg: $mintCream;
$color-yield2-bg: $paleYellow;
$color-yield1: $seaBlue;
$color-yield2: $lightOrange;
$color-yield3: $lightGreen;
$color-filter-header-bg: $whiteSmoke;
$color-theme-app:$color-theme;
$color-form-field:$pureblack;
$color-form-bgColor:$formBackGroundGray;
$color-tabText-gray: $tabTextGray;
$color-white-opacity:$whiteOpacity;
$color-light-gray:$grayLight;
$color-appointment:$appointmentColor;
$color-scheduled:$scheduled;
$color-checkedout:$checkedout;
$color-checkedout-bg:$checkedOut-bg;
$color-upcoming:$upcoming-bg;
$color-clients:$clients;
$color-therapist:$therapist;
$color-tab-content-background: $tabBackGroundGray;
$color-darkGray:$darkGray;
$color-gold: $gold;
$color-noShow:$noShow;
$color-border-gray:$borderGray;
$color-border-grey-fullopacity:$border-grey-fullopacity;
$color-border-lightgray:$borderlightGray;
$color-border-gray-opacity5:$border-grey-opacity5;
$color-radio-gray:$grayRadio;
$color-input-holder:$formFieldGray;
$color-row-gray:$recentRowGray;
$color-near-checkout:$nearlyCheckOut;
$color-border-gray:$appoinment-actions-gray;
$color-menu-gray:$appointment-actions-menu;
$color-shop-border:$shop-border-bottom;
$color-white-transparent:$white-transparent;
$color-border-shop:$border-shop;
$color-left-arrow:$left-arrow-gray;
$color-text-color:$textblack;
$color-steeper-bg:$stepper-color;
$color-button-split:$button-split-color;
$color-more-legend:$more-legend;
$color-success-green:$success-green;
$color-addons-border: $gold;
$color-grey-border: $linkWater;
$color-grid-border: $inputPlaceholderGray;
$color-slots:$lightSeaGreen;
$color-female:$neonPink;
$color-male:$ceruleanBlue;
$color-disabled: $HexaWhite;
$color-li-selected:$floralWhite;
$disabled-row: $aliceBlue;
$disabled-button: $grey;
$disabled-select-underline: $lavender;
$color-staff-border:$suvaGrey;
$color-label:$tuna;
$color-logo-gold:$logo-gold;
$color-blac-opac8:$black-opacity8;
$color-btn-hover: $lightyellow;
$color-tertiary-btn-hover: $tertiarygold;
$color-tertiary-btn-press: $tertiarygolddark;
$color-reports-border: $black-opacity;
$table-header-bg: $formBackGroundGray;
$color-blue-color:$warning-blue;
$card-selection-bg-color: $Beige;
$color-tick-green: $tick-color;
$color-cross-color:$cross-color;
$color-therapist-border: $lightGray;
$label-secondary-color: $formFieldGray;
$color-border-primary: $tabTextGray;
$color-border-secondary: $color-light-grey;
$color-button-secondary-disabled: $secondaryButtonGrey;
$color-searchbox-bg:$searchbox-bg;
$color-client-bg: $snow;
$color-therapist-border: $lightGray;
$form-field-color: $black;
$color-th-bg: $thbg;
$color-save-button: $PureWhite;
$mat-tab-labels: $PureWhite;
$reportBG: $formBackGroundGray;
$color-reports-bg:$reports-bgColor;
$color-reports-background: $PureWhite;
$color-grey-resolution:$screen-grey;
$color-resolution-grey: $resolution-grey;
$color-resolution-black: $resolution-black;
$spa-wizard-total-amount-bg: $light-white;
$toggleBg-color:$toggleBG;
$color-shop-bg: $shop-background;
$border-color: $dullgrey;
$split-button-border-color: $formBackGroundGray;

$global-search-header:  $grey-global;
$global-search-white: $white-global;
$global-search-lightyellow: $light-yellow;
$global-search-overlay: $light-grey-global;

$icon-color:$gold;

$package-selected-color: $papayawhip;
/*****Button - Width & Height -******/
$primary-btn-width: 118px;
$primary-btn-height: 40px;
$primary-btn-border-radius: 3px;
$spa-circle-width:28px;
$spa-circle-height:28px;
$spa-small-btn-height:30px;
$spa-inside-tb-btn: 20px;
$spa-lg-btn-height: 34px;
$spa-savebtn-width: 120px;
$spa-savebtn-min-width: 88px;




/*****Font-Size******/

$font-size2: 2px;
$font-size4: 4px;
$font-size6: 6px;
$font-size8: 8px;
$font-size10: 10px;
$font-size12: 12px;
$font-size14: 14px;
$font-size16: 16px;
$font-size18: 18px;
$font-size20: 20px;
$font-size22: 22px;
$font-size28: 28px;
$font-size40: 40px;
$font-size11: 11px;




//****************Font family*****************//


$lato-web-web: "LatoWeb";
$lato-web-medium: "LatoWebMedium";
$lato-web-bold: "LatoWebBold";



//********Form - Field Configuration***************//
$font-size-mat-form-field-input: $font-size14;

$label-color: $custom-label-color;
$value-color: $custom-value-color;
$header-bg: $header-bg;
$theme-base-text-color: $pureblack;
