@import '../base/_colors';
@import '../base/typography';

$color-black: $fontBlack;
$color-base: $fontBlack;
$color-white: $buttonWhite;
$color-seat-reserved: $secondaryBlue;
$color-seat-blocked: $secondaryDarkBrown;
$color-seat-arrived: $secondaryRed;
$color-seat-seated: $statusGreen;
$color-seat-cancelled: $secondaryDarkGray;
$color-button-action:$primaryDarkGreen;
$color-button-warning: $secondaryRed;
$color-button-darkGray: $buttonDarkGray;
$color-guest-view-count: $font-gray-opacity-8;
$color-button-lightGray: $light-gray-bg;
$color-breakfast-indicator: $statusBlue;
$color-lunch-indicator: $statusOrange;
$color-dinner-indicator: $statusPurple;
$color-vipicon-status: $secondaryPurple;
$color-spent-details-bg: $secondaryPurple;
$color-spent-details: $primary-white;
$color-contact-bg: $font-gray-opacity-2;
$color-tab-background: $silver-sand;
///$color-favouriteIcon-status:$secondaryLightBrown;
$color-waitlist-indicator: $secondaryOrange;
$color-accordian-border-bottom: $button-dark-gray-opacity;
$color-status-box-border: $status-medium-gray-opacity;
$color-button-shadow: $light-black;
$color-vip-border: $spanish-violet;
$color-tables-indicator: $statusGold;
$color-tablesstatus-indicator :$statusDarkGray;
$color-seat-status: $brightGray;
$color-statusScreen-bg: $ghost-white;
$color-icon-indicator: $iconGray;
$color-font-lightGray: $fontLightGray;
$color-font-darkGray: $fontDaryGray;
$color-lightGreen: $primaryLightGreen;
$color-darkGray: $primaryDarkGray;
$color-lightGray: $primaryLightGray;
$color-mediumGray: $primaryMediumGray;
$color-dullGray: $primaryDimGray;
$color-darkBlack: $primaryDarkBlack;
$color-lightBlack: $primaryLightBlack;
$color-toggle-red: $toggleRed;
$color-toggle-redssss: $toggleRed;
$color-minLoader-lightGray: $activityMinLoaderlightGray;
$color-side-panel: $sidePanelGray;
$color-maxLoader-darkGray: $activityMinLoaderDarkGray;
$color-light-blue: $chatHistoryemailBlue;
$color-incomingSMS-gray: $chatHistorySMSOutgoingGray;
$color-outgoingSMS-green: $chatHistorySMSIncomingGreen;
$selected-reservation-color: $buttonWhite;
$status-bg-color: $buttonWhite;
$color-party-actions:$buttonWhite;
$color-form-field-border: $oslo-gray;
$color-form-field-text: $activityMinLoaderDarkGray;
$color-form-field-arrow: $font-gray-opacity;
$color-reservation-border: $font-gray-opacity;
$color-header-notofication-text: $primaryDarkGreen;
$color-notification-header-border: $french-gray;
$color-canvas-border: $font-gray-opacity-1;
$color-primary-info: $smokyBlack;
$color-add-border-color: $green-crayola;
$color-loader-bg: $primaryDarkGreen;
$color-message-bg: $primaryDarkGreen;
$color-tooltip:$primaryDarkGreen;
$color-button-light-gray: $buttonLightGray;
$color-send-btn:  $primaryDarkGreen;
$color-communal-card: $primaryDarkGreen;
$color-black-opacity: $black-opacity;
/*** Login Page Variables ***/
$color-logo-image-bg: $gainsboro;
$color-logo-image-border: $whisper;
$color-login-bg: $light-ghost-white;

/*** Aside Menu Variables ***/
$color-aside-bar: $primaryDarkBlack;
$color-menu-text: $white-transparent;
$color-scroll-menu:$stack;

/*** Header Variables ***/
$color-header-icons: $primary-white;
$color-messages-unread-bg: $athens-gray;
$color-favourite: $secondaryPurple;

//Registration Page Colors
//Header
$color-header-text: #6A6A6A;
$color-button-border: #B0B1B2;
$color-sortby-text:#95989A;
$color-icon-border-color: #ABABAB;
$color-icon-color: #6A6A6A;
$color-iocn-unselect-border: #E8E8E8;
$color-header-controls-text: $color-white;
$color-header-controls-bg: $silverTree;
$color-header-border-bg:$light-gray-border;


//Content
$color-con-text-break: #c6c6c6;
$color-con-border-break: #EBEBEB;
$color-box-text-color: #2f3a36;
$color-box-bor-color: #dfe6ea;
$color-box-icon-color: #5093e1;
$color-box-num-text: #a9acad;
$color-box-num: #A9acad;
$color-text-color: $primaryDimGray;
$color-message: $raisin-black;
$color-guest-name: $black-opacity-text;

/*******Mat label colors**************/
$mat-tab-label-color: $fontBlack;
$pop-up-header-font-color: $buttonWhite;
$color-button-border: $Eucalyptus;
$color-icons: $french-gray;


/**********************Pop-up-configuration***************************/

$pop-up-header-height: 64px;
$pop-up-header-bg-color: $primaryDarkGreen;
$pop-up-header-font-color: $buttonWhite;


/*****Box model********/

$seat-box-bg-color: $primaryDarkGreen;
$seat-box-font-color: $primary-white;

/*****************Button - configure **************************/
$seat-secondary-button-color: $fontLightGray;


/********************Timeline***********************/
$color-grid-time-bg: $wild-sand;
$color-grid-bg-dark: $mercury;
$color-popup-contents-label: $black-opacity;
$color-timeline-table-header:$black-opacity-text;
$color-timeline-grid-border: $dove-gray-opacity;
$color-timeline-table-bg: $primary-white;
$color-timeline-button-border: $oslo-gray;
$color-timeline-button-text: $activityMinLoaderDarkGray;
$color-timeline-booking-text: $color-mediumGray;
$color-legend-text: $buttonDarkGray;
$color-non-operating: $wild-sand;
$color-timeline-btn-control-bg: $primary-white;
$color-timeline-btn-control-font: $primary-white;

/********************Side Nav****************************/
$color-guest-color: $outer-space;
$color-server-name: $activityMinLoaderDarkGray;
$color-lcoation-details: $activityMinLoaderDarkGray;
$color-seat-status-bg: $primary-white;
$color-reservation-status-timeline: $buttonDarkGray;
$color-aside-bg: $primaryDarkBlack;
$color-aside-selected-bg: $primaryLightBlack;
$color-aside-border: $primaryDarkBlack;
$color-time-indicator: $secondaryOrange;
$color-guest-entry-time: $secondaryBlue;

/**************************Floor Plan*********************/
$color-floor-table-data: $black-opacity;


/*********************************Media query breakpoint values************************************************/
$medium-min-width:480px;
$medium-max-width:768px;
$small-max-width:480px;
$large-min-width:1024px;
$large-max-width:1350px;

/*****************Font Size - configure **************************/
$label-font-size: $font-size-14;
$aside-menu-font: $font-size-14;
$slider-menu-font: $font-size-21;
$add-menu-font: $font-size-16;
$datepicker-menu-font: $font-size-20;
$page-title-font: $font-size-20;
$timeline-popup-label-font: $font-size-14;
$timeline-popup-data-font: $font-size-18;
$timeline-table-header: $font-size-12;
$timeline-button-text-font: $font-size-16;
$timeline-zoom-text-font: $font-size-11;
$timeline-booking-text-font: $font-size-14;

/*****************Font Family - configure **************************/
$page-title-family: $font-family-roboto-regular;
$timeline-table-header-font: $font-family-roboto-medium;
$timeline-booking-font-family: $font-family-roboto-light;


/*************************Side-Nav Details********************************/
$guest-intial-font: $font-size-26;
$guest-intial-font-family:$font-family-roboto-medium;
$guest-name-font-family: $font-family-roboto-regular;
$guest-intial-communal-font-family: $font-family-roboto-light;
$server-intial-font: $font-size-14;
$guest-name-font: $font-size-21;
$location-details-font: $font-size-15;
$reservation-status-name: $font-size-26;
$reservation-status-family: $font-family-roboto-light;
$reservation-status-timeline-font: $font-size-14;
$reservation-create-select-text: $font-size-20;
$reservation-create-day-text: $font-size-18;
$reservation-create-date-text: $font-size-24;
$reservation-create-date-font: $font-family-roboto-bold;
$reservation-guest-table-header-family: $font-family-roboto-medium;
$reservation-guest-table-header-font:  $font-size-16;
$guest-label-font: $font-size-14;


/***********************Login*******************************************/
$login-font-family: $font-family-roboto-light;
$color-login-placeholder: $primaryMediumGray;
$login-placeholder-font: $font-family-roboto-regular;
$color-login-text: $fontDaryGray;
$color-login-text: $oslo-gray-login;

/*************************Dialog Container***********************************/
$color-backdrop-bg: $alto-dark;
$color-tab-label: $black-opacity;
$color-tab-label-active: $fontBlack;
$color-tab-label-border: $statusMediumGray;

/*************************Reservations*******************************/
$color-reservation-border: $geyser;
$color-reservation-table-number-bg: $geyser-opacity;
$color-reservation-seperation-border: $athens-gray;
$color-reservation-header-border: $primaryDarkGray;
$color-reservation-button-border: $buttonLightGray;
$color-reservation-button-text: $activityMinLoaderDarkGray;
$color-reservation-date-text: $activityMinLoaderDarkGray;
$color-reservation-guest-table-header: $emperor;
$color-preference-text: $black-opacity;
$color-reservation-label: $black-opacity;
$color-toggle-border: $black-opacity;
$color-toggle-left-border: $dove-gray;
$color-toggle-bg: $primaryDarkGreen;
$color-toggle-text: $primary-white;
$color-chip-bg: $primaryDarkGreen;
$color-chip-text: $black-opacity-text;
$color-selected-print: $primaryDarkGreen;
$color-guest-table-border: $font-gray-opacity-25;
$color-guest-details-bg: $light-spanish-gray;
$color-visit-date: $chinese-black;
$color-ota-source: $secondaryOrange;
$color-primary-white: $primary-white;

/**********************Form Controls*******************************/
$color-btn-text: $primary-white;


/*********************Settings***********************************/
$color-settings-bg: $gray-border;
$color-settings-menu-border: $silver;
$color-search-bg: $primary-white;
$color-settings-menu-name: $mine-shaft;
$color-settings-slot: $black-opacity;
$color-table-border: $font-gray-opacity-1;
$color-table-bg: $gray97;
$color-table-header-color: $activityMinLoaderDarkGray;
$color-header-border-tableturns:$manatee-opacity;

/*********************Media Query Variables ***********************/
$small-tab-devices: 'small-tab-device';
$large-tab-devices: 'desktop-tab-device';
$small-height-devices: 'small-height-device';
$small-tab-resolution: 1300px;
$large-tab-resolution: 1280px;
$min-small-height-resolution: 768px;
$max-small-height-resolution: 900px;



/********************Buttons*********************************/
$color-primary-green-btn:$primaryDarkGreen;
$color-primary-green-btn-hover:$primaryHoverGreen;
$color-primary-green-btn-color: $primary-white;
$color-primary-green-btn-disabled: $primaryDisabledGreen;

$color-primary-red-btn:$buttonRed;
$color-primary-red-btn-hover:$primaryHoverRed;
$color-primary-red-btn-disabled: $primaryDisabledRed;

$color-secondary-btn-border:$primaryDarkGreen;
$color-secondary-btn-disabled:$primaryDisabledGreen;


$color-primary-purple-btn:$secondaryPurple;
$color-primary-purple-btn-hover:$secondaryPurple;
$color-primary-purple-btn-color: $primary-white;
$color-primary-purple-btn-disabled: $statusPurple;

$color-secondary-purple-btn-border:$secondaryPurple;
$color-secondary-purple-btn-disabled:$statusPurple;


/*******************Guest Book******************************/
$color-guest-details-tab-header:$primaryMediumGray;
$color-tab-border-bottom:$iron;
$color-tab-ink-bar:$primaryDarkGreen;
$color-spinner-outside: $statusMediumGray;
$color-table-text:$primaryDimGray;
$color-controls-bottom-border:$manatee-opacity;
$color-alphabets:$primaryDimGray;
$color-guest-email:$buttonDarkGray;
$color-guest-table-row-border:$dove-gray-opacity-2;
$color-guest-table-text-color: $primaryDimGray;
$color-guest-phone:$secondaryBlue;
$color-vip-selected:$darkGold;
$color-table-header-bg:$primary-white;
$color-table-row-selected:$slate-gray-opacity-1;
$color-disabled-icon: $disabledGrayColor;
$color-selected-server: $primaryDarkGreen;
$color-seated-status: $seated-color;
$activity-border-color: $light-silver-sand;
$email-message-bg: $light-lavender;
$sms-message-bg: $light-tea-green;
$color-add-btn: $primaryDarkGreen;
$color-date-color: $grey-color;
$color-image-edit:$primaryDarkGreen;
$color-server-table-border: $oslo-gray;
$color-server-icons-border: $dove-gray;
$color-remove-joined-table: $primaryDarkGreen;

/******Floor Plan******/
$color-action-btn: $primaryDarkGreen;

/**********Tables***********/
$color-canvas-text: $fontLightGray;
$color-canvas-border: $font-gray-opacity-1;

/*****Error msg****/
$color-error-msg: $error-red;
$color-empty-btn: $primary-white;

/******Timer Colors******/
$color-firsthalf-percent:green;
$color-secondhalf-percent:#FD9800;
$color-thirdhalf-percent:red;

/******MatTable row colors****/
$color-header-row: $header-row-color;
$color-header-cell: $header-row-cell;

/*******Switch Property********/
$color-tableheader-bg : $header-row-color;
$color-tableheader-fontcolor : $black-opacity-text;
$color-availabilitygrid-border : rgba(205, 209, 212, 0.4);
$color-reloadicon-bg : $primaryDarkGreen;
$color-reloadicon-font : $primary-white;

$color-foter-red: $primary-red;
$total-count-color: $total-count;
$total-covers-color: $total-covers;
$reservations-color: $reservations;
$walkins-color: $walkins;
$color-message-send-notifcation: $primaryDarkGreen;
$notes-icon-color: $thunderBird;
$color-secondary-btn-red: $tamarillo;
$color-secondary-btn-light-red:  $light-tamarillo;
$color-plainWhite-color:$primary-white;
$color-red-exclamation:$thunderBird;


/*****Seat Typography Colors****/
$color-default-round-icons: $granite-gray;  // #666666
$color-selected-round-icons: $dark-charcoal; // #333333
$color-disabled-icons: $silver; // #cccccc
$color-first-header-text: $primary-white; // #FFFFFF
$color-panel-header: $granite-gray;
$color-second-header-text: $dark-charcoal;
$color-third-header-text: $granite-gray;
$color-primary-text: $primary-white;
$color-secondary-text-fields: $dark-charcoal;
$color-tertiary-text-fields: $granite-gray;
$color-grid-header: $granite-gray;
$color-grid-content: $dark-charcoal;
$color-error-text-messages: $buttonRed;  // #F44336
$color-section-header: $granite-gray;
$color-seat-border: $silver; // #cccccc
$color-seat-icon: $granite-gray;
$color-seat-triple-dots: $silver;
$color-popover-header: $granite-gray;
$color-popover-content: $dark-charcoal;
$color-no-content: $granite-gray;
$color-seat-primary-bg: $sidePanelGray; // #F8F8F8
$color-seat-secondary-bg: $statusMediumGray; // #E8E8E8
$color-seat-notification-bg: $buttonRed;
$color-seat-theme: $primaryDarkGreen;
$color-seat-labels: $granite-gray;
$color-text-fields: $dark-charcoal;
$color-fourth-header-text: $dark-charcoal;
$color-box-shadow: $light-silver;
$color-guest-notes: $thunderBird;
$color-timeline-header: $granite-gray;
$color-timeline-content: $primary-white;
$color-primary-disabled: $primaryDisabledGreen;
$color-table-name:$primary-white;
$color-link-hover-color: $total-covers;


$color-view-log: $soft-blue;
$color-expand-row: $wild-sand;
$calendar-border-color: $grey-light-color;
$calendar-selectBg-color: $green-light-color;
$calendar-select-border-color: $green-border-color;
$calendar-mutedBg-color: $grey-bg-color;
$blocking-icon-color: $light-red-icon;
$current-date-bg: $primaryDarkGreen;
$current-date-color: $primary-white;
$muted-date-color: $grey-soft-color;
$accordion-border-color: $disabledGrayColor;

$payment-due-color: $dark-orange;

$payment-notpaid-color: $primary-red;

$payment-paid-color: $primaryDarkGreen;

$payment-waivedoff-color: $yellow-bright;

$payment-refunded-color: $dark-blue;

$package-highlight-color: $LightOrange;

$aside-menu-text: #5C635F;
$aside-menu-selected-bg: #E5F1E8;
$seat-primary-green: #158136;
$header-user-bg: $silver-foil;
$header-label-bg: rgba(164, 164, 164, 0.08);
$header-label-text-bg: #A4A4A4;