/* You can add global styles to this file, and also import other style files */
@use '@angular/material' as mat;

@import 'assets/sass/main';
@import "assets/sass/base/_typography.scss";
@import "assets/sass/base/_variables.scss";
@import "assets/icons/style.css";
@import "assets/sass/abstracts/mixins";
@import '~ngx-toastr/toastr.css';
@import 'assets/helpers/mixins';
@import 'assets/sass/components/buttons';
@import '~ngx-ui-switch/ui-switch.component.scss';

/* You can add global styles to this file, and also import other style files */
// @import "@angular/material/prebuilt-themes/indigo-pink.css";
@import '~ngx-ui-switch/ui-switch.component.scss';
@import '~ngx-print-element/styles.css';
@import './assets/icons/style.css';
@import './retail-styles.scss';
@import './assets/sass/base/_reset';
@import './assets/sass/base/_typography';
@import './assets/sass/layout/_header';
@import './assets/sass/helpers/_mixins';
@import './assets/sass/components/_buttons';
// @import '@angular/material/prebuilt-themes/deeppurple-amber.css';
// @import '@angular/material/theming';
@import './assets/sass/helpers/_variables.scss';
@import './assets/sass/abstracts/_variables.scss';

@import "assets/sass/main";
@import "assets/sass/abstracts/color";
@import "assets/sass/abstracts/variables";
@import "assets/sass/base/base";
@import "assets/icons/style.css";

@import 'simple-keyboard/build/css/index.css';
@import "assets/sass/rental-styles/rental.scss";
// @import 'assets/sass/themes/theme-config';
html {
  overflow: hidden !important;
}

body {
  font-family: Roboto-Regular;
  color: $color-base;
  position: fixed;
  width: 100%;
}

button {
  background: none;
}

.d-none {
  display: none;
}

.reserved {
  @include statusColor('reserved');
}

.capitalize {
  text-transform: capitalize;
}

.switch {
  border: none;
}

.body-bgcolor {
  //background-color: $theme-base-color !important;
}

.ag_form-control {
  padding-right: 15px;
}

.ag_button--primary:not[disabled]{
  background-color: $theme-base-color!important;
  color:$white!important;
}
.body-bordercolor {
  //border: 1px solid $theme-base-color !important;
}

.spa-primary-default-save {
  height: 36px;
  padding: 0 14px;
  color: $color-primary-green-btn-color !important;
  background: $color-primary-green-btn;
}

.spa-primary-disabled {
  background-color: $color-primary-green-btn-disabled;
  border: none;
  color: $color-white !important;
}

.spa-button-cancel {
  height: 40px !important;
  text-align: center !important;
  line-height: 40px !important;
  color: #ffff !important;
  border-radius: 3px !important;
  font-size: 14px !important;
  cursor: pointer;
  background: none !important;
  color: #000000 !important;

}

.uppercase {
  text-transform: uppercase;
}

.reservation-select-label,
.pop-up-label {
  font-size: $reservation-create-select-text;
  display: block;
  text-transform: capitalize;
}

.guest-form-label {
  @extend .seat-tertiary-text;
  margin-bottom: 8px;
}

perfect-scrollbar>.ps.ps--active-y>.ps__rail-y,
perfect-scrollbar>.ps.ps--active-x>.ps__rail-x {
  width: 0 !important;
}

perfect-scrollbar {
  .ps__rail-y {
    .ps__thumb-y {
      width: 5px !important;
    }
  }

  .ps__rail-x {
    .ps__thumb-x {
      height: 5px !important;
    }
  }
}


.ng-scrollbar-wrapper {
  --scrollbar-size: 8px;
  --scrollbar-hover-size: 8px;
}

.input-with-boundary {
  width: 188px;
  height: 38px;
  margin: 0 2px;
  border: 1px solid $silver-foil;
  border-radius: 4px;
  color: $color-form-field-text;
  padding: 6px;
  vertical-align: middle;
  line-height: 25px;
  font-size: 14px !important;

  @include breakpoint($small-tab-devices) {
    width: 160px;
  }

  .mat-form-field-infix {
    border-top: unset !important;
    padding: 0;

    .mat-select-value {
      color: $color-text-fields;
    }

    .mat-select-arrow {
      color: $color-text-fields;
    }

    .mat-form-field-label {
      display: none !important;
      font-size: 14px;

      &.mat-empty {
        display: block !important;
        line-height: 18px;
      }
    }
  }

  &.mat-focused {
    .mat-form-field-label {
      display: none !important;

      &.mat-empty {
        display: none !important;
      }
    }
  }

  .mat-form-field-underline {
    display: none;
  }
}

.headercontainer__selection-panel{
  .input-with-boundary .mat-form-field-infix .mat-form-field-label{
    &.mat-empty {
    //  display: block !important;
      line-height: 30px;
    }
  }
}

.mat-option {
  color: $color-text-fields;
}

.mat-option.mat-selected:not(.mat-option-disabled) {
  color: $color-text-fields !important;
}

.mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: $color-seat-secondary-bg;
}

.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: $theme-base-color !important;
}

.addNewGuestTitle {
  background: $color-button-action;
  height: 65px;
  text-align: center;
  color: $color-white;

  .icon-Path-490:before {
    padding-right: 5px;
    font-family: 'icomoon' !important;
    font-size: 16px;
    color: $color-white;

  }

  .close_width {
    width: 5%;
    font-family: Roboto-Light;
  }

  .close_width:hover {
    cursor: pointer;
  }

  .title_width {
    font-size: 27px;
    width: 85%;
    font-family: Roboto-Light;
  }

  .back_width {
    width: 10%;
    font-size: 22px;
    cursor: pointer;
    font-family: Roboto-Light !important;
  }
}

.mat-checkbox-frame {
  border-radius: 3px !important;
}

.sidemenu-close {
  @extend .w100;
  transition: 1s width;
}

.backdropBackground {
  background: $color-backdrop-bg;
  box-shadow: 0 0px 5px $color-backdrop-bg;
}

//Common Class for Scroll bar
.scrollbar {
  overflow: auto;

  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    background-color: $color-lightGray;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $color-seat-cancelled;
    border: 4px;
    height: 40px;
    border-radius: 4px;
  }
}

.seat_number_btn {
  font-size: 23px;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  padding: 12px;
  vertical-align: middle;
  text-align: center;
  align-items: center;
  color: $color-white;
}

.clientStateSection {
  width: 100%;
  text-align: center;

  .clientState {
    width: 29%;
    font-size: 15px;
    padding: 0;
    height: 38px;
    margin: 5px 1.5%;
    border-radius: 5px;
    color: $color-button-darkGray;
    border: 1px solid $color-button-darkGray;
    background: $color-white;

  }

  .act_btn_bg {
    background-color: $color-button-action;
    color: $color-white;
    border: none;

  }
}

.checkmark {
  width: 40px;
  height: 40px;
  top: 0px;
  margin-left: 110px !important;
  margin-top: 30px !important;
  border-radius: 50%;
  display: block;
  stroke-width: 3;
  stroke: $color-white;
  stroke-miterlimit: 10;
  margin: 10% auto;
  box-shadow: inset 0px 0px 0px $color-button-action;
  animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
}

.checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke .4s cubic-bezier(0.650, 0.000, 0.450, 1.000) .8s forwards;
}

.disabed_checkmark {
  width: 40px;
  height: 40px;
  top: 0px;
  margin-left: 110px !important;
  margin-top: 30px !important;
  border-radius: 50%;
  display: block;
  stroke-width: 3;
  stroke: $color-white;
  stroke-miterlimit: 10;
  margin: 10% auto;
  box-shadow: inset 0px 0px 0px $color-button-darkGray;
  animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
}

.disabledcheckmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke .4s cubic-bezier(0.650, 0.000, 0.450, 1.000) .8s forwards;
}

.icon_set_tab {
  margin: 3% 0.5%;
  background-color: $color-button-darkGray !important;
  box-shadow: none !important;
}

.status_div {
  height: 80px;
}

.font_color_blue {
  color: $color-seat-reserved;
}

.font_color_gray {
  color: $color-button-darkGray;
}

.font_color_arrived {
  color: $color-seat-arrived;
}

.font_color_blocked {
  color: $color-seat-blocked;
}

.font_color_seated {
  color: $color-seat-seated;
  font-family: Roboto-Light;
  text-transform: capitalize;
  font-size: 26px;
}

.error-message {
  font-size: 12px;
  color: #B00020;
}

.pointer {
  cursor: pointer;
}

.bg_color_blue {
  background-color: $color-seat-reserved !important;
}

.bg_color_white {
  background-color: $color-white !important;
  color: $color-button-darkGray !important;
  border: 1px solid $color-button-darkGray;
}

.bg_color_arrived {
  background-color: $color-seat-arrived !important;
}

.bg_color_blocked {
  background-color: $color-seat-blocked !important;
}

.bg_color_seated {
  background-color: $color-seat-seated !important;
}

.max_capacity_font {
  font-size: 20px;
}

.default {
  .popover {
    >.arrow {
      left: 55% !important;
      position: absolute;
      display: block;
      width: 0;
      height: 0;
      // border-color: $border_color_1;
      border-style: solid;
      border-width: 11px;

      &:after {
        position: absolute;
        display: block;
        width: 0;
        height: 0;
        // border-color: $border_color_1;
        border-style: solid;
        border-width: 10px;
        content: "";
      }
    }
  }

  .popover.bottom {
    margin-top: 14px;
    margin-bottom: 5px;

    >.arrow {
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 10px solid #e8e8e8;
      border-top-width: 0px;
      top: -11px;
      content: " ";
    }
  }

  .popover.top {
    margin-top: 14px;

    >.arrow {
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-top: 10px solid #c7c7c7;
      border-bottom-width: 0px;
      top: -11px;
      content: " ";
    }
  }

  .popover.right {
    margin-top: 0px;
    margin-left: 12px;

    >.arrow {
      border-top: 10px solid transparent;
      border-bottom: 10px solid transparent;
      border-right: 10px solid #c7c7c7;
      border-left-width: 0px;
      top: 47%;
      margin-left: -60%;
      content: " ";
    }
  }

  .popover {
    z-index: 5;
  }
}

.mat-dialog-container {
  padding: 0px !important;
  border-radius: 5px;

  .loaderitem {
    position: absolute;
    //padding-left: 441px;
    padding-left: 50px;

    //padding-top: 240px;
    .mat-progress-spinner {
      svg {
        width: 50px !important;
        height: 50px !important;
        margin-top: 25px;
        margin-left: 25px;

        circle {
          stroke: #1da664 !important;
          stroke-width: 15% !important;
        }
      }
    }
  }
}

/***************** Seat Rewrite Styles ***********************/
.seat-label {
  font-size: $label-font-size;
}

.page-title {
  font-size: $page-title-font;
  font-family: $page-title-family;

  @include breakpoint($small-tab-devices) {
    font-size: 18px;
  }
}

.action-btn {
  width: 100%;
}

@keyframes moveFromBottom {
  from {
    opacity: 0;
    transform: translateY(200%);
  }

  to {
    opacity: 1;
    transform: translateY(0%);
  }
}

.cdk-overlay-dark-backdrop {
  background: rgba(211, 211, 211, 0.75);
  backdrop-filter: blur(5px);
}

.ng-scrollbar-x-layout {
  .ng-scrollbar-visible {
    padding: 0px;
  }

  .ng-scrollbar-y-layout {
    .ng-scrollbar-visible {
      padding: 0px !important;

      .ng-scrollbar-thumb {
        height: 80%;
        width: 80%;
        background-color: #818792;
      }
    }
  }
}

.mat-dialog-actions {
  margin-bottom: 0px !important;
}

.icon-VIP:before,
.icon-Favourites:before {
  color: $color-icons;
}

.mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled) {
  background: none;
}

.icon-Default:before {
  color: $color-seat-icon !important;
}

.icon-SMS:before,
.icon-Birthday:before,
.icon-Gluten-Free:before,
.icon-Vegetarian:before,
.icon-Anniversary:before,
.icon-Peanuts:before,
.icon-Eggs:before,
.icon-High-Chair:before,
.icon-ic_mail_outline_24px:before,
.icon-star:before,
.icon-Baby-Carrier:before,
.icon-Group-7105:before,
.icon-ic_create_24px:before,
.icon-password:before,
.icon-login-user:before,
.icon-Vip:before,
.icon-Shellfish:before,
.icon-Neighborhood:before,
.icon-FriendOfOwner:before,
.icon-printChit:before,
.icon-open-book-2:before,
.icon-note:before,
.icon-Path-490:before,
.icon-left-arrow1:before,
.icon-multi-paging_lg:before,
.icon-Group-587:before,
.icon-add:before,
.icon-Group-591::before,
.icon-edit::before,
.icon-ic_update_24px:before,
.icon-search:before,
.icon-ic_call_24px:before,
.icon-Union-17:before,
.icon-Union-20::before,
.icon-ic_delete_24px:before,
.icon-servant-outline:before,
.icon-padlock:before,
.icon-Path-506:before,
.icon-report:before,
.icon-ic_stay_current_portrait_24px:before {
  color: unset !important;
}

.icon-guest-tags:before {
  color: $color-seat-icon !important;
}

a:hover {
  text-decoration: none;
}

button:focus {
  outline: none;
}

.rounded-icon {
  background-color: $color-white;
  border: 1px solid $color-default-round-icons;
  color: $color-default-round-icons;
  line-height: 28px;
  margin: 10px 15px 5px 0px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  box-shadow: none;
  min-width: 0;
  font-size: 14px;
}

.rectangular-icon {
  font-size: 18px !important;
  border: 1px solid $color-default-round-icons !important;
  padding: 0px;
  color: $color-default-round-icons !important;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 16px;
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 38px !important;
}

.seat-header-one {
  font-size: 24px;
  font-family: $font-family-roboto-light;
  color: $color-first-header-text !important;
}

.seat-panel-header {
  font-size: 24px;
  font-family: $font-family-roboto-regular;
  color: $color-panel-header !important;
}

.seat-header-two {
  font-size: 22px;
  font-family: $font-family-roboto-regular;
  color: $color-second-header-text !important;
}

.seat-header-bold {
  font-size: 22px;
  font-family: $font-family-roboto-medium;
  color: $color-second-header-text !important;
}

.seat-reservation-status {
  font-size: 22px;
  font-family: $font-family-roboto-regular;
}

.seat-header-three {
  font-size: 18px;
  font-family: $font-family-roboto-medium;
  color: $color-third-header-text;
}

.seat-header-four {
  font-size: 16px;
  font-family: $font-family-roboto-medium;
  color: $color-fourth-header-text;
}

// body 1
.seat-primary-text {
  font-size: 16px;
  font-family: $font-family-roboto-regular;
  color: $color-primary-text;
}

.seat-grid-header {
  font-size: 16px;
  font-family: $font-family-roboto-medium;
  color: $color-grid-header;
}

.audit-log .seat-grid-header {
  background-color: #E8E8E8;
}

.seat-text-fields {
  font-size: 16px;
  font-family: $font-family-roboto-regular;
  color: $color-text-fields;
}


// body 2
.seat-secondary-text {
  font-size: 14px;
  font-family: $font-family-roboto-regular;
  color: $color-secondary-text-fields;
}

.seat-grid-content {
  font-size: 14px;
  font-family: $font-family-roboto-regular;
  color: $color-grid-content;
}

// body 3
.seat-tertiary-text {
  font-size: 14px;
  font-family: $font-family-roboto-regular;
  color: $color-black;
}

// body 4
.seat-error-messages {
  font-size: 14px;
  font-family: $font-family-roboto-regular;
  color: $color-error-text-messages;
}

.seat-labels {
  font-size: 12px;
  font-family: $font-family-roboto-regular;
  color: $color-seat-labels;
}

.seat-section-header {
  font-size: 22px;
  font-family: $font-family-roboto-light;
  color: $color-section-header;
}

.seat-border-color {
  border-color: $color-seat-border !important;
}

.seat-icon-color {
  color: $color-seat-icon;

  &.disabled-icon {
    color: $color-disabled-icons;
  }

  &.alert-icon {
    color: $color-error-text-messages;
  }
}

.seat-primary-bg-color {
  background-color: $color-seat-primary-bg;
}

.seat-secondary-bg-color {
  background-color: $color-seat-secondary-bg;
}

.seat-triple-dots {
  font-size: 22px;
  color: $color-seat-triple-dots;
}

.seat-popover-header {
  background: $color-seat-secondary-bg !important;
  font-size: 14px;
  font-family: $font-family-roboto-regular;
  color: $color-popover-header !important;
}

.seat-popover-content {
  font-size: 14px;
  font-family: $font-family-roboto-regular;
  color: $color-popover-content !important;
}

.seat-no-content-found {
  font-size: 14px;
  font-family: $font-family-roboto-regular;
  color: $color-no-content !important;
}

.form-helper-text {
  font-size: 16px;
  font-family: $font-family-roboto-regular;
  color: $color-seat-labels !important;
}

.seat-alert-messages {
  font-size: 18px;
  font-family: $font-family-roboto-regular;
  color: $color-seat-labels !important;
}

.seat-body-text {
  font-size: 14px;
  font-family: $font-family-roboto-regular;
  color: $color-spent-details;
}

.seat-notification {
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  line-height: 19px;
  text-align: center;
  color: $color-primary-text;

  &.red-color {
    border: 1px solid $color-seat-notification-bg;
    background: $color-seat-notification-bg;
  }

  &.green-color {
    border: 1px solid $color-seat-theme;
    background: $color-seat-theme;
  }
}

.mat-expansion-panel {
  &.mat-expansion-panel-spacing {
    .mat-expansion-panel-header-title {
      border-bottom: 1px solid;
      @extend .seat-border-color;
    }
  }

  .mat-expansion-panel-header {
    .mat-expansion-panel-header-title {
      @extend .seat-section-header;
    }
  }

  .mat-expansion-indicator::after {
    color: $color-seat-icon;
  }
}

.toast-top-right {
  top: 70px !important;
}

.content-text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.tooltip-full-text{
  word-break: break-all !important;
  white-space: normal !important;
}

.mat-form-field {
  .mat-form-field-wrapper {
    .mat-form-field-flex {
      .mat-form-field-infix {
        .mat-input-element {
          font-size: 16px !important;
          font-family: $font-family-roboto-regular;
          color: $color-text-fields;
        }

        .mat-form-field-label-wrapper {
          .mat-form-field-label {
            color: $color-seat-labels;
            font-family: $font-family-roboto-regular;
          }
        }

        .mat-select {
          .mat-select-trigger {
            .mat-select-value {
              color: $color-text-fields;
            }

            .mat-select-arrow-wrapper {
              .mat-select-arrow {
                color: $color-text-fields;
              }
            }
          }
        }
      }
    }
  }

  &.mat-form-field-invalid {
    .mat-input-element {
      caret-color: $color-error-text-messages !important;
    }

    .mat-form-field-label {
      color: $color-error-text-messages !important;
    }
  }

  &.mat-form-field-appearance-legacy {
    .mat-hint {
      color: $color-seat-labels;
    }
  }
}

.headercontainer__autorefresh {
  .mat-checkbox-checked.mat-accent .mat-checkbox-background {
    background-color: $color-toggle-bg;
  }
}

.text-color-white {
  color: $color-white;
}

.timeline-grid-header {
  font-size: 12px;
  font-family: $font-family-roboto-bold;
  color: $color-timeline-header;
}

.timeline-grid-content {
  font-size: 12px;
  font-family: $font-family-roboto-medium;
  color: $color-timeline-content;
}

.party-ota-icon {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: $color-ota-source;
  display: inline-flex;
  margin-left: 10px;
}

.refund-popup {
  .mat-checkbox-checked.mat-accent .mat-checkbox-background {
    background-color: $color-button-action;
  }
}

.class-detail,
.privatelesson-booking-detail {
  .mat-tab-labels {
    justify-content: flex-start;
  }

  .mat-tab-label {
    width: max-content;
  }

  .mat-tab-label .mat-tab-label-content {
    font-size: 18px;
  }

  .mat-flat-button.mat-warn,
  .mat-raised-button.mat-warn,
  .mat-fab.mat-warn,
  .mat-mini-fab.mat-warn {
    background-color: $color-time-indicator;
  }

  .mat-button.mat-warn,
  .mat-icon-button.mat-warn,
  .mat-stroked-button.mat-warn {
    color: $color-time-indicator;
  }
}

.popover {
  max-width: 600px;
}

.disabled-text {
  opacity: 0.5;
}

.weekday-shift__day {
  .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
    background-color: $color-primary-green-btn;
  }

  .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
    background-color: rgba($color-primary-green-btn, 0.54);
  }
}

.permissions__clone {

  .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
  .mat-checkbox-checked.mat-accent .mat-checkbox-background {
    background-color: $color-primary-green-btn;
  }
}

.side-fields.basic-input-field {
  // width: 80%;
}

.mat-radio-button.mat-accent .mat-radio-inner-circle,
.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,
.mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: $color-primary-green-btn;
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: $color-primary-green-btn;
}

.field-line-align {
  float: left;
  padding-right: 25px;
  width: 50% !important;
}

/* width */
::-webkit-scrollbar {
  width: 8px !important;
}

*:hover::-webkit-scrollbar{
  width: 8px !important;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #818792 !important;
  border-radius: 10px;
  width: 0;
}

.whitecolor {
  color: #fff !important;
}

.LW14 {
  font-family: "LatoWeb", sans-serif;
  font-size: 14px !important;
}
.LW12 {
  font-size: 12px !important;
}


$custom-typography: mat.define-typography-config($font-family: 'LatoWeb');

html {
  overflow-x: hidden;
}


/* Icon Style */
i {
  &.IC6 {
    font-size: 6px;
  }

  &.IC7 {
    font-size: 7px;
  }

  &.IC8 {
    font-size: 8px;
  }

  &.IC9 {
    font-size: 9px;
  }

  &.IC10 {
    font-size: 10px;
  }

  &.IC11 {
    font-size: 11px;
  }

  &.IC12 {
    font-size: 12px;
  }

  &.IC13 {
    font-size: 13px;
  }

  &.IC14 {
    font-size: 14px;
  }

  &.IC15 {
    font-size: 15px;
  }

  &.IC16 {
    font-size: 16px;
  }

  &.IC17 {
    font-size: 17px;
  }

  &.IC18 {
    font-size: 18px;
  }

  &.IC19 {
    font-size: 19px;
  }

  &.IC20 {
    font-size: 20px;
  }

  &.IC21 {
    font-size: 21px;
  }

  &.IC22 {
    font-size: 22px;
  }

  &.IC23 {
    font-size: 23px;
  }

  &.IC24 {
    font-size: 24px;
  }

  &.IC25 {
    font-size: 25px;
  }

  &.IC26 {
    font-size: 26px;
  }

  &.IC27 {
    font-size: 27px;
  }

  &.IC28 {
    font-size: 28px;
  }

  &.IC29 {
    font-size: 29px;
  }

  &.IC30 {
    font-size: 30px;
  }

  &.IC35 {
    font-size: 35px;
  }
}


/* Custom Common override material related Css*/
.custSearchbox {
  .mat-form-field-label.mat-input-placeholder {
    color: $label-color !important;
    padding-left: 10px !important;
    width: 90%;
  }

}

.DDRemoveUnderline {
  .mat-form-field-underline {
    display: none !important;
  }
}

.label-color {
  color: $label-color;
}

.value-color {
  color: $value-color;
}

.prevent-action {
  pointer-events: none;
  opacity: 0.4;
}

.prevent-action-one {
  pointer-events: none;
}

.mat-error {
  font-size: $font-size12;
}

.custMB8 {
  margin-bottom: 8px !important;
}


/****** Loader Cover Spinner for Application Loader******************/
#cover-spin,
#custom-cover-spin,
#setting-cover-spin {
  position: fixed;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  display: none;
}


@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.theme-loader {
  position: fixed;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 9999;
  display: none;
}

@-webkit-keyframes loader {
  from {
    -webkit-transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes loader {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.theme-loader::after {
  content: '';
  display: block;
  width: 60px;
  height: 60px;
  border-style: solid;
  border-color: $color-border-gold;
  border-top-color: transparent;
  border-width: 4px;
  border-radius: 50%;
  -webkit-animation: spin .8s linear infinite;
  animation: spin .8s linear infinite;
}

#cover-spin::after,
#custom-cover-spin:after,
#setting-cover-spin::after {
  content: '';
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  width: 60px;
  height: 60px;
  border-style: solid;
  border-color: $color-border-gold;
  border-top-color: transparent;
  border-width: 4px;
  border-radius: 50%;
  -webkit-animation: spin .8s linear infinite;
  animation: spin .8s linear infinite;
}

#custom-cover-message,
#loader-message {
  position: absolute;
  top: calc(50% + 80px);
  left: calc(50% + 30px);
  color: #FFF;
  transform: translate(-50%, -50%);
}

#loader-message {
  color: #000;
  top: calc(50% + 72px);
  left: calc(50%);
  z-index: 1001;
}

.dynamic-scrollbar {
  .ng-scrollbar-thumb {
    display: none;
  }

  .ng-scrollbar-view {
    overflow: hidden;
  }
}

/** time Out css */
.timepickerCustBtn {
  display: inline-block;
  height: 36px;
  min-width: 88px;
  line-height: 36px;
  border: 12px;
  border-radius: 2px;
  background-color: transparent;
  text-align: center;
  transition: all 450ms cubic-bezier(.23, 1, .32, 1);
  overflow: hidden;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: relative;
  cursor: pointer;
  outline: 0;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 600;
  padding-left: 16px;
  padding-right: 16px;
  font-family: Roboto, sans-serif;
}

.timepickerCustBtn:hover {
  background-color: rgba(153, 153, 153, .2);
}

.mat-form-field-underline {
  height: 2px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.42);
  background-color: unset !important;
}


.button {
  height: 40px !important;
  line-height: 40px !important;
  margin-right: 8px !important;
  margin-bottom: 8px !important;
}

.button--primary {
  @extend .button;
  background-color: $theme-base-color !important;
  color: $primary-white !important;
  border: 1px solid $theme-base-color !important;

  &[disabled] {
    cursor: default !important;
    pointer-events: none !important;
    opacity: 0.5;
  }
}

.button--primary-save {
  @extend .button;
  background-color: $theme-base-color !important;
  color: $primary-white !important;
  border: 1px solid $theme-base-color !important;


  &[disabled] {
    cursor: default !important;
    pointer-events: none !important;
    opacity: 0.5;
  }
}

.button--disabled {
  @extend .button;
  background-color: $button_background !important;
  color: $disabled-color !important;
  cursor: default !important;
  pointer-events: none !important;
  opacity: 0.5;

  &::before {
    color: $disabled-color !important;
  }
}


.mat-form-field {
  .mat-form-field-wrapper {
    .mat-form-field-flex {
      height: 100%;
    }
  }
}

.golf__link {
  color: $theme-base-color;
}

.select-container-bg {
  .select-container {
    .mat-form-field {
      font-size: 14px;
    }
  }
}


// ---menu icon align--
$default-control-padding: 15px;

.ag_pl--3 {
  padding-left: 5px !important;
}

.ag_float--right {
  float: right !important;
}

.mat-form-field-required-marker {
  color: red !important;
}

.golf-form-control {
  padding-right: $default-control-padding;
}

.golf-form-control--lg {
  @extend .golf-form-control;
  width: 100%;
}

.golf-form-control--md {
  @extend .golf-form-control;
  width: 75%;
}

.golf-form-control--sm {
  @extend .golf-form-control;
  width: 50%;
}

.golf-form-control--xs {
  @extend .golf-form-control;
  width: 25%;
}

.golf-form--label {
  font-size: 13px;
  display: block;
  margin-bottom: 10px;
  white-space: nowrap;
}

.golf-form--text {
  font-size: 16px;
  display: block;
  margin-bottom: 20px;
}

.golf-form-control {
  padding-right: $default-control-padding;
}

// Width CSS
.w-0 {
  width: 0;
}

.w-20 {
  width: 20%;
}

.w-30 {
  width: 30%;
}

.w-33 {
  width: 33.33%;
}

.w-40 {
  width: 40%;
}

.w-50 {
  width: 50%;
}

.w-60 {
  width: 60%;
}

.w-66 {
  width: 66%;
}

.w-80 {
  width: 80%;
}

.w-70 {
  width: 70%;
}

.w-75 {
  width: 75%;
}

.w-87 {
  width: 87%;
}

.w-90 {
  width: 90%;
}

.w-100 {
  width: 100%;
}

.in-block {
  display: inline-block;
}

.font-bold {
  font-weight: bold;
}

a,
a:hover {
  color: $theme-base-color;
}

.theme__link {
  color: $theme-base-color;
}

// Radio Button Common CSS
.golf--form-radio-button {
  clear: both;
  padding-right: 0.625rem;

  .mat-radio-label {
    font-size: 14px;
  }

  .mat-radio-label {
    .mat-radio-container {
      width: $radio-size;
      height: $radio-size;

      .mat-radio-outer-circle {
        width: $radio-size;
        height: $radio-size;
      }
    }
  }

  &.mat-radio-checked {
    .mat-radio-label {
      .mat-radio-container {
        .mat-radio-outer-circle {
          border-color: $theme-base-color;
          border-width: 1px;
        }

        .mat-radio-inner-circle {
          background-color: $theme-base-color;
          transform: scale(0.5);
          width: $radio-size;
          height: $radio-size;
        }
      }
    }
  }
}

//ngx-material-timepicker latest version fix

.timepicker-overlay {
  z-index: 10000000 !important;
}

.timepicker-backdrop-overlay {
  z-index: 10000000 !important;
}

.ag-form-label {
  font-size: $font-size12 !important;
  font-family: $lato-web-web !important;
  color: $label-color;
  margin-bottom: 10px;
  white-space: nowrap;
  display: block;
}

.timepicker__actions {

  .button--primary:focus,
  .ag_button--primary:focus,
  .picker-ok:focus {
    opacity: 0.8;
  }

  .button--tertiary:focus,
  .ag_button--secondary:focus,
  .picker-cancel:focus {
    background-color: $golf-button-bg !important;
  }

  .timepicker-button.picker-ok:focus {
    background-color: $golf-button-bg !important;
  }
}

// Removing Border for disabled button
body {
  .ag_button--primary.mat-button-disabled {
    border: none !important;
  }
}

.negotiableRatesPlan {
  .mat-form-field-infix {
    padding-top: 0px !important;
    text-align: right;
    border-top: 0px !important;
  }

}

.grid-table table thead th {
  color: #000;
  font-size: 14px;
  height: 43px;
}

.btn:focus,
.btn.focus {
  outline: none !important;
  box-shadow: none !important;
}

.icon-Minus,
.icon-Plus {
  font-size: 24px;
  margin-left: 10px;
  cursor: pointer;
  color: $theme-base-color
}

.icon-minus,
.icon-plus {
  position: absolute;
  cursor: pointer;
  margin-top: 10px;
  color: $theme-base-color;
  font-size: $font-size26;
  margin-left: 10px;
}

#table_3.retailCustomDataTable,
#table_4.retailCustomDataTable,
#table_6.retailCustomDataTable,
#table_2.retailCustomDataTable {
  .retailtable {
    tbody {
      tr {
        td:nth-child(8) {
          white-space: unset;
        }
      }
    }
  }
}

.retailCustomDataTable {

  .search-icon.icon-Search,
  .search-icon.icon-Cancel {
    top: 0px !important;
  }
}

.button_invalid {
  opacity: 0.5;
  cursor: not-allowed !important;
  pointer-events: none;
}

.button_invalidbgcolor {
  cursor: default;
  background-color: $color-disabled !important;
  border-color: $color-disabled !important;
}

.assignCommision {
  .gratuityDetails {
    .gratuityDesc {
      .staffOptions {
        .icon-plus {
          vertical-align: middle;
          position: initial;
          font-size: 24px;
          margin-left: 10px;
          cursor: pointer;
          color: $theme-green;
        }
      }
    }
  }
}

.search-bar {
  .search-input {
    #AdvancedSearch {
      position: inherit;
    }

    .cursor {
      position: absolute;
    }
  }
}

@media print {
  body {
    font-family: 'Roboto-Regular';
  }
}


@media print {
  #ticket-print-section {
    break-inside: avoid;
    font-family: 'Roboto-Regular';
  }

  .ticket__container.print-selection {
    display: block !important;
    visibility: visible !important;
    border: 2px solid black;
    width: 90%;
    color: black !important;
    break-inside: avoid;
    font-family: 'Roboto-Regular';
  }


  .panel.left-panel tr {
    border-bottom: 2px solid black !important;
  }


  .right-panel .scan {
    position: absolute !important;
    right: 5% !important;
    top: 0px !important;
  }


  tr {
    border-bottom: 2px solid black !important;
  }

  td {
    padding: 4px 4px;
    font-size: 14px;
    border-bottom: 2px solid black !important;
  }

  td:first-child {
    width: 55%;
  }

  td:last-child {
    width: 50%;
  }

  .ticket-col-8 {
    width: 60%;
    padding-right: 10px;


  }

  .user-counter-details {}

  .user-counter-details .group {
    border-right: 1px solid black;
  }

  .user-counter-details .group:last-child {
    border-right: none;
  }

  .user-detail {
    padding: 0px 10px 0px 10px;
  }

  .ticket-row {
    display: inline-block;
    width: 100%;

  }

  .left-section {
    float: left;
    width: 60%;
  }

  .right-section {
    float: left;
    width: 40%;
  }

  .right-panel {
    position: relative;
    width: 30%;
    float: left;
  }

  .left-panel {
    width: 70%;
    float: left;
  }

  .ticket__container {
    margin-top: 20px;
    position: relative;
    border: 1px solid rgba(171, 171, 171, 0.3);
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 7px;
  }

  .ticket-col-4 {
    width: 35%;


  }

  .counter-area {

    margin-left: 10px;
  }

  .counter-area .group {
    border-right: 1px solid black;
  }

  .counter-area .group:last-child {
    border-right: none;
  }

  .bottom-session.time-details {
    text-align: center;
    margin-left: 10%;
  }

  time-title {
    text-align: center;
    font-size: 15px;
    margin-top: 13px;
  }

  time-title span {
    font-weight: bold
  }

  .user-counter-details .group {

    display: inline-block;
    font-size: 12px;
    padding: 7px 4px;
    border-right: 1px solid hsla(0, 0%, 67.1%, .3);
    width: 47%;
    float: left;

  }

  .propertyname {
    text-align: center;
    font-weight: bold;
    font-size: 18px;
    padding: 10px;
  }

  .user-name,
  name {
    font-weight: 600;
    font-size: 18px;
    line-height: 12px;
  }

  label {
    font-size: 13px;
    padding: 5px 0;
    font-weight: 500;
  }

  .user-counter-details .group-number {
    float: right;
    width: 47%;
  }

  .court-section {
    text-align: center;
    background: #eaeef1;
    border-radius: 8px;
    margin: 2px 0px 0px 0px;
  }


  .col-list {
    display: table-cell !important;
    position: relative;
  }

  .counter-area .group {
    display: inline-block;
    font-size: 12px;
    padding: 7px 4px;
    border-right: 1px solid rgba(171, 171, 171, 0.3);
  }

  .counter-area .group-name {
    display: inline-block;
    font-size: 12px;
    padding: 7px 4px;
  }
}

.hidden-submit {
  position: absolute;
  left: -9999px;
  width: 1px;
  height: 1px;
}




// Display Properties
// body {
//   min-width: 1024px;
// }

.ag_display--none {
  display: none !important;
}

.ag_display--block {
  display: block !important;
}

.ag_display--flex {
  display: flex !important;
}

.ag_display--inflex {
  display: inline-flex !important;
}

.ag_display--inblock {
  display: inline-block !important;
}

.ag_display--inline {
  display: inline !important;
}

.ag_display--flex-wrap {
  @extend .ag_display--flex;
  width: 100%;
  flex-wrap: wrap;
}

// Float Properties
.ag_float--left {
  float: left;
}

.ag_float--right {
  float: right;
}

// Clear Properties
.ag_clearfix::after {
  content: "";
  clear: both;
  display: table;
}

.ag_clear--both {
  clear: both;
}

// Position Properties
.ag_position--relative {
  position: relative;
}

.ag_position--absolute {
  position: absolute;
}

.ag_position--fixed {
  position: fixed;
}

.ag_position--static {
  position: static;
}

.ag_position--sticky {
  position: sticky;
}

// Text Alignment Properties
.ag_text--left {
  text-align: left;
}

.ag_text-right {
  text-align: right;
}

.ag_text--center {
  text-align: center;
}

// Text Transform Properties
.ag_text--uppercase {
  text-transform: uppercase;
}

.ag_text--capitalize {
  text-transform: capitalize;
}

.ag_text--lowercase {
  text-transform: lowercase;
}

// Font Properties
.ag_font--normal {
  font-weight: normal;
}

.ag_font--bold {
  font-weight: bold;
}

.ag_font--italic {
  font-style: italic;
}

//Overflow Properties
.ag_text-overflow--hidden {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ag_text-overflow--auto {
  overflow: auto;
}

.ag_text-overflow--scroll {
  overflow: scroll;
}

// Cursor Properties
.ag_cursor--pointer {
  cursor: pointer !important;
}

.ag_cursor--move {
  cursor: move;
}

.ag_cursor--not-allowed {
  cursor: not-allowed;
}

.ag_pointer--none {
  pointer-events: none;
}

// Width Properties
@for $i from 0 through 10 {
  .ag_w--#{$i * 10} {
    width: #{$i * 10%};
  }
}

.ag_w--25 {
  width: 25%;
}

.ag_w--33 {
  width: 33.33%;
}

.ag_w--75 {
  width: 75%;
}

// Padding Properties
@for $i from 0 through 8 {
  .ag_p--#{$i} {
    padding: #{$i * 0.25}rem !important;
  }
}

@for $i from 0 through 8 {
  .ag_pl--#{$i} {
    padding-left: #{$i * 0.25}rem !important;
  }
}

@for $i from 0 through 8 {
  .ag_pr--#{$i} {
    padding-right: #{$i * 0.25}rem !important;
  }
}

@for $i from 0 through 8 {
  .ag_pt--#{$i} {
    padding-top: #{$i * 0.25}rem !important;
  }
}

@for $i from 0 through 8 {
  .ag_pb--#{$i} {
    padding-bottom: #{$i * 0.25}rem !important;
  }
}

//Margin Proerties
.ag_m--auto {
  margin: auto;
}

.ag_ml--auto {
  margin-left: auto;
}

.ag_mr--auto {
  margin-right: auto;
}

.ag_mt--auto {
  margin-top: auto;
}

.ag_mb--auto {
  margin-bottom: auto;
}

.ag-mr-3 {
  margin-right: 0.75rem;
}

@for $i from 0 through 8 {
  .ag_m--#{$i} {
    margin: #{$i * 0.25}rem !important;
  }
}

@for $i from 0 through 8 {
  .ag_ml--#{$i} {
    margin-left: #{$i * 0.25}rem !important;
  }
}

@for $i from 0 through 8 {
  .ag_mr--#{$i} {
    margin-right: #{$i * 0.25}rem !important;
  }
}

@for $i from 0 through 8 {
  .ag_mt--#{$i} {
    margin-top: #{$i * 0.25}rem !important;
  }
}

@for $i from 0 through 8 {

  .ag_mb--#{$i},
  .ag-mb-#{$i} {
    margin-bottom: #{$i * 0.25}rem !important;
  }
}

// Form related Properties
.ag_form-control {
  padding-right: $default-control-padding;
}

.ag_form_wrapper--xs {
  width: 300px;
}

.ag_form_wrapper--sm {
  width: 550px;
}

.ag_form_wrapper--md {
  width: 800px;
}

.ag_form_wrapper--lg {
  width: 990px;
}

.ag_form-control--lg {
  @extend .ag_form-control;
  @extend .ag_w--100;
}

.ag_form-control--md {
  @extend .ag_form-control;
  @extend .ag_w--75;
}

.ag_form-control--sm {
  @extend .ag_form-control;
  @extend .ag_w--50;
}

.ag_form-control--xs {
  @extend .ag_form-control;
  @extend .ag_w--25;
}

.ag_radio--horizontal {
  @extend .ag_mb--4;
}

.ag_form--label {
  font-size: $font-size12;
  @extend .ag_display--block;
  color: $ag_black-lighter !important;
  margin-bottom: $ten-px;
  white-space: nowrap;
  color: $black;
}

.ag_form--value {
  font-size: 16px;
  @extend .ag_display--block;
  @extend .ag_mb--5;
}

.ag_error {
  color: $error-color;
}

.ag_mandatory-lbl {
  &::after {
    content: "*";
    color: $ag_red;
    padding-left: 0.25rem;
  }
}

// Layout Properties

// Overall Outer Padding
.ag_container--padding-lg {
  @extend .ag_p--6;
}

.ag_container--padding-md {
  @extend .ag_p--5;
}

.ag_container--padding-sm {
  @extend .ag_p--4;
}

.ag_container--padding-xs {
  @extend .ag_p--3;
}

h4 {
  &.ag_state--headers {
    color: $ag_grey-darkest;
    font-size: $font-size20 !important; // change this px to rem
    @extend .ag_font--normal;
    font-family: $ag_lato-medium;
  }

  &.ag_group--headers {
    font-size: $font-size16 !important; // change this px to rem
    @extend .ag_mb--7;
    @extend .ag_font--bold;
    font-family: $ag_lato-medium;
  }

  &.ag_page--headers {
    color: $ag_grey-darkest;
    @extend .ag_mb--5;
    @extend .ag_font--bold;
    font-size: $font-size16 !important; // change this px to rem
  }

  &.ag_card--headers {
    color: $ag_black;
    font-size: $font-size18 !important; // change this px to rem
    @extend .ag_font--normal;
    font-family: $ag_lato-medium;
  }
}

.ag_footer--actions {
  width: 100%;
  padding: 10px 20px;
  background-color: $ag_white;
  border-top: 1px solid $ag_border-lighter;
  display: flex;
  position: relative;
  z-index: 1;
  height: $dialog-footer-height;
}

.ag_height--100 {
  height: 100%;
}

.ag_modal--form-height {
  height: calc(100% - #{$dialog-footer-height});
  overflow: auto;

  form {
    height: 100%;
  }

  .mat-tab-group {
    overflow: auto;
    height: 100%;
  }
}

// Disables complete section
.ag_section--disable {
  @extend .ag_cursor--not-allowed;
  @extend .ag_pointer--none;
  opacity: 0.5;
}

// Link Properties
.ag_link {
  color: $ag_base-color !important;
  text-decoration: none;
  white-space: nowrap;
  @extend .ag_cursor--pointer;
  @extend .ag_font--bold;
}

.ag_link--sm {
  @extend .ag_link;
  font-size: 12px;
}

.ag_link--lg {
  @extend .ag_link;
  font-size: 14px;
}

.ag_link--disabled {
  @extend .ag_pointer--none;
  @extend .ag_cursor--not-allowed;
  color: $ag_disabled-color !important;
}

.ag_sticky-note {
  padding: 3px;
  font-family: $ag_lato-medium;
  @extend .ag_mr--2;
}

.ag_sticky-note-confirmed {
  @extend .ag_sticky-note;
  color: $ag_blue;
  background-color: $ag_blue-lightest;
}

.ag_sticky-note-draft {
  @extend .ag_sticky-note;
  color: $ag_orange;
  background-color: $ag_orange-lightest;
}

.ag_sticky-note-closed {
  @extend .ag_sticky-note;
  color: $ag_green;
  background-color: $ag_green-lightest;
}

.ag_sticky-note-cancelled {
  @extend .ag_sticky-note;
  color: $ag_grey-dark;
  background-color: $ag_grey-lighter;
}

// Utilities Common CSS

//Checkbox with Circle Icon
.ag_checkbox--icon {
  padding: 0.9375rem 0 0 0.9375rem;

  .icon-confirmed {
    font-size: 1.5rem;
    color: $ag_grey-darker;

    &.selected-icon {
      color: $navy-blue-darkest;
    }
  }

  .check-label {
    font-size: 14px;
    margin-left: 0.5rem;
    vertical-align: super;
  }
}

//Ngx TimePicker Color Customization
.timepicker__header {
  background: $ag_base-color !important;
}

.clock-face__number {
  span {
    &.active {
      background: $ag_base-color !important;
    }
  }
}

.timepicker-button {
  color: $ag_base-color !important;
}

.clock-face__clock-hand {
  background: $ag_base-color !important;

  &::before {
    border: 4px solid $ag_base-color !important;
  }

  &::after {
    background-color: $ag_base-color !important;
  }
}

// Ghost Css Animation
@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }

  100% {
    background-position: 468px 0;
  }
}

.animated-background {
  animation-duration: 1.25s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #f6f6f6;
  background: linear-gradient(to right, #f6f6f6 8%, #f0f0f0 18%, #f6f6f6 33%);
  background-size: 800px 104px;
  height: 96px;
  position: relative;
}

.ghostRectangle {
  height: 25px;
  width: 100%;
  @extend .animated-background;
}

.ghostcircle {
  height: 25px;
  width: 25px;
  border-radius: 1rem;
  @extend .animated-background;
}

.ghostSquare {
  height: 25px;
  width: 25px;
  @extend .animated-background;
}

.ghostOval {
  height: 25px;
  width: 40px;
  border-radius: 5px;
  @extend .animated-background;
}

/* POP OVER*/
@mixin fix-arrow-position-for-retina($direction) {
  .arrow {

    &:before,
    &:after {
      #{$direction}: 2px !important; // Fix visual gap on retina displays
    }
  }
}

//Common class to remove underline from the mat-form-field
.underline-hide {
  .mat-form-field-appearance-legacy .mat-form-field-underline {
    background: none;
    display: none;
  }
}

.mat-form-field-appearance-outline .mat-form-field-outline{
  color: #aeaeae;
}

//Common class to remove wrapper from the mat-form-field
.no-wrapper {
  .mat-form-field-appearance-legacy .mat-form-field-wrapper {
    padding-bottom: 0px;
  }
}

//table css
table.custom-table {
  border-collapse: collapse;
  text-align: left;
  width: 100%;

  thead {
    tr {
      border-bottom: 1px solid $ag_grey-darkest;
    }
  }

  tbody {
    tr {
      border-bottom: 1px solid $ag_grey-lighter;

      &:last-child {
        border-bottom: none;
      }
    }
  }

  th,
  td {
    @extend .ag_text-overflow--hidden;
    padding: 10px;
  }
}

// .table td,
// .table th {
//   vertical-align: middle;
// }

//icons

.icon-minus:before,
.icon-plus:before {
  color: $ag_navy-blue-darkest;
  font-size: 2rem;
}

//label
label.ag_form--label {
  font-size: 13px;
  display: block;
  margin-bottom: 5px;
  white-space: nowrap;
}

.submodule-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

// .table-container {
//   flex-grow: 1;
//   padding: 1.25rem;
//   user-select: none;
// }

.header-wrapper .ag_toggle {
  margin-bottom: 0rem !important;
}

.ag-tab--theme-bg {
  .mat-tab-labels {
    background-color: $ag_navy-blue-lighter;
  }
}

.active-icon {
  margin-right: 0.5rem;
  font-size: 0.75rem;

  &:before {
    color: $green;
  }
}

.inActive-icon {
  margin-right: 0.5rem;
  font-size: 0.75rem;

  &:before {
    color: $red;
  }
}

//Checkbox with Circle Icon
.snc__checkbox--icon {
  padding: 0.9375rem 0 0 0.9375rem;

  .icon-confirmed {
    font-size: 1.5rem;
    cursor: pointer;
    color: $check-box-outer;

    &.disabled {
      opacity: 0.5;
      pointer-events: none !important;
    }

    &.selected-icon {
      color: $navy-blue-darkest;
      pointer-events: all;
    }
  }

  .check-label {
    font-size: 14px;
    margin-left: 0.5rem;
    vertical-align: super;
  }
}

.ag_common-label-width {
  min-width: 12rem;
  display: block;
}

// Right Side Summary Section Classes
.ag_card-section--wrapper {
  background-color: $ag_grey-lightest;
  width: 400px;
  padding: 20px;
  height: 100%;
}

.ag_card-row--wrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}

.ag_card-value--label {
  font-size: 16px;
  font-weight: bold;
}

.ag_card-section--seperator {
  border-top: 1px solid $ag_grey-lighter;
  padding-top: 20px;
}

.ag_total-bg {
  background-color: $navy-blue-lighter;
}

p.ag_state--headers {
  color: $ag_grey-darkest;
  font-size: $font-size20 !important; // change this px to rem
  @extend .ag_font--normal;
  font-family: $ag_lato-medium;
}

.ag_pointer {
  cursor: pointer;
}

.ag_textarea {
  min-height: 10vh;
  max-height: 30vh;
}

.ag_textarea {
  min-height: 10vh;
  max-height: 30vh;
}

.required--color {
  color: $ag_red;
  font-size: $font-size20;
}


.d-none {
  display: none !important;
}

// Width CSS
.w-0 {
  width: 0;
}

.w-20 {
  width: 20%;
}

.w-30 {
  width: 30%;
}

.w-33 {
  width: 33.33%;
}

.w-40 {
  width: 40%;
}

.w-50 {
  width: 50%;
}

.w-60 {
  width: 60%;
}

.w-66 {
  width: 66%;
}

.w-80 {
  width: 80%;
}

.w-70 {
  width: 70%;
}

.w-75 {
  width: 75%;
}

.w-87 {
  width: 87%;
}

.w-90 {
  width: 90%;
}

.w-100 {
  width: 100%;
}

.w-307px {
  width: 307px;
}

.pr8 {
  padding-right: 0.5rem;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.in-block {
  display: inline-block;
}

.position--relative {
  position: relative;
}

.position--absolute {
  position: absolute;
}

.text-center {
  text-align: center;
}

// Padding CSS

.ag-p-0 {
  padding: 0;
}

.ag-p-1 {
  padding: 0.25rem;
}

.ag-p-2 {
  padding: 0.5rem;
}

.ag-p-3 {
  padding: 0.75rem;
}

.ag-p-4 {
  padding: 1rem;
}

.ag-p-5 {
  padding: 1.25rem;
}

.ag-p-6 {
  padding: 1.5rem;
}

.ag-p-7 {
  padding: 1.75rem;
}

.ag-p-8 {
  padding: 2rem;
}

.ag-pl-0 {
  padding-left: 0;
}

.ag-pl-1 {
  padding-left: 0.25rem;
}

.ag-pl-2 {
  padding-left: 0.5rem;
}

.ag-pl-3 {
  padding-left: 0.75rem;
}

.ag-pl-4 {
  padding-left: 1rem;
}

.ag-pl-5 {
  padding-left: 1.25rem;
}

.ag-pl-6 {
  padding-left: 1.5rem;
}

.ag-pl-7 {
  padding-left: 1.75rem;
}

.ag-pl-8 {
  padding-left: 2rem;
}

.ag-pr-0 {
  padding-right: 0;
}

.ag-pr-1 {
  padding-right: 0.25rem;
}

.ag-pr-2 {
  padding-right: 0.5rem;
}

.ag-pr-3 {
  padding-right: 0.75rem;
}

.ag-pr-4 {
  padding-right: 1rem;
}

.ag-pr-5 {
  padding-right: 1.25rem;
}

.ag-pr-6 {
  padding-right: 1.5rem;
}

.ag-pr-7 {
  padding-right: 1.75rem;
}

.ag-pr-8 {
  padding-right: 2rem;
}

.ag-pt-0 {
  padding-top: 0;
}

.ag-pt-1 {
  padding-top: 0.25rem;
}

.ag-pt-2 {
  padding-top: 0.5rem;
}

.ag-pt-3 {
  padding-top: 0.75rem;
}

.ag-pt-4 {
  padding-top: 1rem;
}

.ag-pt-5 {
  padding-top: 1.25rem;
}

.ag-pt-6 {
  padding-top: 1.5rem;
}

.ag-pt-7 {
  padding-top: 1.75rem;
}

.ag-pt-8 {
  padding-top: 2rem;
}

.ag-pb-0 {
  padding-bottom: 0;
}

.ag-pb-1 {
  padding-bottom: 0.25rem;
}

.ag-pb-2 {
  padding-bottom: 0.5rem;
}

.ag-pb-3 {
  padding-bottom: 0.75rem;
}

.ag-pb-4 {
  padding-bottom: 1rem;
}

.ag-pb-5 {
  padding-bottom: 1.25rem;
}

.ag-pb-6 {
  padding-bottom: 1.5rem;
}

.ag-pb-7 {
  padding-bottom: 1.75rem;
}

.ag-pb-8 {
  padding-bottom: 2rem;
}

//Margin CSS

.ag-m-0 {
  margin: 0;
}

.ag-m-1 {
  margin: 0.25rem;
}

.ag-m-2 {
  margin: 0.5rem;
}

.ag-m-3 {
  margin: 0.75rem;
}

.ag-m-4 {
  margin: 1rem;
}

.ag-m-5 {
  margin: 1.25rem;
}

.ag-m-6 {
  margin: 1.5rem;
}

.ag-m-7 {
  margin: 1.75rem;
}

.ag-m-8 {
  margin: 2rem;
}

.ag-ml-auto {
  margin-left: auto;
}

.ag-ml-0 {
  margin-left: 0;
}

.ag-ml-1 {
  margin-left: 0.25rem;
}

.ag-ml-2 {
  margin-left: 0.5rem;
}

.ag-ml-3 {
  margin-left: 0.75rem;
}

.ag-ml-4 {
  margin-left: 1rem;
}

.ag-ml-5 {
  margin-left: 1.25rem;
}

.ag-ml-6 {
  margin-left: 1.5rem;
}

.ag-ml-7 {
  margin-left: 1.75rem;
}

.ag-ml-8 {
  margin-left: 2rem;
}

.ag-mr-auto {
  margin-right: auto;
}

.ag-mr-0 {
  margin-right: 0;
}

.ag-mr-1 {
  margin-right: 0.25rem;
}

.ag-mr-2 {
  margin-right: 0.5rem;
}

.ag-mr-3 {
  margin-right: 0.75rem;
}

.ag-mr-4 {
  margin-right: 1rem;
}

.ag-mr-5 {
  margin-right: 1.25rem;
}

.ag-mr-6 {
  margin-right: 1.5rem;
}

.ag-mr-7 {
  margin-right: 1.75rem;
}

.ag-mr-8 {
  margin-right: 2rem;
}

.ag-mt-auto {
  margin-top: auto;
}

.ag-mt-0 {
  margin-top: 0;
}

.ag-mt-1 {
  margin-top: 0.25rem;
}

.ag-mt-2 {
  margin-top: 0.5rem;
}

.ag-mt-3 {
  margin-top: 0.75rem;
}

.ag-mt-4 {
  margin-top: 1rem;
}

.ag-mt-5 {
  margin-top: 1.25rem;
}

.ag-mt-6 {
  margin-top: 1.5rem;
}

.ag-mt-7 {
  margin-top: 1.75rem;
}

.ag-mt-8 {
  margin-top: 2rem;
}

.ag-mb-auto {
  margin-bottom: auto;
}

.ag-mb-0 {
  margin-bottom: 0;
}

.ag-mb-1 {
  margin-bottom: 0.25rem;
}

.ag-mb-2 {
  margin-bottom: 0.5rem;
}

.ag-mb-3 {
  margin-bottom: 0.75rem;
}

.ag-mb-4 {
  margin-bottom: 1rem;
}

.ag-mb-5 {
  margin-bottom: 1.25rem;
}

.ag-mb-6 {
  margin-bottom: 1.5rem;
}

.ag-mb-7 {
  margin-bottom: 1.75rem;
}

.ag-mb-8 {
  margin-bottom: 2rem;
}

.d-block {
  display: block;
}

// Overall Outer Padding
.golf-container--padding {
  padding: 20px;
}

.padding-top-none {
  padding-top: 0;
}

.padding-bottom-none {
  padding-bottom: 0;
}

//Form CSS
.golf-page--headers {
  margin-bottom: 20px;
  font-weight: normal;
}

h4 {
  &.golf-form--headers {
    font-size: 16px !important; // change this px to rem
    margin-bottom: 10px;
    font-weight: normal;
    font-family: $golf-lato-medium;
  }

  &.golf-page--headers {
    font-size: 16px !important; // change this px to rem
  }
}

.golf-form--label {
  font-size: 13px;
  display: block;
  margin-bottom: 10px;
  white-space: nowrap;
}

.golf-form--text {
  font-size: 16px;
  display: block;
  margin-bottom: 20px;
}

.golf-form-control {
  padding-right: $default-control-padding;
}

.golf-form-control--lg {
  @extend .golf-form-control;
  width: 100%;
}

.golf-form-control--md {
  @extend .golf-form-control;
  width: 75%;
}

.golf-form-control--sm {
  @extend .golf-form-control;
  width: 50%;
}

.golf-form-control--xs {
  @extend .golf-form-control;
  width: 25%;
}

.golf-radio--horizontal {
  @extend .ag-mb-4;
}


.footer-button--actions {
  width: 100%;
  padding: $default-control-padding;
  background-color: $golf-base-white-bg;
  border-top: 1px solid $border-grey;
}

// UI-SWITCH Common CSS
ui-switch {
  cursor: pointer;

  .switch.checked.checked small {
    right: 6px;
  }

  small {
    width: 12px !important;
    height: 12px !important;
    top: 3.6px !important;
  }

  .switch.checked {
    background: $theme-base-color;
  }

  .switch-pane {
    span {
      font-size: 12px !important;
      // font-family: $golf-lato-bold;
    }
  }

  .switch {
    background: $toggle-uncheck-color;
    color: $golf-base-white-bg !important;
    border: none !important;
    // margin-bottom: 20px;
  }

  .switch.switch-medium {
    height: 20px;
  }

  .switch.switch-medium>.switch-pane>span {
    line-height: 20px;
  }
}

//Common class to remove underline from the mat-form-field
.underline-hide {
  .mat-form-field-appearance-legacy .mat-form-field-underline {
    background: none;
    display: none;
  }
}

//Common class to remove wrapper from the mat-form-field
.no-wrapper {
  .mat-form-field-appearance-legacy .mat-form-field-wrapper {
    padding-bottom: 0px;
  }
}

// // Utilities Common CSS

// Radio Button Common CSS
.golf--form-radio-button {
  clear: both;
  padding-right: 0.625rem;

  .mat-radio-label {
    font-size: 14px;
  }

  .mat-radio-label {
    .mat-radio-container {
      width: $radio-size;
      height: $radio-size;

      .mat-radio-outer-circle {
        width: $radio-size;
        height: $radio-size;
      }
    }
  }

  &.mat-radio-checked {
    .mat-radio-label {
      .mat-radio-container {
        .mat-radio-outer-circle {
          border-color: $theme-base-color;
          border-width: 1px;
        }

        .mat-radio-inner-circle {
          background-color: $theme-base-color;
          transform: scale(0.8);
          width: $radio-size;
          height: $radio-size;
        }
      }
    }
  }
}

//Checkbox with Circle Icon
.golf__checkbox--icon {
  padding: 0.9375rem 0 0 0.9375rem;

  .icon-confirmed {
    font-size: 1.5rem;
    cursor: pointer;
    color: $check-box-outer;

    &.selected-icon {
      color: $theme-base-color;
    }
  }

  .check-label {
    font-size: 14px;
    margin-left: 0.5rem;
    vertical-align: super;
  }
}

//table css
.table-type--static {
  border: 1px solid $header-color;
  border-collapse: collapse;
  text-align: left;
  width: 100%;

  .table-type--static-header {
    background-color: $header-color;

    .table-type--static-header-th {
      @extend .golf__text--overflow;
      padding: 10px;
      font-weight: normal;

      // &:last-child {
      //   text-align: right;
      // }
    }
  }

  .table-type--static-row {
    border-bottom: 1px solid $header-color;

    .table-type--static-row-td {
      @extend .golf__text--overflow;
      padding: 7px 10px;

      .td-input {
        border: 1px solid $header-color;
        border-radius: 5px;
        padding: 7px;
        width: 75px;
        // float: right;
      }

      .td-select {
        .mat-form-field-infix {
          border-top-width: 5px;
          padding: 0.4785rem 0;
        }

        .mat-form-field-wrapper {
          padding-bottom: 2px;
        }

        .mat-select-arrow-wrapper {
          transform: none;
        }

        &.mat-form-field-appearance-outline {
          &:not(.mat-form-field-disabled) {
            .mat-form-field-flex {
              &:hover {
                .mat-form-field-outline-thick {
                  color: $theme-base-color;
                }
              }
            }
          }
        }
      }
    }

    &:last-child {
      border-bottom: none;
    }
  }

  .switch {
    @extend .ag-mb-0;
  }
}

//TimePicker Colors
.timepicker__header {
  background: $theme-base-color !important;
}

.clock-face__number {
  span {
    &.active {
      background: $theme-base-color !important;
    }
  }
}

.timepicker-button {
  color: $theme-base-color !important;
}

.clock-face__clock-hand {
  background: $theme-base-color !important;

  &::before {
    border: 4px solid $theme-base-color !important;
  }

  &::after {
    background-color: $theme-base-color !important;
  }
}

// Custom Scroll Bar Styles
/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 7px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $golf-grey-text-color;
  border-radius: 5px;
}

.golf__link {
  color: $theme-base-color;
  text-decoration: none;
}

.golf__link--sm {
  @extend .golf__link;
  font-size: 12px;
}

.golf__link--lg {
  @extend .golf__link;
  font-size: 14px;
}

.golf__link--disabled {
  pointer-events: none;
  cursor: not-allowed;
  color: $disabled-color;
}

.golf-tertiary-header {
  height: $tertiary-header-height;
  background-color: $setting-search-header-bg;
  padding: 0 $default-container-padding;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.golf__date-picker {
  .mat-form-field-wrapper {
    .mat-form-field-flex {
      .mat-form-field-infix {
        display: none;
      }
    }

    .mat-form-field-underline {
      display: none;
    }
  }
}

.golf-module-nav {
  &.hideSubnavs {

    .menu-wrapper,
    .golf-secondary-header {
      display: none;
    }

    .golf-module-nav__outlet,
    .golf-subnav-container {
      height: 100%;
    }
  }
}


// ---display flex----

.display-flex-wrap {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}

/****As Per StyleGuide_V.1.1_#****/
.text-uppercase {
  text-transform: uppercase;
}

.text-capitalize {
  text-transform: capitalize;
}


// Text aligment
.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.norecords-alignment {
  height: 100%;
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;

  .no-schedule-img {
    div {
      display: flex;
      align-items: center;
      flex-direction: column;
    }
  }
}

.active-icon {
  margin-right: 0.5rem;
  font-size: 0.75rem;

  &:before {
    color: $green;
  }
}

.inActive-icon {
  margin-right: 0.5rem;
  font-size: 0.75rem;

  &:before {
    color: $red;
  }
}

// Ghost Css
// Animation
@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }

  100% {
    background-position: 468px 0;
  }
}

.animated-background {
  animation-duration: 1.25s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #f6f6f6;
  background: linear-gradient(to right, #f6f6f6 8%, #f0f0f0 18%, #f6f6f6 33%);
  background-size: 800px 104px;
  height: 96px;
  position: relative;
}

//Activities Retail Integration
.mat-horizontal-stepper-content {
  height: 100%;
}

.text-align-right {
  text-align: right;
}

/* Icon Style */
i {
  &.IC6 {
    font-size: 6px;
  }

  &.IC7 {
    font-size: 7px;
  }

  &.IC8 {
    font-size: 8px;
  }

  &.IC9 {
    font-size: 9px;
  }

  &.IC10 {
    font-size: 10px;
  }

  &.IC11 {
    font-size: 11px;
  }

  &.IC12 {
    font-size: 12px;
  }

  &.IC13 {
    font-size: 13px;
  }

  &.IC14 {
    font-size: 14px;
  }

  &.IC15 {
    font-size: 15px;
  }

  &.IC16 {
    font-size: 16px;
  }

  &.IC17 {
    font-size: 17px;
  }

  &.IC18 {
    font-size: 18px;
  }

  &.IC19 {
    font-size: 19px;
  }

  &.IC20 {
    font-size: 20px;
  }

  &.IC21 {
    font-size: 21px;
  }

  &.IC22 {
    font-size: 22px;
  }

  &.IC23 {
    font-size: 23px;
  }

  &.IC24 {
    font-size: 24px;
  }

  &.IC25 {
    font-size: 25px;
  }

  &.IC26 {
    font-size: 26px;
  }

  &.IC27 {
    font-size: 27px;
  }

  &.IC28 {
    font-size: 28px;
  }

  &.IC29 {
    font-size: 29px;
  }

  &.IC30 {
    font-size: 30px;
  }
}


.DDRemoveUnderline {
  .mat-form-field-underline {
    display: none !important;
  }
}

.label-color {
  color: $label-color;
}

.value-color {
  color: $value-color;
}

.prevent-action {
  pointer-events: none;
  opacity: 0.4;
}

.prevent-action-one {
  pointer-events: none;
}

// .mat-error {
//   font-size: $font-size12;
// }

.custMB8 {
  margin-bottom: 8px !important;
}

// __<<ngThemingMigrationEscapedComment31>>__
#cover-spin,
#custom-cover-spin,
#setting-cover-spin {
  position: fixed;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  // background-color: transparent;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  display: none;
}

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.theme-loader {
  position: fixed;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 9999;
  display: none;
}

@-webkit-keyframes loader {
  from {
    -webkit-transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes loader {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.theme-loader::after {
  content: "";
  display: block;
  width: 60px;
  height: 60px;
  border-style: solid;
  border-color: #43a047;
  border-top-color: transparent;
  border-width: 4px;
  border-radius: 50%;
  -webkit-animation: spin 0.8s linear infinite;
  animation: spin 0.8s linear infinite;
}

#cover-spin::after,
#custom-cover-spin:after,
#setting-cover-spin::after {
  content: "";
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  width: 60px;
  height: 60px;
  border-style: solid;
  border-color: $theme-base-color;
  border-top-color: transparent;
  border-width: 4px;
  border-radius: 50%;
  -webkit-animation: spin 0.8s linear infinite;
  animation: spin 0.8s linear infinite;
}

#custom-cover-message {
  position: absolute;
  top: calc(50% + 80px);
  left: calc(50% + 30px);
  color: #fff;
  transform: translate(-50%, -50%);
}

.dynamic-scrollbar {
  .ng-scrollbar-thumb {
    display: none;
  }

  //   // .ng-scrollbar-view {
  //   //   overflow: hidden;
  //   // }
}

/** time Out css */
.timepickerCustBtn {
  display: inline-block;
  height: 36px;
  min-width: 88px;
  line-height: 36px;
  border: 12px;
  border-radius: 2px;
  background-color: transparent;
  text-align: center;
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1);
  overflow: hidden;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: relative;
  cursor: pointer;
  outline: 0;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 600;
  padding-left: 16px;
  padding-right: 16px;
  font-family: Roboto, sans-serif;
}

.timepickerCustBtn:hover {
  background-color: rgba(153, 153, 153, 0.2);
}

.AgysMatCustTooltip {
  word-break: break-word;
}

.body-bgcolor {
  background-color: $theme-base-color !important;
}

.body-bordercolor {
  border: 1px solid $theme-base-color !important;
}

.golf-section__disable {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.5;
}

.select-container-bg {
  // height: 55px;

  .select-container {
    height: 55px;
    padding: 0px 30px 0px 30px;
  }
}

//Overriding Retail CSS for Credit Card
.creditcard-info .creditcard-details,
.miscSection .formMargin {
  @extend .ag_container--padding-md;
}

.connect-btn {
  .icon-device {
    padding-right: 0.5rem;
  }
}

//icon default colors

.icon-dashboard-cross:before {
  content: "\ebd7";
  background: linear-gradient(-90deg, #fa9386, #de6758);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.icon-new-user:before {
  content: "\ebdd";
  background: linear-gradient(-90deg, #a5adf8, #7481f1);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.icon-swap1:before {
  content: "\ebe0";
  background: linear-gradient(-90deg, #6ed6f8, #3bc2ee);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.icon-dashboard-tick:before {
  content: "\ebe1";
  background: linear-gradient(-90deg, #73ecb4, #37df91);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.icon-cloudy .path1:before {
  content: "\ea42";
  color: rgb(255, 216, 0);
}

.icon-cloudy .path2:before {
  content: "\ea43";
  margin-left: -1.4111328125em;
  color: rgb(255, 255, 255);
  text-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
}

.icon-thunder .path1:before {
  content: "\ea44";
  color: rgb(255, 216, 59);
}

.icon-thunder .path2:before {
  content: "\ea45";
  margin-left: -1.0595703125em;
  color: rgb(13, 145, 224);
}

.icon-thunder .path3:before {
  content: "\ea46";
  margin-left: -1.0595703125em;
  color: rgb(13, 145, 224);
}

.icon-thunder .path4:before {
  content: "\ea47";
  margin-left: -1.0595703125em;
  color: rgb(246, 246, 246);
}

.icon-light .path1:before {
  content: "\ea48";
  color: rgb(255, 216, 59);
}

.icon-light .path2:before {
  content: "\ea49";
  margin-left: -1.0595703125em;
  color: rgb(13, 145, 224);
}

.icon-light .path3:before {
  content: "\ea4a";
  margin-left: -1.0595703125em;
  color: rgb(246, 246, 246);
}

.icon-emptyshop {
  >span {
    &:before {
      color: $ag_base-color !important;
    }

    &.path11:before {
      color: rgb(255, 255, 255) !important;
    }
  }
}

.icon-Shop_nocart {
  >span {
    &:before {
      color: $ag_base-color !important;
    }
  }
}

.icon-No-Search-Results-2 .path1:before {
  content: "\e986";
  color: #ebf4eb;
}

.icon-No-Search-Results-2 .path2:before {
  content: "\e987";
  color: #4d8af0;
  margin-left: -1.404296875em;
}

.icon-No-Search-Results-2 .path3:before {
  content: "\e988";
  color: #fdd835;
  margin-left: -1.404296875em;
}

.icon-No-Search-Results-2 .path4:before {
  content: "\e989";
  color: #47e6b1;
  opacity: 0.5 !important;
  margin-left: -1.404296875em;
}

.icon-No-Search-Results-2 .path5:before {
  content: "\e98a";
  color: #f55f44;
  margin-left: -1.404296875em;
}

.icon-No-Search-Results-2 .path6:before {
  content: "\e98b";
  color: #d7d7d7;
  /* opacity: 0.5; */
  margin-left: -1.404296875em;
}

.icon-No-Search-Results-2 .path7:before {
  content: "\e98c";
  color: #fff;
  margin-left: -1.404296875em;
}

.icon-No-Search-Results-2 .path8:before {
  content: "\e98d";
  color: #000;
  margin-left: -1.404296875em;
}

.icon-No-Search-Results-2 .path9:before {
  content: "\e98e";
  color: #fff;
  margin-left: -1.404296875em;
}

.icon-No-Search-Results-2 .path10:before {
  content: "\e98f";
  color: #bdbdbd;
  margin-left: -1.404296875em;
}

.icon-No-Search-Results-2 .path11:before {
  content: "\e990";
  color: #e0e0e0;
  margin-left: -1.404296875em;
}

.icon-No-Search-Results-2 .path12:before {
  content: "\e991";
  color: #2665c0;
  opacity: 0.9 !important;
  margin-left: -1.404296875em;
}

.icon-No-Search-Results-2 .path13:before {
  content: "\e992";
  color: #000;
  margin-left: -1.404296875em;
}

.icon-No-Search-Results-2 .path14:before {
  content: "\e993";
  color: #fff;
  margin-left: -1.404296875em;
}

.icon-No-Search-Results-2 .path15:before {
  content: "\e994";
  color: #bdbdbd;
  margin-left: -1.404296875em;
}

.icon-No-Search-Results-2 .path16:before {
  content: "\e995";
  color: #e0e0e0;
  margin-left: -1.404296875em;
}

.icon-No-Search-Results-2 .path17:before {
  content: "\e996";
  color: #2665c0;
  opacity: 0.9 !important;
  margin-left: -1.404296875em;
}

.icon-No-Search-Results-2 .path18:before {
  content: "\e997";
  color: #000;
  margin-left: -1.404296875em;
}

.icon-No-Search-Results-2 .path19:before {
  content: "\e998";
  color: #fff;
  margin-left: -1.404296875em;
}

.icon-No-Search-Results-2 .path20:before {
  content: "\e999";
  color: #bdbdbd;
  margin-left: -1.404296875em;
}

.icon-No-Search-Results-2 .path21:before {
  content: "\e99a";
  color: #e0e0e0;
  margin-left: -1.404296875em;
}

.icon-No-Search-Results-2 .path22:before {
  content: "\e99b";
  color: #e0e0e0;
  margin-left: -1.404296875em;
}

.icon-No-Search-Results-2 .path23:before {
  content: "\e99c";
  color: #2665c0;
  opacity: 0.9 !important;
  margin-left: -1.404296875em;
}

.icon-No-Search-Results-2 .path24:before {
  content: "\e99d";
  color: #e0e0e0;
  margin-left: -1.404296875em;
}

.icon-No-Search-Results-2 .path25:before {
  content: "\e99e";
  color: #2665c0;
  margin-left: -1.404296875em;
}

.icon-no-appointment-booked .path1:before {
  content: "\ea4b";
  color: rgb(67, 160, 71);
  opacity: 0.1;
}

.icon-no-appointment-booked .path2:before {
  content: "\ea4c";
  margin-left: -1.494140625em;
  color: rgb(108, 99, 255);
}

.icon-no-appointment-booked .path3:before {
  content: "\ea4d";
  margin-left: -1.494140625em;
  color: #2665c0;
  opacity: 0.354;
}

.icon-no-appointment-booked .path4:before {
  content: "\ea4e";
  margin-left: -1.494140625em;
  color: #2665c0;
  opacity: 0.354;
}

.icon-no-appointment-booked .path5:before {
  content: "\ea4f";
  margin-left: -1.494140625em;
  color: #2665c0;
  opacity: 0.354;
}

.icon-no-appointment-booked .path6:before {
  content: "\ea50";
  margin-left: -1.494140625em;
  color: #2665c0;
  opacity: 0.354;
}

.icon-no-appointment-booked .path7:before {
  content: "\ea51";
  margin-left: -1.494140625em;
  color: #2665c0;
  opacity: 0.354;
}

.icon-no-appointment-booked .path8:before {
  content: "\ea52";
  margin-left: -1.494140625em;
  color: #2665c0;
  opacity: 0.354;
}

.icon-no-appointment-booked .path9:before {
  content: "\ea53";
  margin-left: -1.494140625em;
  color: #2665c0;
  opacity: 0.354;
}

.icon-no-appointment-booked .path10:before {
  content: "\ea54";
  margin-left: -1.494140625em;
  color: #2665c0;
  opacity: 0.354;
}

.icon-no-appointment-booked .path11:before {
  content: "\ea55";
  margin-left: -1.494140625em;
  color: #2665c0;
  opacity: 0.354;
}

.icon-no-appointment-booked .path12:before {
  content: "\ea56";
  margin-left: -1.494140625em;
  color: #2665c0;
  opacity: 0.354;
}

.icon-no-appointment-booked .path13:before {
  content: "\ea57";
  margin-left: -1.494140625em;
  color: #2665c0;
  opacity: 0.354;
}

.icon-no-appointment-booked .path14:before {
  content: "\ea58";
  margin-left: -1.494140625em;
  color: #2665c0;
  opacity: 0.354;
}

.icon-no-appointment-booked .path15:before {
  content: "\ea59";
  margin-left: -1.494140625em;
  color: #2665c0;
  opacity: 0.354;
}

.icon-no-appointment-booked .path16:before {
  content: "\ea5a";
  margin-left: -1.494140625em;
  color: #2665c0;
  opacity: 0.354;
}

.icon-no-appointment-booked .path17:before {
  content: "\ea5b";
  margin-left: -1.494140625em;
  color: #2665c0;
  opacity: 0.354;
}

.icon-no-appointment-booked .path18:before {
  content: "\ea5c";
  margin-left: -1.494140625em;
  color: #2665c0;
  opacity: 0.354;
}

.icon-no-appointment-booked .path19:before {
  content: "\ea5d";
  margin-left: -1.494140625em;
  color: #2665c0;
  opacity: 0.354;
}

.icon-no-appointment-booked .path20:before {
  content: "\eba1";
  margin-left: -1.494140625em;
  color: #2665c0;
  opacity: 0.354;
}

.icon-no-appointment-booked .path21:before {
  content: "\eba2";
  margin-left: -1.494140625em;
  color: #2665c0;
  opacity: 0.354;
}

.icon-no-appointment-booked .path22:before {
  content: "\eba3";
  margin-left: -1.494140625em;
  color: #2665c0;
  opacity: 0.354;
}

.icon-no-appointment-booked .path23:before {
  content: "\eba4";
  margin-left: -1.494140625em;
  color: #2665c0;
  opacity: 0.354;
}

.icon-no-appointment-booked .path24:before {
  content: "\eba5";
  margin-left: -1.494140625em;
  color: #2665c0;
  opacity: 0.354;
}

.icon-no-appointment-booked .path25:before {
  content: "\eba6";
  margin-left: -1.494140625em;
  color: #2665c0;
  opacity: 0.354;
}

.icon-no-appointment-booked .path26:before {
  content: "\eba7";
  margin-left: -1.494140625em;
  color: #2665c0;
  opacity: 0.354;
}

.icon-no-appointment-booked .path27:before {
  content: "\eba8";
  margin-left: -1.494140625em;
  color: #2665c0;
  opacity: 0.354;
}

.icon-no-appointment-booked .path28:before {
  content: "\eba9";
  margin-left: -1.494140625em;
  color: #2665c0;
  opacity: 0.354;
}

.icon-no-appointment-booked .path29:before {
  content: "\ebaa";
  margin-left: -1.494140625em;
  color: #2665c0;
  opacity: 0.354;
}

.icon-no-appointment-booked .path30:before {
  content: "\ebab";
  margin-left: -1.494140625em;
  color: #2665c0;
  opacity: 0.354;
}

.icon-no-appointment-booked .path31:before {
  content: "\ebac";
  margin-left: -1.494140625em;
  color: #2665c0;
  opacity: 0.354;
}

.icon-no-appointment-booked .path32:before {
  content: "\ebad";
  margin-left: -1.494140625em;
  color: #2665c0;
  opacity: 0.354;
}

.icon-no-appointment-booked .path33:before {
  content: "\ebae";
  margin-left: -1.494140625em;
  color: #2665c0;
  opacity: 0.354;
}

.icon-no-appointment-booked .path34:before {
  content: "\ebaf";
  margin-left: -1.494140625em;
  color: #2665c0;
  opacity: 0.354;
}

.icon-no-appointment-booked .path35:before {
  content: "\ebb0";
  margin-left: -1.494140625em;
  color: rgb(68, 64, 83);
}

.icon-no-appointment-booked .path36:before {
  content: "\ebb1";
  margin-left: -1.494140625em;
  color: rgb(68, 64, 83);
}

.icon-no-appointment-booked .path37:before {
  content: "\ebb2";
  margin-left: -1.494140625em;
  color: rgb(68, 64, 83);
}

.icon-no-appointment-booked .path38:before {
  content: "\ebb3";
  margin-left: -1.494140625em;
  color: rgb(67, 160, 71);
}

.icon-no-appointment-booked .path39:before {
  content: "\ebb4";
  margin-left: -1.494140625em;
  color: rgb(63, 61, 86);
}

.icon-no-appointment-booked .path40:before {
  content: "\ebb5";
  margin-left: -1.494140625em;
  color: rgb(63, 61, 86);
}

.icon-no-appointment-booked .path41:before {
  content: "\ebb6";
  margin-left: -1.494140625em;
  color: rgb(63, 61, 86);
}

.icon-no-appointment-booked .path42:before {
  content: "\ebb7";
  margin-left: -1.494140625em;
  color: rgb(63, 61, 86);
}

.icon-no-appointment-booked .path43:before {
  content: "\ebb8";
  margin-left: -1.494140625em;
  color: rgb(63, 61, 86);
}

.icon-no-appointment-booked .path44:before {
  content: "\ebb9";
  margin-left: -1.494140625em;
  color: rgb(63, 61, 86);
}

.icon-no-appointment-booked .path45:before {
  content: "\ebba";
  margin-left: -1.494140625em;
  color: rgb(63, 61, 86);
}

.icon-no-appointment-booked .path46:before {
  content: "\ebbb";
  margin-left: -1.494140625em;
  color: rgb(67, 160, 71);
}

.icon-no-appointment-booked .path47:before {
  content: "\ebbc";
  margin-left: -1.494140625em;
  color: #2665c0;
  opacity: 0.1;
}

.icon-no-appointment-booked .path48:before {
  content: "\ebbd";
  margin-left: -1.494140625em;
  color: rgb(255, 255, 255);
}

.icon-no-appointment-booked .path49:before {
  content: "\ebbe";
  margin-left: -1.494140625em;
  color: rgb(0, 0, 0);
  opacity: 0.1;
}

.icon-no-appointment-booked .path50:before {
  content: "\ebbf";
  margin-left: -1.494140625em;
  color: rgb(0, 0, 0);
  opacity: 0.1;
}

.icon-no-appointment-booked .path51:before {
  content: "\ebc0";
  margin-left: -1.494140625em;
  color: rgb(236, 235, 237);
  opacity: 0.343;
}

.icon-no-appointment-booked .path52:before {
  content: "\ebc1";
  margin-left: -1.494140625em;
  color: rgb(68, 64, 83);
  opacity: 0.1;
}

.icon-no-appointment-booked .path53:before {
  content: "\ebc2";
  margin-left: -1.494140625em;
  color: rgb(68, 64, 83);
  opacity: 0.1;
}

.icon-no-appointment-booked .path54:before {
  content: "\ebc3";
  margin-left: -1.494140625em;
  color: rgb(68, 64, 83);
  opacity: 0.1;
}

.icon-no-appointment-booked .path55:before {
  content: "\ebc4";
  margin-left: -1.494140625em;
  color: rgb(67, 160, 71);
}

.icon-no-appointment-booked .path56:before {
  content: "\ebc5";
  margin-left: -1.494140625em;
  color: rgb(68, 64, 83);
  opacity: 0.1;
}

.icon-no-appointment-booked .path57:before {
  content: "\ebc6";
  margin-left: -1.494140625em;
  color: rgb(68, 64, 83);
  opacity: 0.1;
}

.icon-no-appointment-booked .path58:before {
  content: "\ebc7";
  margin-left: -1.494140625em;
  color: rgb(68, 64, 83);
  opacity: 0.1;
}

.icon-no-appointment-booked .path59:before {
  content: "\ebc8";
  margin-left: -1.494140625em;
  color: rgb(67, 160, 71);
}

.icon-no-appointment-booked .path60:before {
  content: "\ebc9";
  margin-left: -1.494140625em;
  color: rgb(0, 0, 0);
  opacity: 0.1;
}

.icon-no-appointment-booked .path61:before {
  content: "\ebca";
  margin-left: -1.494140625em;
  color: rgb(0, 0, 0);
  opacity: 0.1;
}

.icon-no-appointment-booked .path62:before {
  content: "\ebcb";
  margin-left: -1.494140625em;
  color: rgb(67, 160, 71);
  opacity: 0.1;
}

.icon-no-appointment-booked .path63:before {
  content: "\ebcc";
  margin-left: -1.494140625em;
  color: rgb(67, 160, 71);
  opacity: 0.1;
}

.icon-no-appointment-booked .path64:before {
  content: "\ebcd";
  margin-left: -1.494140625em;
  color: rgb(67, 160, 71);
  opacity: 0.1;
}

.icon-no-appointment-booked .path65:before {
  content: "\ebce";
  margin-left: -1.494140625em;
  color: rgb(67, 160, 71);
  opacity: 0.1;
}

.icon-coins:before {
  content: "\eb03";
  color: #26ebc0;
}

.icon-password-show1:before {
  content: "\ea02";
  color: #2665c0;
}

.icon-No_records_found_aftersearch1 .path1:before {
  content: "\eb49";
  color: #2665c0;
  opacity: 0.204;
}

.icon-No_records_found_aftersearch1 .path2:before {
  content: "\eb60";
  margin-left: -1.4921875em;
  color: rgb(224, 224, 224);
}

.icon-No_records_found_aftersearch1 .path3:before {
  content: "\eb61";
  margin-left: -1.4921875em;
  color: rgb(108, 99, 255);
  opacity: 0.7;
}

.icon-No_records_found_aftersearch1 .path4:before {
  content: "\eb62";
  margin-left: -1.4921875em;
  color: rgb(224, 224, 224);
}

.icon-No_records_found_aftersearch1 .path5:before {
  content: "\eb63";
  margin-left: -1.4921875em;
  color: rgb(224, 224, 224);
}

.icon-No_records_found_aftersearch1 .path6:before {
  content: "\eb64";
  margin-left: -1.4921875em;
  color: rgb(224, 224, 224);
}

.icon-No_records_found_aftersearch1 .path7:before {
  content: "\eb65";
  margin-left: -1.4921875em;
  color: rgb(224, 224, 224);
}

.icon-No_records_found_aftersearch1 .path8:before {
  content: "\eb66";
  margin-left: -1.4921875em;
  color: rgb(224, 224, 224);
}

.icon-No_records_found_aftersearch1 .path9:before {
  content: "\eb67";
  margin-left: -1.4921875em;
  color: rgb(224, 224, 224);
}

.icon-No_records_found_aftersearch1 .path10:before {
  content: "\eb68";
  margin-left: -1.4921875em;
  color: rgb(224, 224, 224);
}

.icon-No_records_found_aftersearch1 .path11:before {
  content: "\eb69";
  margin-left: -1.4921875em;
  color: rgb(0, 0, 0);
  opacity: 0.5;
}

.icon-No_records_found_aftersearch1 .path12:before {
  content: "\eb6a";
  margin-left: -1.4921875em;
  color: rgb(245, 245, 245);
}

.icon-No_records_found_aftersearch1 .path13:before {
  content: "\eb6b";
  margin-left: -1.4921875em;
  color: rgb(0, 0, 0);
  opacity: 0.5;
}

.icon-No_records_found_aftersearch1 .path14:before {
  content: "\eb6c";
  margin-left: -1.4921875em;
  color: rgb(255, 255, 255);
}

.icon-No_records_found_aftersearch1 .path15:before {
  content: "\eb6d";
  margin-left: -1.4921875em;
  color: rgb(108, 99, 255);
  opacity: 0.4;
}

.icon-No_records_found_aftersearch1 .path16:before {
  content: "\eb6e";
  margin-left: -1.4921875em;
  color: rgb(108, 99, 255);
  opacity: 0.4;
}

.icon-No_records_found_aftersearch1 .path17:before {
  content: "\eb6f";
  margin-left: -1.4921875em;
  color: rgb(0, 0, 0);
  opacity: 0.5;
}

.icon-No_records_found_aftersearch1 .path18:before {
  content: "\eb70";
  margin-left: -1.4921875em;
  color: rgb(245, 245, 245);
}

.icon-No_records_found_aftersearch1 .path19:before {
  content: "\eb71";
  margin-left: -1.4921875em;
  color: rgb(0, 0, 0);
  opacity: 0.5;
}

.icon-No_records_found_aftersearch1 .path20:before {
  content: "\eb72";
  margin-left: -1.4921875em;
  color: rgb(255, 255, 255);
}

.icon-No_records_found_aftersearch1 .path21:before {
  content: "\eb73";
  margin-left: -1.4921875em;
  color: rgb(189, 189, 189);
  opacity: 0.4;
}

.icon-No_records_found_aftersearch1 .path22:before {
  content: "\eb74";
  margin-left: -1.4921875em;
  color: rgb(189, 189, 189);
  opacity: 0.4;
}

.icon-No_records_found_aftersearch1 .path23:before {
  content: "\eb75";
  margin-left: -1.4921875em;
  color: rgb(0, 0, 0);
  opacity: 0.5;
}

.icon-No_records_found_aftersearch1 .path24:before {
  content: "\eb76";
  margin-left: -1.4921875em;
  color: rgb(0, 0, 0);
  opacity: 0.5;
}

.icon-No_records_found_aftersearch1 .path25:before {
  content: "\eb77";
  margin-left: -1.4921875em;
  color: rgb(255, 255, 255);
}

.icon-No_records_found_aftersearch1 .path26:before {
  content: "\eb78";
  margin-left: -1.4921875em;
  color: rgb(255, 255, 255);
}

.icon-No_records_found_aftersearch1 .path27:before {
  content: "\eb79";
  margin-left: -1.4921875em;
  color: rgb(0, 0, 0);
}

.icon-No_records_found_aftersearch1 .path28:before {
  content: "\eb7a";
  margin-left: -1.4921875em;
  color: rgb(0, 0, 0);
}

.icon-No_records_found_aftersearch1 .path29:before {
  content: "\eb7b";
  margin-left: -1.4921875em;
  color: rgb(255, 255, 255);
}

.icon-No_records_found_aftersearch1 .path30:before {
  content: "\eb7c";
  margin-left: -1.4921875em;
  color: rgb(189, 189, 189);
}

.icon-No_records_found_aftersearch1 .path31:before {
  content: "\eb7d";
  margin-left: -1.4921875em;
  color: rgb(224, 224, 224);
}

.icon-No_records_found_aftersearch1 .path32:before {
  content: "\eb7e";
  margin-left: -1.4921875em;
  color: rgb(224, 224, 224);
}

.icon-No_records_found_aftersearch1 .path33:before {
  content: "\eb7f";
  margin-left: -1.4921875em;
  color: rgb(224, 224, 224);
}

.icon-No_records_found_aftersearch1 .path34:before {
  content: "\eb80";
  margin-left: -1.4921875em;
  color: rgb(224, 224, 224);
}

.icon-No_records_found_aftersearch1 .path35:before {
  content: "\eb81";
  margin-left: -1.4921875em;
  color: #2665c0;
  opacity: 0.9;
}

.icon-No_records_found_aftersearch1 .path36:before {
  content: "\eb82";
  margin-left: -1.4921875em;
  color: rgb(0, 0, 0);
}

.icon-No_records_found_aftersearch1 .path37:before {
  content: "\eb83";
  margin-left: -1.4921875em;
  color: rgb(255, 255, 255);
}

.icon-No_records_found_aftersearch1 .path38:before {
  content: "\eb84";
  margin-left: -1.4921875em;
  color: rgb(189, 189, 189);
}

.icon-No_records_found_aftersearch1 .path39:before {
  content: "\eb85";
  margin-left: -1.4921875em;
  color: rgb(224, 224, 224);
}

.icon-No_records_found_aftersearch1 .path40:before {
  content: "\eb86";
  margin-left: -1.4921875em;
  color: rgb(224, 224, 224);
}

.icon-No_records_found_aftersearch1 .path41:before {
  content: "\eb87";
  margin-left: -1.4921875em;
  color: rgb(224, 224, 224);
}

.icon-No_records_found_aftersearch1 .path42:before {
  content: "\eb88";
  margin-left: -1.4921875em;
  color: rgb(224, 224, 224);
}

.icon-No_records_found_aftersearch1 .path43:before {
  content: "\eb89";
  margin-left: -1.4921875em;
  color: #2665c0;
  opacity: 0.9;
}

.icon-No_records_found_aftersearch1 .path44:before {
  content: "\eb8a";
  margin-left: -1.4921875em;
  color: rgb(0, 0, 0);
}

.icon-No_records_found_aftersearch1 .path45:before {
  content: "\eb8b";
  margin-left: -1.4921875em;
  color: rgb(255, 255, 255);
}

.icon-No_records_found_aftersearch1 .path46:before {
  content: "\eb8c";
  margin-left: -1.4921875em;
  color: rgb(189, 189, 189);
}

.icon-No_records_found_aftersearch1 .path47:before {
  content: "\eb8d";
  margin-left: -1.4921875em;
  color: rgb(224, 224, 224);
}

.icon-No_records_found_aftersearch1 .path48:before {
  content: "\eb8e";
  margin-left: -1.4921875em;
  color: rgb(224, 224, 224);
}

.icon-No_records_found_aftersearch1 .path49:before {
  content: "\eb8f";
  margin-left: -1.4921875em;
  color: rgb(224, 224, 224);
}

.icon-No_records_found_aftersearch1 .path50:before {
  content: "\eb90";
  margin-left: -1.4921875em;
  color: rgb(224, 224, 224);
}

.icon-No_records_found_aftersearch1 .path51:before {
  content: "\eb91";
  margin-left: -1.4921875em;
  color: #2665c0;
  opacity: 0.9;
}

.icon-No_records_found_aftersearch1 .path52:before {
  content: "\eb92";
  margin-left: -1.4921875em;
  color: rgb(0, 0, 0);
}

.icon-No_records_found_aftersearch1 .path53:before {
  content: "\eb93";
  margin-left: -1.4921875em;
  color: rgb(0, 0, 0);
}

.icon-No_records_found_aftersearch1 .path54:before {
  content: "\eb94";
  margin-left: -1.4921875em;
  color: #2665c0;
}

.icon-No_records_found_aftersearch1 .path55:before {
  content: "\eb95";
  margin-left: -1.4921875em;
  color: #2665c0;
}

.icon-No_records_found_aftersearch1 .path56:before {
  content: "\eb96";
  margin-left: -1.4921875em;
  color: #2665c0;
}

.icon-No_records_found_aftersearch1 .path57:before {
  content: "\eb97";
  margin-left: -1.4921875em;
  color: rgb(71, 230, 177);
}

.icon-No_records_found_aftersearch1 .path58:before {
  content: "\eb98";
  margin-left: -1.4921875em;
  color: rgb(71, 230, 177);
}

.icon-No_records_found_aftersearch1 .path59:before {
  content: "\eb99";
  margin-left: -1.4921875em;
  color: rgb(77, 138, 240);
}

.icon-No_records_found_aftersearch1 .path60:before {
  content: "\eb9a";
  margin-left: -1.4921875em;
  color: rgb(253, 216, 53);
}

.icon-No_records_found_aftersearch1 .path61:before {
  content: "\eb9b";
  margin-left: -1.4921875em;
  color: rgb(253, 216, 53);
}

.icon-No_records_found_aftersearch1 .path62:before {
  content: "\eb9c";
  margin-left: -1.4921875em;
  color: rgb(245, 95, 68);
}

.icon-No_records_found_aftersearch1 .path63:before {
  content: "\eb9d";
  margin-left: -1.4921875em;
  color: rgb(245, 95, 68);
}

.icon-No_records_found_aftersearch1 .path64:before {
  content: "\eb9e";
  margin-left: -1.4921875em;
  color: rgb(77, 138, 240);
}

.icon-No_records_found_aftersearch1 .path65:before {
  content: "\eb9f";
  margin-left: -1.4921875em;
  color: rgb(71, 230, 177);
  opacity: 0.5;
}

.icon-No_records_found_aftersearch1 .path66:before {
  content: "\eba0";
  margin-left: -1.4921875em;
  color: rgb(77, 138, 240);
}

.icon-No-Search-Results .path1:before {
  content: "\e979";
  color: rgb(232, 239, 248);
  opacity: 1 !important;
}

.icon-No-Search-Results .path2:before {
  content: "\e97a";
  margin-left: -1.37890625em;
  color: rgb(224, 224, 224);
}

.icon-No-Search-Results .path3:before {
  content: "\e97b";
  margin-left: -1.37890625em;
  color: rgb(255, 255, 255);
}

.icon-No-Search-Results .path4:before {
  content: "\e97c";
  margin-left: -1.37890625em;
  color: rgb(38, 101, 192);
}

.icon-No-Search-Results .path5:before {
  content: "\e97d";
  margin-left: -1.37890625em;
  color: rgb(96, 142, 209);
}

.icon-No-Search-Results .path6:before {
  content: "\e97e";
  margin-left: -1.37890625em;
  color: rgb(224, 224, 224);
}

.icon-No-Search-Results .path7:before {
  content: "\e97f";
  margin-left: -1.37890625em;
  color: rgb(255, 255, 255);
}

.icon-No-Search-Results .path8:before {
  content: "\e980";
  margin-left: -1.37890625em;
  color: rgb(38, 101, 192);
}

.icon-No-Search-Results .path9:before {
  content: "\e981";
  margin-left: -1.37890625em;
  color: rgb(96, 142, 209);
}

.icon-No-Search-Results .path10:before {
  content: "\e982";
  margin-left: -1.37890625em;
  color: rgb(71, 230, 177);
}

.icon-No-Search-Results .path11:before {
  content: "\e983";
  margin-left: -1.37890625em;
  color: rgb(165, 203, 247);
}

.icon-No-Search-Results .path12:before {
  content: "\e984";
  margin-left: -1.37890625em;
  color: rgb(245, 95, 68);
  opacity: 0.5 !important;
}

// .icon-No-Search-Results .path13:before {
//   content: "\ea27";
//   margin-left: -1.37890625em;
//   color: rgb(165, 196, 247);
// }

// .icon-No-Search-Results .path14:before {
//   content: "\ea28";
//   margin-left: -1.37890625em;
//   color: rgb(162, 242, 216);
// }

.icon-Player-worth_enabled .path1:before {
  content: "\ecc2";
  color: $theme-base-color;
}

.icon-Player-worth_enabled .path2:before {
  content: "\ecc3";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

//ngx-material-timepicker latest version fix

.timepicker-overlay {
  z-index: 10000000 !important;
}

.timepicker-backdrop-overlay {
  z-index: 10000000 !important;
}

.timepicker__actions {

  .button--primary:focus,
  .ag_button--primary:focus,
  .picker-ok:focus {
    opacity: 0.8;
    background-color: $golf-button-bg !important;
  }

  .button--tertiary:focus,
  .ag_button--secondary:focus,
  .picker-cancel:focus {
    background-color: $golf-button-bg !important;
  }
}

.ag_form-control {
  padding-right: $default-control-padding;
}

.ag_display--flex {
  display: flex !important;
}

.ag_align-items--center {
  align-items: center;
}

.ag_text--center {
  text-align: center;
}

.ag_cursor--not-allowed {
  cursor: not-allowed;
}

.ag_pointer--none {
  pointer-events: none;
}

.ag_ml--auto {
  margin-left: auto !important;
}

.ag_height--100,
.h-100 {

  height: 100% !important;
}

.ag_display--block {
  display: block !important;
}

.table-container {
  flex-grow: 1;
  padding: 0.75rem 1.25rem 1.25rem 1.25rem;
  user-select: none;
}

.ag_form--label {
  font-size: $font-size12;
  @extend .ag_display--block;
  color: $ag_black-lighter !important;
  margin-bottom: 10px;
  white-space: nowrap;
  color: $black;
}

.ag_w--20 {
  width: 20%;
}

.ag_w--25 {
  width: 25%;
}

// Disables complete section
.ag_section--disable {
  @extend .ag_cursor--not-allowed;
  @extend .ag_pointer--none;
  opacity: 0.5;
}

.swiper-button-next:after,
.swiper-button-prev:after {
  display: none;
}

.hidden-submit {
  position: absolute;
  left: -9999px;
  width: 1px;
  height: 1px;
}

body .cdk-custom-wrapper .ag_table .table th {
  font-weight: unset !important;
}

.popover-body {
  .mat-list-base {
    height: calc(35vh - 20px) !important;
  }
}

// css for toggle slider
.toggle-button {
  .switch {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 20px;
    margin: 0;

    input {
      display: none;
    }

    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      -webkit-transition: .4s;
      border-radius: 34px;
      transition: .4s;
      background-color: #EA6153;
    }

    .slider::before {
      position: absolute;
      content: "";
      height: 16px;
      width: 16px;
      left: 2px;
      bottom: 2px;
      background-color: $color-white;
      -webkit-transition: .4s;
      transition: .4s;
      border-radius: 50%;
    }

    input:checked+.slider:before {
      transform: translateX(22px);
    }

    input:checked+.slider {
      background-color: #27AE60;

      &::before {
        left: 0px;
      }
    }
  }
}


.hidden-submit {
  position: absolute;
  left: -9999px;
  width: 1px;
  height: 1px;
}

.custom-fields {
  .custom-field-mapping {
    app-form-input {
      display: inline-block;
      margin-right: 20px;
      width: 25%;
    }
  }

  .mat-accordion .mat-expansion-panel {
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
    margin: 10px 20px 15px 10px;
    box-shadow: 0 0px 5px $color-backdrop-bg;
    margin-left: 5px;
  }
}

ag-menu {
  .mat-form-field-appearance-legacy {
    .mat-form-field-infix {
      display: block;
    }
  }
}

#SearchItemAutoCompleteSection {
  .mat-form-field-appearance-legacy {
    .mat-form-field-infix {
      font-size: 14px;
      display: inherit;
      border-top: 0px;
    }
  }
}

.retailDiscount .leftSection {
  .mat-form-field-appearance-legacy {
    .mat-form-field-infix {
      display: inherit;
      padding-bottom: 0px;
      border-top: 0px;
    }
  }
}

.inventory .search-bar .search-input .cursor {
  left: 95%;
}

//Activity creation css
.activity-creation {
  .main-menu {
    .activity-description {
      width: 95%;
    }

    .available-for-web-reservation{
      margin-bottom: 2%;
    }

    app-form-input {
      display: inline-block;
      width: 45%;
      margin-right: 5%;
    }

    .basic-setup {
      .lesson-details {
        app-form-switch {
          display: inline-block;
          margin-right: 20%;
          margin-top: 15px;
        }


      }

      //UI revamp CSS override
      .reservation-setup {
        margin-top: 2rem !important;
        dynamic-form form {
          app-form-select, app-group-form-select {
            width: 45%;
            display: inline-block;
            margin-right: 5% !important;
          }
        }
      }
    }

    .activity-setup {
      .ticket-print {
        form.dynamicform {
          gap: 50px;
          display: inline-flex;
        }
      }

      .activity-strength {
        .guest-container {
          app-form-checkbox {
            width: 46%;
          }
        }
      }
    }

    .calender-details {
      .calender-template {
        dynamic-form {
          display: inline-flex;

          form.dynamicform {
            display: flex;
            gap: 5%;
          }
        }
      }

      .activity-sessions {
        .unique-sessions {
          app-form-select {
            display: inline-block;
            margin-right: 30px;
          }

          app-form-timepicker {
            display: inline-block;
            margin-right: 15px;
          }
        }
      }
    }

    .guest-details {
      .guest-settings {
        form.dynamicform {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          gap: 10px;
          grid-auto-rows: minmax(30px, auto);
        }
      }
    }
  }

  .ticket-print,.rafting {
    .chip-control {
      border: 1px solid $color-reservation-seperation-border;
      padding: 10px 10px;
      margin-bottom: 18px;
      border-radius: 3px;
    }
  }
  //UI revamp CSS override
  // .basic-select {
  //   width: 45%;
  //   margin-right: 5%;
  // }

  .payment-select {
    width: 90%;
    margin-right: 5%;
    &.link-type {
      width: 95% !important;
    }
  }
  .differ-payment {
    app-form-input {
      display: inline-block;
      width: 70% !important;
      margin-right: 5%;
    }
  }

  .payment-setup {
    app-form-input {
      width: 90% !important;
    }

  }

  .private-booking {
    .unique-sessions {
      app-form-select {
        display: inline-block;
        width: 40%;
        margin-right: 5%;
      }
    }
  }

  .additional-settings {
    .additional-employee {
      form.dynamicform {
        display: flex;
        gap: 50px;
        margin-bottom: 20px;
      }
    }
    .email-notifications{
      form.dynamicform {
       display: flex;
       flex-direction:column;
       gap:15px;
    }
  }
  }
}

.activity-table{
  .mat-sort-header-content{
    text-align: left;
  }
}

.payment-config {
  dynamic-form {
    display: inline-block;
    width: 50%;
  }
}

.activity-strength {
  .guest-container {
    form.dynamicform {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;

      .mat-checkbox-inner-container {}

      .label.mat-checkbox-layout {
        display: flex;
      }
    }
  }
}

.payment-options {
  position: sticky;
  display: flex;
  justify-content: space-between;
  bottom: 0;
  width: 100%;
  align-items: center;
  background: white;
  padding: 10px 10px;
  gap: 20px;

  .dynamic-radio {
    margin-bottom: 0px !important;
  }
  label.chip-control__label.guest-form-label.form-helper-text {
    margin-right: 10px;
    padding-bottom: 0px;
    font-size:15px;
    margin-bottom:0px;
}
}

.cart__row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
  align-items: center;

  .form--headers {
    margin-top: 5px !important;
  }

  .capture-card-container {
    margin: 0px 20px !important;
  }
}

.cart__payments {
  .payment-options {
    padding: 10px;
    background: #efefef96;
    border-radius: 5px;
    position: revert;
    width: auto;
  }
}

.server-toggle {
  .status-switch {
    width: 200px;
    display: flex;
    gap: 10px;
    align-items: flex-end;
    justify-content: center;
  }
}

*:hover::-webkit-scrollbar, .crs__activity-container::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

.crs__activity-container::-webkit-scrollbar, .crs__activity-container:hover::-webkit-scrollbar{
  width: 7px;
  height: 7px;
}

.class-detail__actions__btn {
  height: 31px !important;
  width: 100px !important;
}

// Payment status

.payment-NA {
  background-color: $color-white !important;
  color: $color-black !important;
}

.payment-Due {
  background-color: $payment-due-color !important;
  color: #ffffff !important;
  white-space: nowrap;
}

.payment-Not-Paid {
  background-color: $payment-notpaid-color !important;
  color: #ffffff !important;
}

.payment-Paid {
  background-color: $payment-paid-color !important;
  color: #ffffff !important;
}

.payment-Waived__Off {
  background-color: $payment-waivedoff-color !important;
  color: #474747 !important;
  border: 1px solid $color-box-bor-color;
  display: flex;
  text-wrap: nowrap;
}

.payment-Refunded, .payment-Refund__pending {
  background-color: $payment-refunded-color !important;
  color: #ffffff !important;
}

.payment-Refund__pending{
  display: flex;
  align-items: center;
  width: max-content;
}

.reservation {
  .dashboard-party-info__guest-preference {
    .messages-popover {
      z-index: 0 !important;
    }
  }
}

.overflow-y-auto {
  overflow-y: auto !important;
}

.noPointerEvents {
  pointer-events: none !important;
}

.width-auto {
  width: auto !important;
}

//Audit Log Reports css
.auditlog-reports dynamic-form form.dynamicform {
  display: inline-flex;
  gap: 20px;

  .mat-form-field-infix {
    width: 250px !important;
  }
}

.auditlog-reports {
  .mat-button {
    width: 10rem;
  }
}

.items-list {
  & .ng-scroll-content {
    vertical-align: middle;
  }

  .mat-select-panel.mat-primary.tnslt-y-60 {
    transform: translateY(55px) !important;
  }
}

.modify-session {
  &__session-mapping {
    .session-edit-mapping-container {
      margin: 3%;

      app-form-select {
        &:first-of-type {
          width: 100%;
        }

        display: inline-block;
        padding-right: 30px;
        width: 50%;
      }

      app-form-timepicker {
        display: inline-block;
        padding-right: 30px;
        width: 50%;

        .mat-form-field {
          width: 100%;
        }

        ngx-material-timepicker-toggle {
          margin-right: -25px;
          right: 25px;
        }
      }
    }
  }

  .session-edit-customize-form-container {
    margin: 3%;

    app-form-input {
      display: inline-block;
      padding-right: 30px;
      width: 50%;
    }

    app-form-textarea {
      width: 100%;
      
      .form-text-area{
        padding-right: 30px;
      }

      mat-form-field {
        width: 100%;
      }
    }
  }
}

.package-info {
  z-index: 1001;
}

.mat-tooltip.modify-session-warning-tooltip {
  font-size: 14px !important;
}

.cursor-initial {
  cursor: initial !important;
}

.field-200 {
  width: 200px;
}

.field-420 {
  width: 420px;
}

//Audit log expansion body height css
.auditlog__item {
  .mat-expansion-panel-content {
    .mat-expansion-panel-body {
    //max-height: 480px;
    //overflow: hidden !important;
    }

  }
}

.audit-section-panel {
  .mat-expansion-panel-content .mat-expansion-panel-body {
    padding: 0;
  }

  .mat-expansion-panel {
    &.mat-expansion-panel-spacing {
      .mat-expansion-panel-header-title {
        border-bottom: none;
      }
    }
  }
}

.privatelesson-booking__cover-type,
.activity-booking__cover-type,
.open-booking__cover-type {
  form.dynamicform {
    display: flex;
    flex-wrap: wrap;
    gap: 0px 35px;

    .mat-form-field-infix {
      width: 100px;
    }
  }
}

.privatelesson-booking__cover-type,
.activity-booking__cover-type,
.open-booking__cover-type {
  form.dynamicform {
    .mat-form-field {
      .mat-tooltip-trigger{
        width: 70px;
        display: block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}
.mat-select-panel.mat-primary.tnslt-y-60{
  transform: translateY(55px) !important;
}

.parties-grid__list{
  .parties-grid__party-info{
    .icon-ic_stay_current_portrait_24px{
      padding-left: 5px !important;
    }
  }
}

.general-info{
  .general-info__time-slot{
    .reservation-timeslot__slotsDiv {
      height: calc(100% - 30px) !important;
      overflow-y: auto !important;
      overflow-x: hidden;
  }
  }
}

.retail-vendor {
  .vendor-filter {
    .filter-wrapper {
      width: 300px !important;
    }
  }

  mat-form-field#searchInput {
    .mat-form-field-label-wrapper {
      width: 95% !important;
    }
  }
}

.gift-card-search {
  .card-number-search {
    height: 85px;
  }
}

.assignCommision {
  .splitCommission {
    height: auto !important;
  }
}

.gift-card-details .mh150 {
  height: auto !important;
}

.vertical-header { 
  font-family: Roboto-Regular !important;
  .navItem {
    ul {
      li {
        &.text {
          a,label {
            font-family: Roboto-Regular !important;
            &:hover {
              color: $color-header-bg !important;
            }
          }
        }
      }
    }
  }
}

.chat-box__disable-message-container{
  .chat-box__send,
  .chat-box__disable {
    color: #b8c0bd !important;
  }

  .mat-select-disabled .mat-select-trigger{
    cursor: not-allowed;
  }
}

.retail-vendor {
  .vendor-filter {
    .filter-wrapper {
      width: 300px !important;
    }
  }

  mat-form-field#searchInput {
    .mat-form-field-label-wrapper {
      width: 95% !important;
    }
  }
}

.gift-card-search {
  .card-number-search {
    height: 85px;
  }
}

.assignCommision {
  .splitCommission {
    height: auto !important;
  }
}


.gift-card-details .mh150 {
  height: auto !important;
}

.ReportSection .report .textcenter-Web #reportData {
  padding: 45px 35px !important;
}

.guestlist__search-by {
  form.dynamicform {
    app-form-select {
      .input-with-boundary {
        width: 200px !important;
      }
    }
  }
}



//Special meal booking form CSS
.warning-button {
  background-color: #b49a7c !important;
}

.warning-button-cancel {
  border: 1px solid #a46617 !important;
  color: #846617 !important;
}
.cover-types{
  form.dynamicform {
    display: flex;
    gap: 0px 30px;
    flex-wrap: wrap;
    justify-content: space-between;
    app-form-select{
      width: 42%;
  }
    .basic-select-dropdown {
      font-size: 16px;
      width: 100%;
  }
}
}
.w-100{
  app-form-select{
    width: 100% !important;
  }
}

  .popover .filter-popover {
    .popover-body {
      padding: 10px !important;
    }
  }

  .tables-view__table-details {
    .dashboard-content
    .dashboard {
      width: 100% !important;
    }
  }

  .edit-popover {
    z-index: 999 !important;
    max-width: 500px !important;
  }

  .crs__view-mode{
    .mat-button-toggle-label-content{
      line-height: 30px;
      font-size: 14px;
    }
  }

  .up-txt{
    text-transform: capitalize;
  }
    
  .cursor-pointer{
    cursor: pointer;
  }

  .mt-10{
    margin-top: 10px;
  }
  //Activities - retail integration
  .available-round-lbl {
    height: 40px;
   }

   .retailChild .retail-header .boxed-icon {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
   }


   //Retail loader CSS 
   .tertiary-container{
    background-color: #f4f5f6;
  }
  .router-container{
    background-color: #f4f5f6;
  }
  #cover-spin, #custom-cover-spin, #setting-cover-spin, #cover-spin-retail {
    position: fixed;
    width: 100%;
    inset: 0;
    background-color: #00000080;
    z-index: 9999;
    display: none;
}
#cover-spin-retail {
  display: block;
}
.customspinnerimg {
  position: absolute;
  left: calc(50% + 9px);
  top: calc(50% + 9px);
  width: 42px;
  height: 46px;
}
#custom-cover-message, #default-message {
  position: absolute;
  top: calc(50% + 80px);
  left: calc(50% + 30px);
  color: #fff;
  transform: translate(-50%,-50%);
}

#cover-spin:after, #custom-cover-spin:after, #setting-cover-spin:after, #cover-spin-retail:after {
  content: "";
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  width: 60px;
  height: 60px;
  border-style: solid;
 // border-color: #945b29;
  border-top-color: transparent;
  border-width: 4px;
  border-radius: 50%;
  animation: spin .8s linear infinite;
}

.settings{
  .config-style{
    border: 1px solid $activity-border-color;
    box-shadow: 5px 5px 3px $activity-border-color;
    background-color: $color-white;
  }
}

.advanced-settings__print-chit {
  margin: 0px 20px;
  .auto-chit-print__switch {
      width: 100%;
      display: flex;
      gap: 10px;
      align-items: flex-end;
      justify-content: space-between;
      padding: 15px;
      border-bottom: 1px solid #0000001f;
  }
}

.advanced-settings {
  .mat-expansion-panel-content .mat-expansion-panel-body {
   // padding: 0px 10px 16px !important;
    padding: 0px 10px 16px !important;
  }
}

.block-staff__form-container, .block-location__form-container {

  app-form-date {
    display: inline-block;
    padding-right: 1.8rem;
    width: 50%;

    .mat-form-field {
      width: 100% !important;
    }
  }

  app-form-select {
    display: inline-block;
    width: 50%;
    padding-right: 1.8rem !important;

    .mat-form-field {
      width: 100% !important;
    }
  }

  app-form-timepicker {
    display: inline-block;
    padding-right: 1.8rem;
    width: 50%;

    .mat-form-field {
      width: 100% !important;
    }
  }

  & .block-location__description{
    padding-right: 1.8rem;
  }

  .disabled {
    opacity: 0.5;
  }
}
.seat-green{
  color: $color-button-action;
}

.block-staff__description {
  padding-right: 1.8rem !important;
}

//reservation page type slelection  CSS
  .page-preference-type{
    margin-bottom:10px;
    margin-top: 10px;
      .dynamic-radio{
          margin-bottom: 0px !important;
      }

}
.user-card__payment--label {
  padding: 3px 10px;
  background: #1dba6f;
  border-radius: 4px;
  color: #fff;
  font-size: 14px;
  text-align: center;
  cursor: pointer;
}

.reservation-charge__paymentIcon {
  float: right;
  padding: 15px 5px 0px 0px !important;
}

//Activities - Retail integration
.client-details-section {
  .search-container {
    .radio-grp {
      margin-bottom: 1rem !important;
    }
  }
}

.shopping-items .viewmore {
  cursor: pointer !important;
}

.newVersionWrapper .prop-actions {
  gap: 10px;
}

#member-selection-popup .dialog-container__actions button.actions__save {
  background-color: $theme-base-color!important;
  color:$white !important;
}

#member-selection-popup .dialog-container__actions button.actions__save.mat-button-disabled {
  background-color: $theme-base-color !important;
  color: $white !important;
  opacity: 0.5 !important;
}

.time-picker-dial ngx-material-timepicker-toggle button {
  display: block !important;
}

ngx-material-timepicker-content .timepicker__actions button {
  &.ag_button--secondary, &.ag_button--primary {
    display: inline-block;
    height: 36px;
    min-width: 88px;
    line-height: 36px;
    border: 12px;
    border-radius: 2px;
    background-color: transparent;
    text-align: center;
    transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1);
    overflow: hidden;
    user-select: none;
    position: relative;
    cursor: pointer;
    outline: 0;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 600;
    padding-left: 16px;
    padding-right: 16px;
    color: $theme-base-color !important; 
  }
}

app-retail-setup mat-horizontal-stepper .mat-horizontal-stepper-wrapper {
  height: 100% !important;
}

.inventoryContainer {
  .invContent {
    a.layout{
      height: 20px !important;
    }
  }
}

.icon-No-Search-Results .path1:before, .icon-No-Search-Results .path4:before, .icon-No-Search-Results .path5:before,
.icon-No-Search-Results .path8:before, .icon-No-Search-Results .path9:before{
color: $theme-base-color !important;
}
.activities-popover,.payee-card-popover{
  z-index: 1000 !important;
}

// Retail CSS overiding 
.moreSection{
  left: 500px !important;
}
.order-summary .row-details .client-inner #customers{
  width: calc(100% - 15px);
  padding-right: 5px;
  margin-right: 0;
}
@media only screen and (max-width: 1180px) {
  .order-summary .order-present-table-view{
    .ng-scroll-content{
      width: calc(100% - 5px);
      overflow-x: auto;
    }
    .Tbl_orderSummary_orderSummaryTable{
      & > tbody {
        background-color: #fff;
      }
    }
  }
}

//UI revamp CSS override
.mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 0 0 0.5em 0 !important;
}

.mat-form-field-appearance-outline .mat-form-field-prefix, .mat-form-field-appearance-outline .mat-form-field-suffix {
  top: 0 !important;
}

.mat-form-field-appearance-outline .mat-form-field-wrapper {
  padding-bottom: 0 !important;
}

.mat-form-field-hint-wrapper {
  padding-top: 1rem !important;
}
.mat-datepicker-toggle {
  position: relative;
  top: 5px !important;
}

.mat-form-field-appearance-outline .mat-form-field-subscript-wrapper {
  height: 2rem;
  .mat-error {
    position: relative;
    top: 0.75rem;
  }
}
.permissions {
  .mat-tab-group {
    .mat-tab-header {
      .mat-tab-label-container {
        .mat-tab-labels {
          .mat-tab-label {
            font-size: 16px !important;
            color: #666666;
            border-right: none;
            opacity: 1;
            min-width: 100px;
            padding: 0 20px;
            font-family: 'Roboto-Regular';
            &.mat-tab-label-active{
              color: unset;
              .tab-name{
               color:#1DA664;
           }
          }
          }
        }
      }
    }
  }
  }

  
.multi-select-search{
   width: 100%;
   padding: 15px 10px;
   border-bottom: 1px solid #d3d3d3;
   background-color: #fff;
   z-index: 9;
   position: sticky;
   top: 0;

   .mat-form-field{
    width: 100%;
   }
   .mat-form-field-wrapper{
    padding-bottom: 0;
   }
   .mat-form-field-appearance-outline .mat-form-field-infix{
    border-top: 5px solid transparent;
   }
 }

 .chat-box__chat-area{
  .mat-form-field-appearance-outline .mat-form-field-outline-start{
    border: none !important;
  }
  .mat-form-field-outline-end{
    border: none !important;
  }
 }
  
 .seat-labels-v2{
  font-size: 14px;
  // padding-bottom: 10px;
 }

 .open-reservation__party-size {
  margin-bottom: 8px;
 }

 
.basic-setup__description {
  margin-bottom: 8px;
}

.pay-value__form-code {
  position: relative;
  bottom: 4px;
}

.basic-date-picker.confirm__date.input-with-boundary.header-dayname{
  .mat-form-field-infix{
    justify-content: center;
  }
}

.commissionValues .Actions {
  .position-relative {
    top: 8px !important;
    .apply-btn .mat-button-wrapper {
      bottom: 4px !important;
      position: relative !important;
    }
  }
}

h4.ag_group--headers {
  margin-bottom: 1rem !important;
}

.spa-searchbox .icon-Search, .spa-searchbox .icon-Cancel {
  right: -10px;
  position: absolute;
  top: 9px;
}

.retail-CustomDataTable .page-header .icon-Search,
 .retail-CustomDataTable .page-header .icon-Cancel {
  position: absolute !important;
  left: 100% !important;
  top: 10px !important;
}

button.mat-focus-indicator.LW14.text-uppercase.mt-3.body-bgcolor.whitecolor.location-btn.p-2.mat-raised-button.mat-button-base {
  padding: 0px 8px !important;
}


@media screen and (max-width: 1024px) {
  .page-title {
    margin-left: 0px !important;
  }
  .headercontainer__restaurant-selection {
    width: 150px !important;
  }
  .confirm__date {
    width: 150px !important;
  }
  .headercontainer__today-btn {
    display: none !important;
  }
  .login__language-selection {
    width: 70px !important;
  }
  .headercontainer__menu-section .headercontainer__display-section {
    width: calc(100% - 480px) !important;
    padding: 10px 0px;
  }
  .seating-area {
    margin-left: 0px !important;
  }
  button.add-schedule-btn {
    width: 100px;
  }
  .field-200 {
    width: 140px !important;
  }
 }

.reservation-guest__search-field-input {
  border-color: #636363;
}

.reservation-guest__search-field-input {
  .mat-form-field-wrapper {
    background-color: $color-white;
  }
}

.application-information {
  activities-app-button{
    button.mat-button {
    width: 133px !important;
    }
  }
}




//Keyboard Styles

.simple-keyboard {
  margin: 0 auto;
  max-width: calc(100% - 20px);
}

.hg-theme-default .hg-button {
  height:60px;
  font-size: 24px;
}

.hg-row:nth-of-type(2),
.hg-row:nth-of-type(4) {
  margin: 0 2em;
}

input{
  // To fix onscreen keyboard focus hides input partly.
  scroll-margin-top: 50px !important;
}

  .icon-section {
    .icon-search{
      top: 10px !important;
    }
  }

.roomAccount-cont {
  margin-top: 30px !important;
  padding: 15px 10px 8px !important;
}

.shopping-items .products .search-div .icon-Cancel {
  top: 6px !important;
}

.button-row {
  button.LW14 .mat-button-wrapper {
    padding: 0px 8px !important;
  }
}

.advanced-search-section{
  .advanced-search-inputs{
    .item-search{
      .icon-Search{
        margin-right: 20px;
      }
    }
  }
}

.activity-row {
  display: flex;
  align-items: center;
  justify-content: center;
  form {
      app-form-select {
          &:nth-child(1) {
              position: relative;
              bottom: 38px;
          }
      }
  }
}

.miscSection .formMargin .gratuityCheck mat-form-field {
  min-width: 300px !important;
}