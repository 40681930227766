@import '../base/variables';
@import '../base/colors';

/*
  ****
  @Define the Media Queries for Tablet, Desktop
  @Params Pass the Paramenter
  ****
*/

$golf-media-queries:(

    "small":  ( breakpoint:'(max-width: #{$small-max-width})'),
    "medium": ( breakpoint:'(min-width: #{$medium-min-width}) and (max-width: #{$medium-max-width})'),
    "large":  ( breakpoint:'(min-width: #{$large-min-width}) and (max-width: #{$large-max-width})')
);


@mixin breakpoint($point) {
  @if $point == $small-tab-devices {
    // Extra small devices (portrait phones, less than 576px)
    @media only screen and (max-width: $small-tab-resolution) {
      @content;
    }
  } @else if $point == $large-tab-devices {
    @media only screen and (min-width: $large-tab-resolution)  {
    // @media only screen and (min-width: 992px) {
      @content;
    }
  } @else if $point == $small-height-devices {
    @media only screen and (min-height: $min-small-height-resolution) and (max-height: $max-small-height-resolution) {
      @content;
    }
  }
}

// @mixin golf-media-query ($size) {
//     @each $item, $value in $golf-media-queries {
//             @if $item == $size {
//                 @if map-get($value, breakpoint) != null {
//                         @media only screen and #{map-get($value, breakpoint)}{
//                             @content;
//                 }
//             }
//         }
//     }
// }


// $circle-width: 25px !default;
// $circle-height: $circle-width !default;
// $circle-bg: $text-white !default;
// $circle-radius: 50% !default;
// $circle-line-height: $circle-width !default;
// $circle-text-align: center !default;
// $circle-text-color: e !default;
// $circle-font-size: $font-14 !default;
// $circle-font-family: $montserrat-regular !default;
@mixin circle($cw:$circle-width, $cb:$circle-bg, $cta:$circle-text-align, $cr:$circle-radius, $cc: $circle-text-color, $cf:$circle-font-size, $cff: $circle-font-family) {
    width: $cw;
    height: $cw;
    background: $cb;
  //  line-height: $cw;
    text-align: $cta;
    display: inline-flex;
    vertical-align: middle;
    color: $cc !important;
    font-size: $cf;
    font-family: $cff;
    justify-content: center;
    align-items: center;
    line-height: $cf;
    @include border-radius($cr);

  }

  @mixin inline-block(){
    position: relative;
    display: block;
  }

  @mixin border-radius($rd){
    border-radius: #{$rd}
  }


  @mixin statusColor($name){
   // @each $key, $value in $reservationStatus {
        @if($name == 'arrived'){
            $selected-reservation-color: $secondaryRed;
        }
        @if($name == 'reserved'){
            $selected-reservation-color: $secondaryBlue;
        }
        @if($name == 'blocked'){
            $selected-reservation-color: $secondaryDarkBrown;
        }
        @if($name == 'seated'){
            $selected-reservation-color: $statusGreen;
        }
        @if($name == 'cancelled'){
            $selected-reservation-color: $color-seat-cancelled;
        }

    }
// @include statusColor()
//   }
//   }
//   $reservationStatus: (
//     arrived: $secondaryRed,
//     reserved: $secondaryBlue,
//     blocked: $secondaryDarkBrown,
//     seated: $statusGreen,
//     cancelled: $color-seat-cancelled
//   )

