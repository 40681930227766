// Custom Theming for Angular Material
@use '@angular/material' as mat;
// For more information: https://material.angular.io/guide/theming
@import '@angular/material/theming';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
$seat-rewrite-typography: mat.define-typography-config(
    $font-family: 'Roboto-Regular',
    $button: mat.define-typography-level(14px, 14px, none),
    $input:  mat.define-typography-level(23px, 1.125, none),
    $caption: mat.define-typography-level(12px, 20px, 400),
);

@include mat.core($seat-rewrite-typography);
/* For use in src/lib/core/theming/_palette.scss */
$md-mcgpalette0: (
    50 : #e4f4ec,
    100 : #bbe4d1,
    200 : #8ed3b2,
    300 : #61c193,
    400 : #3fb37b,
    500 : #1da664,
    600 : #1a9e5c,
    700 : #159552,
    800 : #118b48,
    900 : #0a7b36,
    A100 : #abffc8,
    A200 : #78ffa6,
    A400 : #45ff85,
    A700 : #2bff74,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);



// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$seat-rewrite-primary: mat.define-palette($md-mcgpalette0);
$seat-rewrite-accent: mat.define-palette($md-mcgpalette0, A200, A100, A400);

// The warn palette is optional (defaults to red).
$seat-rewrite-warn: mat.define-palette(mat.$red-palette);

// Create the theme object (a Sass map containing all of the palettes).
$seat-rewrite-theme: mat.define-light-theme($seat-rewrite-primary, $seat-rewrite-accent, $seat-rewrite-warn);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($seat-rewrite-theme);

/* You can add global styles to this file, and also import other style files */