@import "../base/variables";
@import '../base/colors';

.asset-pick-container {

    .mat-step-header {
        pointer-events: none;
    }

    .mat-horizontal-stepper-header-container {
        justify-content: flex-end;
        width: fit-content;
        position: absolute;
        right: 0px;
    }

    .mat-horizontal-content-container {
        padding: 0px;
        position: absolute;
        top: 72px;
        width: 100%;
    }

}

.rntl-pa-filter-container {
    .mat-expansion-panel {
        box-shadow: none;
    }

    .mat-expansion-panel-body {
        padding: 0px !important;
    }

    .mat-expansion-panel-header.mat-expanded {
        height: 40px;
    }

    .mat-expansion-panel-header {
        border-bottom: 1px solid #0000001F;
        padding: 0px;
        border-radius: 0px;
        padding-left: 1rem;
    }

    .mat-expansion-indicator {
        margin-right: 1rem;
    }

    .mat-checkbox-frame {
        border-color: #{$color-button-action}
    }
}

.pick-asset-guest-filter-input {
    width: 450px !important;
}

.pick-asset-list-search {
    height: 40px !important;
    // padding-top: 5px !important;
    // border: 1px solid #8D8D8D !important;
    // border-radius: 6px !important;
}

.pick-asset-guest-search-input {
    @extend .pick-asset-list-search;
    width: 530px !important;
}

.rntl-fav-table-search {
    @extend .pick-asset-list-search;
    width: 350px !important;
}

.rntl-primary-color {
    color: $color-button-action;
}

.rntl-displayNone {
    display: none !important;
}

.rntl-default-label {
    width: fit-content;
    border-radius: 4px;
    padding: 0.5rem;
    font-size: 12px;
    height: 24px;
    display: flex;
    align-items: center;
}

.rntl-availability-high {
    background-color: #E6FFEF;
    color: #1B8F4C;
    @extend .rntl-default-label;
}

.rntl-availability-medium {
    background-color: #FEF4DD;
    color: #B18E13;
    @extend .rntl-default-label;
}

.rntl-availability-low {
    background-color: #FFD1D1;
    color: #830101;
    @extend .rntl-default-label;
}

.rntl-tbl {

    thead {
        position: sticky !important;
        top: 65px !important;
    }

    tr.mat-row {
        height: 70px;
    }

    th.mat-header-cell {
        color: #000;
        font-size: 16px;
        font-weight: bold;
        border-bottom: unset;
    }

    &__row {

        .rntl-tbl-td {
            border-bottom: unset;
            color: #000;
            font-weight: bold;
            height: 70px;
        }

        .rntl-tbl-th {
            color: #000;
            font-weight: bold;
        }
    }

    &__row:nth-child(odd) {
        background-color: #FFF;
    }

    &__row:nth-child(even) {
        background-color: #F4F5F6;
    }

    &__header {
        color: #000;
        background-color: #1781551f;
        text-transform: capitalize;
    }
}

.rntl-tbl.rntl-tbl-dashboard thead {
    position: sticky !important;
    top: 0px !important;
}

.rntl-icon-s2 {
    font-size: 1.5rem;
}

.rntl-text-center {
    text-align: center;
}

.rntl-dashboard__header {
    &--block-type {
        height: 35px;
        border-color: #1da664;

        .mat-button-toggle-label-content {
            line-height: 35px;
        }

        .mat-button-toggle {
            color: #1da664;
            min-width: 120px;
        }

        .mat-button-toggle-checked {
            color: white !important;
        }
    }
}

.rntl-logisticpre-location {
    width: 100%;
}


.rntl-logistic-perference {

    .location-description {
        width: 95%;
      }
      
    app-form-input {
        display: inline-block;
        width: 45%;
        margin-right: 5%;
        margin-top: 10px;
    }
}

.rntl-count {
    font-size: 16px;
    font-weight: 500;
    color: $fontBlack;
    font-family: 'Roboto-Regular';

    span {
        background: $primaryDarkGreen;
        color: $buttonWhite;
        padding: 4px;
        font-size: 13px;
        border-radius: 4px;
        // margin-left: 8px;
    }
}