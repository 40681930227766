@import '../base/variables';
@import './typography';

%pop-up-header-font{
  font-size: 24px;
  color: $color-first-header-text;
  font-family: 'Roboto-Light';
}

%inner-section-padding{
  padding: 24px;
}

a{
  cursor: pointer;
}
.w100{
  width: 100%;
}

.h100{
  height: 100%;
}

.flex-center{
  display: flex;
  justify-content: center;
  align-items: center;
}

.text-ellipsis{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

$font-family-list: $font-family-roboto-light, $font-family-roboto-medium, $font-family-roboto-bold, $font-family-roboto-regular;

@for $i from 1 through 36 {
  .rs-pl-#{$i} {
    padding-left: #{$i}px !important;
  }
  .rs-pr-#{$i} {
    padding-right: #{$i}px !important;
  }
  .rs-pb-#{$i} {
    padding-bottom: #{$i}px !important;
  }
  .rs-pt-#{$i} {
    padding-top: #{$i}px !important;
  }
  .rs-ml-#{$i} {
    margin-left: #{$i}px !important;
  }
  .rs-mr-#{$i} {
    margin-right: #{$i}px !important;
  }
  .rs-mb-#{$i} {
    margin-bottom: #{$i}px !important;
  }
  .rs-mt-#{$i} {
    margin-top: #{$i}px !important;
  }
  .rs-fs-#{$i} {
    font-size: #{$i}px !important;
  }
}

@for $i from 1 through 36 {
  @each $font in $font-family-list {
    .rs-#{$font}-#{$i} {
      font-family: $font !important;
      font-size: #{$i}px !important;
    }
  }
}

.golf__text--overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
