/* Primary Colors */
$primaryDarkGreen:#1DA664;
$primaryHoverGreen:#1dba6f;
$primaryDisabledGreen:#9AD5B8;
$primaryLightGreen:#74BE65;
$primaryDarkGray:#99999b;
$primaryLightGray:#EAEEF1;
$primaryMediumGray:#5C5C5C;
$primaryDarkBlack:#34353A;
$primaryLightBlack:#252527;
$primary-white: #FFFFFF;
$primaryDimGray: #6A6A6A;
$primary-red: #FF0000;

/* Secondary Colors */
$secondaryDarkGray: #A1A6B4;
$secondaryDarkBrown: #C1832E;
//$secondaryLightBrown:#D09F33;
$secondaryOrange: #ED7814;
$secondaryRed: #ED1446;
$secondaryBlue: #5093E1;
$secondaryPurple: #663399;

/* Button Colors */
$buttonRed: #F44336;
$primaryHoverRed:#EF2718;
$primaryDisabledRed:#FAA19B;
$buttonDarkGray: #95989A;
$buttonLightGray:#ABABAB;
$buttonWhite: #FFFFFF;

/* Status Indicator */
$statusGreen: #9CC62B;
$statusPurple: #4D4698;
$statusOrange: #F27935;
$statusBlue: #32A6D5;
$statusGold: #D09F33;
$darkGold: #EA9713;
$statusDarkGray : #A7AAAB;
$statusLightGray : #F3F3F3;
$statusMediumGray: #E8E8E8;
$gainsboro:#E0E0E0;
$whisper: #FCFAFA;
$stack:#8D8E90;
$dove-gray: #707070;
$dove-gray-opacity: rgba(112,112,112,0.5);
$dove-gray-opacity-2: rgba(112,112,112,0.2);
$button-dark-gray-opacity: rgba(149, 151, 152, 0.5);
$alto-light: rgba(221, 221, 221, 0.3);
$alto-dark: rgba(221, 221, 221, 0.8);
$black-opacity:rgba(0,0,0,0.54);
$black-opacity-text:rgba(0,0,0,0.87);
$light-black: rgba(0, 0, 0, 0.1);
$status-medium-gray-opacity: rgba(232, 232, 232, 0.5);
$outer-space: #2F3A36;
$athens-gray:#eeeff4;
$silver-sand: rgba(189, 195, 199, .28);
$light-gray-bg: rgba(171, 171, 171, .59);
$brightGray: rgba(231, 233, 234, .68);


/* Font Color */
$fontBlack: #000000;
$raisin-black: #262626;
$fontDaryGray:#A2A2A2;
$fontLightGray:#95989A;
$font-gray-opacity: rgba(149, 152, 154, 0.7);
$font-gray-opacity-1: rgba(149, 152, 154, 0.12);
$font-gray-opacity-2: rgba(149, 152, 154, 0.4);
$font-gray-opacity-8:rgba(149, 152, 154, 0.8);
$font-gray-opacity-25:rgba(149, 152, 154, 0.2);
$font-primary-dim-gray-opacity-1:rgba(106,106,106,0.06);
$oslo-gray: #959798;
$Eucalyptus: #21935C;
$white-transparent: rgba(255,255,255,0.7);
$wild-sand: #F5F5F5;
$geyser: #D5DDE3;
$geyser-opacity: rgba(213,221,227,0.5);
$athens-gray: #EDEFF5;
$french-gray: #CECECF;
$iron: #e0e0e1;
$gray-border: #f0f0f1;
$silver:#cccccc;
$mercury:#e3e3e3;
$mine-shaft: #383838;
$font-gray-opacity-1: rgba(149, 152, 154, 0.12);
$manatee-opacity: rgba(153, 153, 155, 0.5);
$gray97: #F7F7F7;
$smokyBlack: #0D0D0D;
$green-crayola: #1CA261;
$ghost-white: #F9F9FA;
$spanish-violet: #4E2676;
$grey-color: #B9B9B9;
$slate-gray-opacity-1:rgba(108, 122, 137, 0.1);
/* Toggle Color */
$toggleRed: #EA6153;
$emperor:#504F4F;
$iron: #d1d1d2;
/* Icon Color */
$iconGray: #CECECE;
$disabledGrayColor:#bdbdbd;
$silverTree: #61c092;
$chinese-black: #161616;

/* Panel Color */
$sidePanelGray: #F8F8F8; //not in UI Kit
/*chat History - email color*/
$chatHistoryemailBlue: #EBF4FA; //not in UI Kit
/*chat History - sms outgoing color*/
$chatHistorySMSOutgoingGray: #F1F2F2; //not in UI Kit
/*chat History - sms incoming color*/
$chatHistorySMSIncomingGreen: #E3F0DA; //not in UI Kit
/*activity - loading min light gray color*/
$activityMinLoaderlightGray: #E5E5E5; //not in UI Kit
/*activity - loading min dark gray color*/
$activityMinLoaderDarkGray: #6A6A6A; //not in UI Kit
/*sidenav - servers and tables page indicator*/
$sidenav_indicator_orange: #D47924; //not in UI Kit
/*sidenav - border gray color*/
$sidebar_border_color: #C8C8C9; //not in UI Kit
/*sidenav - serviewview borderbottom gray*/
$sidebar_bottom_gray: #DCDCDC; //not in UI Kit
// Seated color
$seated-color: rgba(29, 166, 100, .68);
$light-silver-sand: rgba(189, 195, 199, 0.28);
$light-lavender: rgba(226, 241, 250, .59);
$light-tea-green: rgba(212, 234, 196, .59);
$light-gray-border: rgba(255, 255, 255, 0.38);
$light-spanish-gray: rgba(149, 152, 154, .1);
$light-ghost-white: #F5F6FB;
$old-silver: #848383;
$oslo-gray-login:#818892;
$error-red:#B00020;
$header-row-color: rgba(231, 233, 234, 1.68);
$header-row-cell: #1C1C1C;
$total-count: #AC4F99;
$total-covers: #92D16A;
$reservations: #7284E5;
$walkins: #FFA05A;
$thunderBird: #BF1B1D;
$tamarillo: #A31313;
$light-tamarillo: rgba(163, 19, 19, .5);
$silver-foil: #AEAEAE;
$dark-charcoal: #333333;
$granite-gray: #666666;
$bright-gray: #EAEAEA;
$default-gray: #808080;
$gray-x11: #BDBDBD;
$spanish-gray: #969696;
$light-silver: #D6D6D6;
$soft-blue: #157bc4;
$grey-light-color: #cbd5e1;
$green-light-color: #bce9d4;
$green-border-color: #54cb94;
$grey-bg-color: #eff1f3;
$light-red-icon: #ee6767;
$grey-soft-color: #a3b2c7;
$yellow-bright: #f2f239;
$dark-blue: #0f5eeb;
$dark-orange: #fd9800;
$LightOrange: unquote('#fda401');