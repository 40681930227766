@font-face {
	font-family: 'Roboto-Regular';
	src: url('../../../assets/fonts/Roboto-Regular/Roboto-Regular.eot');
	src: local('☺'), url('../../../assets/fonts/Roboto-Regular/Roboto-Regular.woff') format('woff'), url('../../../assets/fonts/Roboto-Regular/Roboto-Regular.ttf') format('truetype'), url('../../../assets/fonts/Roboto-Regular/Roboto-Regular.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Roboto-Light';
	src: url('../../../assets/fonts/Roboto-Light/Roboto-Light.eot');
	src: local('☺'), url('../../../assets/fonts/Roboto-Light/Roboto-Light.woff') format('woff'), url('../../../assets/fonts/Roboto-Light/Roboto-Light.ttf') format('truetype'), url('../../../assets/fonts/Roboto-Light/Roboto-Light.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Roboto-Medium';
	src: url('../../../assets/fonts/Roboto-Medium/Roboto-Medium.eot');
	src: local('☺'), url('../../../assets/fonts/Roboto-Medium/Roboto-Medium.woff') format('woff'), 
	url('../../../assets/fonts/Roboto-Medium/Roboto-Medium.ttf') format('truetype'), url('../../../assets/fonts/Roboto-Medium/Roboto-Medium.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}


@font-face {
	font-family: 'Roboto-Bold';
	src: url('../../../assets/fonts/Roboto-Bold/Roboto-Bold.eot');
	src: local('☺'), url('../../../assets/fonts/Roboto-Bold/Roboto-Bold.woff') format('woff'),
	 url('../../../assets/fonts/Roboto-Bold/Roboto-Bold.ttf') format('truetype'), url('../../../assets/fonts/Roboto-Bold/Roboto-Bold.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}

$font-size-11: 11px;
$font-size-12: 12px;
$font-size-14: 14px;
$font-size-15: 15px;
$font-size-16: 16px;
$font-size-18: 18px;
$font-size-20: 20px;
$font-size-21: 21px;
$font-size-22: 22px;
$font-size-23: 23px;
$font-size-24: 24px;
$font-size-26: 26px;
$font-size-33: 33px;

$font-family-roboto-light: 'Roboto-Light';
$font-family-roboto-medium: 'Roboto-Medium';
$font-family-roboto-bold: 'Roboto-Bold';
$font-family-roboto-regular: 'Roboto-Regular';

