
@import '../base/variables';


%seat-mat-tab-label{
    font-size: 23px;
    color: $mat-tab-label-color;
    font-family: 'Roboto-Light';
    opacity: 100%;
}

.mat-tab-label{
  @extend %seat-mat-tab-label;
}