@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?602bqv');
  src:  url('fonts/icomoon.eot?602bqv#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?602bqv') format('truetype'),
    url('fonts/icomoon.woff?602bqv') format('woff'),
    url('fonts/icomoon.svg?602bqv#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  
  /* Enable Ligatures ================ */
  letter-spacing: 0;
  -webkit-font-feature-settings: "liga";
  -moz-font-feature-settings: "liga=1";
  -moz-font-feature-settings: "liga";
  -ms-font-feature-settings: "liga" 1;
  font-feature-settings: "liga";
  -webkit-font-variant-ligatures: discretionary-ligatures;
  font-variant-ligatures: discretionary-ligatures;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-grid:before {
  content: "\edec";
}
.icon-icons:before {
  content: "\edec";
}
.icon-public:before {
  content: "\f32e";
}
.icon-public_off:before {
  content: "\f32d";
}
.icon-add-solid:before {
  content: "\edfc";
}
.icon-minus-outline:before {
  content: "\edff";
}
.icon-clock1:before {
  content: "\edfd";
}
.icon-document:before {
  content: "\edfe";
}
.icon-file-pdf:before {
  content: "\f324";
}
.icon-notification1:before {
  content: "\ee00";
}
.icon-player1:before {
  content: "\f320";
}
.icon-radio-unchecked:before {
  content: "\ee01";
}
.icon-warning:before {
  content: "\ee02";
}
.icon-star-empty:before {
  content: "\ee05";
}
.icon-star-half:before {
  content: "\ee04";
}
.icon-star-full:before {
  content: "\ee03";
}
.icon-AMEX .path1:before {
  content: "\ed90";
  color: rgb(48, 111, 197);
}
.icon-AMEX .path2:before {
  content: "\ed91";
  margin-left: -1.4501953125em;
  color: rgb(255, 255, 255);
}
.icon-AMEX .path3:before {
  content: "\ed92";
  margin-left: -1.4501953125em;
  color: rgb(255, 255, 255);
}
.icon-AMEX .path4:before {
  content: "\ed93";
  margin-left: -1.4501953125em;
  color: rgb(255, 255, 255);
}
.icon-AMEX .path5:before {
  content: "\ed94";
  margin-left: -1.4501953125em;
  color: rgb(255, 255, 255);
}
.icon-AMEX .path6:before {
  content: "\ed95";
  margin-left: -1.4501953125em;
  color: rgb(255, 255, 255);
}
.icon-AMEX .path7:before {
  content: "\ed96";
  margin-left: -1.4501953125em;
  color: rgb(255, 255, 255);
}
.icon-AMEX .path8:before {
  content: "\edab";
  margin-left: -1.4501953125em;
  color: rgb(255, 255, 255);
}
.icon-AMEX .path9:before {
  content: "\edac";
  margin-left: -1.4501953125em;
  color: rgb(255, 255, 255);
}
.icon-AMEX .path10:before {
  content: "\edae";
  margin-left: -1.4501953125em;
  color: rgb(255, 255, 255);
}
.icon-filter_filled:before {
  content: "\edfb";
  color: #fff;
}
.icon-wristband_off:before {
  content: "\edfa";
  color: #5f6368;
}
.icon-wristband:before {
  content: "\edf9";
  color: #5f6368;
}
.icon-Activity:before {
  content: "\edf2";
}
.icon-spa:before {
  content: "\edf0";
}
.icon-itinerary:before {
  content: "\edf8";
}
.icon-respos-compact-grid:before {
  content: "\ede9";
  color: #5f6368;
}
.icon-respos-keyboard:before {
  content: "\eded";
  color: #5f6368;
}
.icon-respos-keyboard-off:before {
  content: "\edee";
  color: #5f6368;
}
.icon-invert-axis:before {
  content: "\edea";
  color: #5f6368;
}
.icon-respos-grid:before {
  content: "\edeb";
  color: #5f6368;
}
.icon-reserve_v1crs:before {
  content: "\eda1";
}
.icon-reserve_crs:before {
  content: "\eda2";
}
.icon-reserve_host:before {
  content: "\eda3";
}
.icon-reserve_restaurant:before {
  content: "\edaa";
}
.icon-rntl-inventory:before {
  content: "\eda9";
}
.icon-offer:before {
  content: "\eda8";
}
.icon-booking-cancel-icon .path1:before {
  content: "\eda5";
  color: rgb(255, 65, 65);
}
.icon-booking-cancel-icon .path2:before {
  content: "\eda6";
  margin-left: -1.0234375em;
  color: rgb(57, 57, 57);
}
.icon-block_1:before {
  content: "\eda7";
}
.icon-check_circle_FILL0_wght400_GRAD0_opsz24:before {
  content: "\eda0";
}
.icon-error_FILL0_wght400_GRAD0_opsz24:before {
  content: "\ed9f";
}
.icon-selection-all .path1:before {
  content: "\ed98";
  color: rgb(128, 128, 128);
}
.icon-selection-all .path2:before {
  content: "\ed9e";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-select-all:before {
  content: "\ed97";
}
.icon-AR_Post:before {
  content: "\ed9a";
}
.icon-Advanced-Search:before {
  content: "\edbe";
}
.icon-Advanced-Search1:before {
  content: "\edbd";
}
.icon-Anniversary:before {
  content: "\e966";
  color: #21935c;
}
.icon-Appointment:before {
  content: "\e900";
}
.icon-Ascending:before {
  content: "\e9bd";
  color: #959798;
}
.icon-Audit:before {
  content: "\eb39";
}
.icon-Auditlog .path1:before {
  content: "\e9f4";
  color: rgb(149, 152, 154);
}
.icon-Auditlog .path2:before {
  content: "\e9f5";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Auditlog .path3:before {
  content: "\e9f6";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Auditlog2:before {
  content: "\e9f3";
  color: #6a6a6a;
}
.icon-Avatar:before {
  content: "\e906";
}
.icon-Baby-Carrier:before {
  content: "\e96a";
  color: #666;
}
.icon-Bell:before {
  content: "\e910";
}
.icon-Birthday:before {
  content: "\e96b";
  color: #666;
}
.icon-Block:before {
  content: "\e909";
  color: #953f3f;
}
.icon-Calender:before {
  content: "\e94c";
}
.icon-Call:before {
  content: "\e95b";
}
.icon-Cancel:before {
  content: "\e911";
}
.icon-Card:before {
  content: "\e90c";
}
.icon-Cash:before {
  content: "\e90b";
}
.icon-Cash1:before {
  content: "\e993";
}
.icon-Cheque:before {
  content: "\e90e";
}
.icon-China-UnionPay .path1:before {
  content: "\ead4";
  color: rgb(221, 2, 40);
}
.icon-China-UnionPay .path2:before {
  content: "\ead5";
  margin-left: -1.5595703125em;
  color: rgb(2, 67, 129);
}
.icon-China-UnionPay .path3:before {
  content: "\ead6";
  margin-left: -1.5595703125em;
  color: rgb(1, 121, 138);
}
.icon-China-UnionPay .path4:before {
  content: "\ead7";
  margin-left: -1.5595703125em;
  color: rgb(255, 255, 255);
}
.icon-China-UnionPay .path5:before {
  content: "\ead8";
  margin-left: -1.5595703125em;
  color: rgb(255, 255, 255);
}
.icon-China-UnionPay .path6:before {
  content: "\ead9";
  margin-left: -1.5595703125em;
  color: rgb(255, 255, 255);
}
.icon-China-UnionPay .path7:before {
  content: "\eada";
  margin-left: -1.5595703125em;
  color: rgb(255, 255, 255);
}
.icon-China-UnionPay .path8:before {
  content: "\eadb";
  margin-left: -1.5595703125em;
  color: rgb(255, 255, 255);
}
.icon-China-UnionPay .path9:before {
  content: "\eadc";
  margin-left: -1.5595703125em;
  color: rgb(255, 255, 255);
}
.icon-China-UnionPay .path10:before {
  content: "\eadd";
  margin-left: -1.5595703125em;
  color: rgb(255, 255, 255);
}
.icon-China-UnionPay .path11:before {
  content: "\eade";
  margin-left: -1.5595703125em;
  color: rgb(255, 255, 255);
}
.icon-China-UnionPay .path12:before {
  content: "\eadf";
  margin-left: -1.5595703125em;
  color: rgb(255, 255, 255);
}
.icon-China-UnionPay .path13:before {
  content: "\eae0";
  margin-left: -1.5595703125em;
  color: rgb(255, 255, 255);
}
.icon-China-UnionPay .path14:before {
  content: "\eae1";
  margin-left: -1.5595703125em;
  color: rgb(255, 255, 255);
}
.icon-China-UnionPay .path15:before {
  content: "\eae2";
  margin-left: -1.5595703125em;
  color: rgb(255, 255, 255);
}
.icon-China-UnionPay .path16:before {
  content: "\eae3";
  margin-left: -1.5595703125em;
  color: rgb(255, 255, 255);
}
.icon-China-UnionPay .path17:before {
  content: "\eae4";
  margin-left: -1.5595703125em;
  color: rgb(255, 255, 255);
}
.icon-China-UnionPay .path18:before {
  content: "\eae5";
  margin-left: -1.5595703125em;
  color: rgb(255, 255, 255);
}
.icon-China-UnionPay .path19:before {
  content: "\eae6";
  margin-left: -1.5595703125em;
  color: rgb(255, 255, 255);
}
.icon-Circle:before {
  content: "\eb3c";
  color: #fff;
}
.icon-Coins:before {
  content: "\e912";
}
.icon-Comp-Redemption:before {
  content: "\ed9c";
}
.icon-Contact:before {
  content: "\e90a";
  color: #5c5c5c;
}
.icon-Cover-reportwhite:before {
  content: "\e9fb";
  color: #fff;
}
.icon-Credit_book:before {
  content: "\ed99";
}
.icon-Credit_book1:before {
  content: "\ed9b";
}
.icon-Decrease:before {
  content: "\e90f";
  color: #fff;
}
.icon-Default:before {
  content: "\e967";
  color: #6a6a6a;
}
.icon-Delete:before {
  content: "\e907";
}
.icon-Descending:before {
  content: "\e9bc";
  color: #959798;
}
.icon-Device:before {
  content: "\e9de";
}
.icon-Diners-Club .path1:before {
  content: "\eaad";
  color: rgb(0, 74, 152);
}
.icon-Diners-Club .path2:before {
  content: "\eaae";
  margin-left: -1.44921875em;
  color: rgb(255, 255, 255);
}
.icon-Diners-Club .path3:before {
  content: "\eaaf";
  margin-left: -1.44921875em;
  color: rgb(35, 31, 32);
}
.icon-Diners-Club .path4:before {
  content: "\eab0";
  margin-left: -1.44921875em;
  color: rgb(35, 31, 32);
}
.icon-Diners-Club .path5:before {
  content: "\eab1";
  margin-left: -1.44921875em;
  color: rgb(35, 31, 32);
}
.icon-Diners-Club .path6:before {
  content: "\eab2";
  margin-left: -1.44921875em;
  color: rgb(35, 31, 32);
}
.icon-Diners-Club .path7:before {
  content: "\eab3";
  margin-left: -1.44921875em;
  color: rgb(35, 31, 32);
}
.icon-Diners-Club .path8:before {
  content: "\eab4";
  margin-left: -1.44921875em;
  color: rgb(35, 31, 32);
}
.icon-Diners-Club .path9:before {
  content: "\eab5";
  margin-left: -1.44921875em;
  color: rgb(35, 31, 32);
}
.icon-Diners-Club .path10:before {
  content: "\eab6";
  margin-left: -1.44921875em;
  color: rgb(35, 31, 32);
}
.icon-Diners-Club .path11:before {
  content: "\eab7";
  margin-left: -1.44921875em;
  color: rgb(35, 31, 32);
}
.icon-Diners-Club .path12:before {
  content: "\eab8";
  margin-left: -1.44921875em;
  color: rgb(35, 31, 32);
}
.icon-Diners-Club .path13:before {
  content: "\eab9";
  margin-left: -1.44921875em;
  color: rgb(35, 31, 32);
}
.icon-Diners-Club .path14:before {
  content: "\eaba";
  margin-left: -1.44921875em;
  color: rgb(35, 31, 32);
}
.icon-Diners-Club .path15:before {
  content: "\eabb";
  margin-left: -1.44921875em;
  color: rgb(35, 31, 32);
}
.icon-Diners-Club .path16:before {
  content: "\eabc";
  margin-left: -1.44921875em;
  color: rgb(35, 31, 32);
}
.icon-Diners-Club .path17:before {
  content: "\eabd";
  margin-left: -1.44921875em;
  color: rgb(35, 31, 32);
}
.icon-Diners-Club .path18:before {
  content: "\eabe";
  margin-left: -1.44921875em;
  color: rgb(35, 31, 32);
}
.icon-Diners-Club .path19:before {
  content: "\eabf";
  margin-left: -1.44921875em;
  color: rgb(35, 31, 32);
}
.icon-Diners-Club .path20:before {
  content: "\eac0";
  margin-left: -1.44921875em;
  color: rgb(35, 31, 32);
}
.icon-Diners-Club .path21:before {
  content: "\eac1";
  margin-left: -1.44921875em;
  color: rgb(35, 31, 32);
}
.icon-Diners-Club .path22:before {
  content: "\eac2";
  margin-left: -1.44921875em;
  color: rgb(35, 31, 32);
}
.icon-Diners-Club .path23:before {
  content: "\eac3";
  margin-left: -1.44921875em;
  color: rgb(35, 31, 32);
}
.icon-Diners-Club .path24:before {
  content: "\eac4";
  margin-left: -1.44921875em;
  color: rgb(35, 31, 32);
}
.icon-Diners-Club .path25:before {
  content: "\eac5";
  margin-left: -1.44921875em;
  color: rgb(35, 31, 32);
}
.icon-Diners-Club .path26:before {
  content: "\eac6";
  margin-left: -1.44921875em;
  color: rgb(35, 31, 32);
}
.icon-Diners-Club .path27:before {
  content: "\eac7";
  margin-left: -1.44921875em;
  color: rgb(35, 31, 32);
}
.icon-Diners-Club .path28:before {
  content: "\eac8";
  margin-left: -1.44921875em;
  color: rgb(35, 31, 32);
}
.icon-Diners-Club .path29:before {
  content: "\eac9";
  margin-left: -1.44921875em;
  color: rgb(35, 31, 32);
}
.icon-Diners-Club .path30:before {
  content: "\eaca";
  margin-left: -1.44921875em;
  color: rgb(35, 31, 32);
}
.icon-Diners-Club .path31:before {
  content: "\eacb";
  margin-left: -1.44921875em;
  color: rgb(35, 31, 32);
}
.icon-Diners-Club .path32:before {
  content: "\eacc";
  margin-left: -1.44921875em;
  color: rgb(35, 31, 32);
}
.icon-Diners-Club .path33:before {
  content: "\eacd";
  margin-left: -1.44921875em;
  color: rgb(35, 31, 32);
}
.icon-Diners-Club .path34:before {
  content: "\eace";
  margin-left: -1.44921875em;
  color: rgb(35, 31, 32);
}
.icon-Diners-Club .path35:before {
  content: "\eacf";
  margin-left: -1.44921875em;
  color: rgb(35, 31, 32);
}
.icon-Diners-Club .path36:before {
  content: "\ead0";
  margin-left: -1.44921875em;
  color: rgb(35, 31, 32);
}
.icon-Diners-Club .path37:before {
  content: "\ead1";
  margin-left: -1.44921875em;
  color: rgb(35, 31, 32);
}
.icon-Diners-Club .path38:before {
  content: "\ead2";
  margin-left: -1.44921875em;
  color: rgb(35, 31, 32);
}
.icon-Diners-Club .path39:before {
  content: "\ead3";
  margin-left: -1.44921875em;
  color: rgb(35, 31, 32);
}
.icon-Discover .path1:before {
  content: "\ea8c";
  color: rgb(255, 255, 255);
}
.icon-Discover .path2:before {
  content: "\ea8d";
  margin-left: -1.5634765625em;
  color: rgb(35, 31, 32);
}
.icon-Discover .path3:before {
  content: "\ea8e";
  margin-left: -1.5634765625em;
  color: rgb(245, 130, 32);
}
.icon-Discover .path4:before {
  content: "\ea8f";
  margin-left: -1.5634765625em;
  color: rgb(0, 0, 0);
}
.icon-Discover .path5:before {
  content: "\ea90";
  margin-left: -1.5634765625em;
  color: rgb(0, 23, 34);
}
.icon-Discover .path6:before {
  content: "\ea91";
  margin-left: -1.5634765625em;
  color: rgb(0, 23, 34);
}
.icon-Discover .path7:before {
  content: "\ea92";
  margin-left: -1.5634765625em;
  color: rgb(0, 23, 34);
}
.icon-Discover .path8:before {
  content: "\ea93";
  margin-left: -1.5634765625em;
  color: rgb(0, 23, 34);
}
.icon-Discover .path9:before {
  content: "\ea94";
  margin-left: -1.5634765625em;
  color: rgb(0, 23, 34);
}
.icon-Discover .path10:before {
  content: "\ea95";
  margin-left: -1.5634765625em;
  color: rgb(0, 23, 34);
}
.icon-Discover .path11:before {
  content: "\ea96";
  margin-left: -1.5634765625em;
  color: rgb(0, 23, 34);
}
.icon-Discover .path12:before {
  content: "\ea97";
  margin-left: -1.5634765625em;
  color: rgb(35, 31, 32);
}
.icon-Discover .path13:before {
  content: "\ea98";
  margin-left: -1.5634765625em;
  color: rgb(35, 31, 32);
}
.icon-Discover .path14:before {
  content: "\ea99";
  margin-left: -1.5634765625em;
  color: rgb(35, 31, 32);
}
.icon-Discover .path15:before {
  content: "\ea9a";
  margin-left: -1.5634765625em;
  color: rgb(35, 31, 32);
}
.icon-Discover .path16:before {
  content: "\ea9b";
  margin-left: -1.5634765625em;
  color: rgb(35, 31, 32);
}
.icon-Discover .path17:before {
  content: "\ea9c";
  margin-left: -1.5634765625em;
  color: rgb(35, 31, 32);
}
.icon-Discover .path18:before {
  content: "\ea9d";
  margin-left: -1.5634765625em;
  color: rgb(35, 31, 32);
}
.icon-Discover .path19:before {
  content: "\ea9e";
  margin-left: -1.5634765625em;
  color: rgb(35, 31, 32);
}
.icon-Discover .path20:before {
  content: "\ea9f";
  margin-left: -1.5634765625em;
  color: rgb(35, 31, 32);
}
.icon-Discover .path21:before {
  content: "\eaa0";
  margin-left: -1.5634765625em;
  color: rgb(35, 31, 32);
}
.icon-Discover .path22:before {
  content: "\eaa1";
  margin-left: -1.5634765625em;
  color: rgb(35, 31, 32);
}
.icon-Discover .path23:before {
  content: "\eaa2";
  margin-left: -1.5634765625em;
  color: rgb(35, 31, 32);
}
.icon-Discover .path24:before {
  content: "\eaa3";
  margin-left: -1.5634765625em;
  color: rgb(35, 31, 32);
}
.icon-Discover .path25:before {
  content: "\eaa4";
  margin-left: -1.5634765625em;
  color: rgb(35, 31, 32);
}
.icon-Discover .path26:before {
  content: "\eaa5";
  margin-left: -1.5634765625em;
  color: rgb(35, 31, 32);
}
.icon-Discover .path27:before {
  content: "\eaa6";
  margin-left: -1.5634765625em;
  color: rgb(35, 31, 32);
}
.icon-Discover .path28:before {
  content: "\eaa7";
  margin-left: -1.5634765625em;
  color: rgb(35, 31, 32);
}
.icon-Discover .path29:before {
  content: "\eaa8";
  margin-left: -1.5634765625em;
  color: rgb(35, 31, 32);
}
.icon-Down-Arrow:before {
  content: "\e918";
}
.icon-Download---Grid:before {
  content: "\edb3";
}
.icon-DragAndDrop:before {
  content: "\e916";
}
.icon-Dropdown-2:before {
  content: "\e917";
  color: #99999b;
}
.icon-Edit:before {
  content: "\e914";
}
.icon-Eggs:before {
  content: "\e968";
  color: #1da664;
}
.icon-Email:before {
  content: "\ecc2";
}
.icon-Email1:before {
  content: "\ecc1";
}
.icon-Expand .path1:before {
  content: "\e9c2";
  color: rgb(29, 166, 100);
}
.icon-Expand .path2:before {
  content: "\e9c3";
  margin-left: -1em;
  color: none;
}
.icon-Expand .path3:before {
  content: "\e9c4";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Favourites:before {
  content: "\e9df";
  color: #969696;
}
.icon-Filter:before {
  content: "\e9be";
}
.icon-First-Page:before {
  content: "\e95c";
}
.icon-Force-run:before {
  content: "\e908";
  color: #1da664;
}
.icon-Force-run-disable:before {
  content: "\e92d";
  color: #9ad5b8;
}
.icon-FriendOfOwner:before {
  content: "\e9fe";
  color: #1da664;
}
.icon-GiftCard:before {
  content: "\e913";
}
.icon-Gluten-Free:before {
  content: "\e96c";
  color: #666;
}
.icon-Grey:before {
  content: "\eb3e";
  color: #9b9b9b;
}
.icon-Group-197:before {
  content: "\e91f";
}
.icon-Group-198:before {
  content: "\e920";
}
.icon-Group-547 .path1:before {
  content: "\e921";
  color: rgb(248, 248, 248);
}
.icon-Group-547 .path2:before {
  content: "\e922";
  margin-left: -1em;
  color: none;
}
.icon-Group-547 .path3:before {
  content: "\e923";
  margin-left: -1em;
  color: rgb(149, 151, 152);
}
.icon-Group-572:before {
  content: "\e924";
}
.icon-Group-573:before {
  content: "\e942";
}
.icon-Group-584:before {
  content: "\e929";
}
.icon-Group-587:before {
  content: "\e919";
  color: #666;
}
.icon-Group-591:before {
  content: "\e91a";
  color: #a1a6b4;
}
.icon-Group-592:before {
  content: "\e92a";
}
.icon-Group-593:before {
  content: "\e92b";
}
.icon-Group-595:before {
  content: "\e92c";
}
.icon-Group-601:before {
  content: "\e92f";
  color: #5c5c5c;
}
.icon-Group-602:before {
  content: "\e93e";
  color: #5c5c5c;
}
.icon-Group-7105:before {
  content: "\e9c9";
  color: #1da664;
}
.icon-Group-7106:before {
  content: "\e9ca";
}
.icon-Group-741:before {
  content: "\e931";
}
.icon-Group-748:before {
  content: "\e932";
}
.icon-Group-749:before {
  content: "\e933";
}
.icon-High-Chair:before {
  content: "\e969";
  color: #1da664;
}
.icon-Interac .path1:before {
  content: "\ea7c";
  color: rgb(255, 202, 5);
}
.icon-Interac .path2:before {
  content: "\ea7d";
  margin-left: -0.994140625em;
  color: rgb(35, 31, 32);
}
.icon-Interac .path3:before {
  content: "\ea7e";
  margin-left: -0.994140625em;
  color: rgb(255, 202, 5);
}
.icon-Interac .path4:before {
  content: "\ea7f";
  margin-left: -0.994140625em;
  color: rgb(35, 31, 32);
}
.icon-Interac .path5:before {
  content: "\ea80";
  margin-left: -0.994140625em;
  color: rgb(35, 31, 32);
}
.icon-Interac .path6:before {
  content: "\ea81";
  margin-left: -0.994140625em;
  color: rgb(35, 31, 32);
}
.icon-Interac .path7:before {
  content: "\ea82";
  margin-left: -0.994140625em;
  color: rgb(35, 31, 32);
}
.icon-Interac .path8:before {
  content: "\ea83";
  margin-left: -0.994140625em;
  color: rgb(255, 255, 255);
}
.icon-Interac .path9:before {
  content: "\ea84";
  margin-left: -0.994140625em;
  color: rgb(255, 255, 255);
}
.icon-Interac .path10:before {
  content: "\ea85";
  margin-left: -0.994140625em;
  color: rgb(35, 31, 32);
}
.icon-Interac .path11:before {
  content: "\ea86";
  margin-left: -0.994140625em;
  color: rgb(35, 31, 32);
}
.icon-Interac .path12:before {
  content: "\ea87";
  margin-left: -0.994140625em;
  color: rgb(35, 31, 32);
}
.icon-Interac .path13:before {
  content: "\ea88";
  margin-left: -0.994140625em;
  color: rgb(35, 31, 32);
}
.icon-Interac .path14:before {
  content: "\ea89";
  margin-left: -0.994140625em;
  color: rgb(35, 31, 32);
}
.icon-Interac .path15:before {
  content: "\ea8a";
  margin-left: -0.994140625em;
  color: rgb(35, 31, 32);
}
.icon-Interac .path16:before {
  content: "\ea8b";
  margin-left: -0.994140625em;
  color: rgb(35, 31, 32);
}
.icon-Item:before {
  content: "\e949";
}
.icon-JCB .path1:before {
  content: "\ea6f";
  color: rgb(15, 84, 157);
}
.icon-JCB .path2:before {
  content: "\ea70";
  margin-left: -1.453125em;
  color: rgb(180, 31, 54);
}
.icon-JCB .path3:before {
  content: "\ea71";
  margin-left: -1.453125em;
  color: rgb(50, 153, 71);
}
.icon-JCB .path4:before {
  content: "\ea72";
  margin-left: -1.453125em;
  color: rgb(243, 242, 242);
}
.icon-JCB .path5:before {
  content: "\ea73";
  margin-left: -1.453125em;
  color: rgb(243, 242, 242);
}
.icon-JCB .path6:before {
  content: "\ea74";
  margin-left: -1.453125em;
  color: rgb(243, 242, 242);
}
.icon-Location:before {
  content: "\e96d";
}
.icon-Lock:before {
  content: "\ecdb";
  color: #818892;
}
.icon-Lost-and-Found:before {
  content: "\ebdc";
}
.icon-Maestro .path1:before {
  content: "\ea75";
  color: rgb(108, 107, 189);
}
.icon-Maestro .path2:before {
  content: "\ea76";
  margin-left: -1.2880859375em;
  color: rgb(211, 32, 17);
}
.icon-Maestro .path3:before {
  content: "\ea77";
  margin-left: -1.2880859375em;
  color: rgb(0, 153, 223);
}
.icon-Maestro .path4:before {
  content: "\ea78";
  margin-left: -1.2880859375em;
  color: rgb(17, 15, 13);
}
.icon-Mask-Group-10:before {
  content: "\e959";
  color: #fff;
}
.icon-Mask-Group-8:before {
  content: "\e955";
  color: #fff;
}
.icon-Mask-Group-9:before {
  content: "\e958";
  color: #fff;
}
.icon-Menu:before {
  content: "\e95e";
  color: #fff;
}
.icon-Menu_search_icon:before {
  content: "\ed1b";
}
.icon-Minus:before {
  content: "\e9dd";
}
.icon-More:before {
  content: "\e91b";
}
.icon-Neighborhood:before {
  content: "\e9ff";
  color: #1da664;
}
.icon-No-Search-Results .path1:before {
  content: "\e979";
  color: rgb(235, 244, 235);
  opacity: 0.997;
}
.icon-No-Search-Results .path2:before {
  content: "\e97a";
  margin-left: -1.37890625em;
  color: rgb(224, 224, 224);
}
.icon-No-Search-Results .path3:before {
  content: "\e97b";
  margin-left: -1.37890625em;
  color: rgb(255, 255, 255);
}
.icon-No-Search-Results .path4:before {
  content: "\e97c";
  margin-left: -1.37890625em;
  color: rgb(67, 160, 71);
}
.icon-No-Search-Results .path5:before {
  content: "\e97d";
  margin-left: -1.37890625em;
  color: rgb(189, 222, 190);
}
.icon-No-Search-Results .path6:before {
  content: "\e97e";
  margin-left: -1.37890625em;
  color: rgb(224, 224, 224);
}
.icon-No-Search-Results .path7:before {
  content: "\e97f";
  margin-left: -1.37890625em;
  color: rgb(255, 255, 255);
}
.icon-No-Search-Results .path8:before {
  content: "\e980";
  margin-left: -1.37890625em;
  color: rgb(67, 160, 71);
}
.icon-No-Search-Results .path9:before {
  content: "\e981";
  margin-left: -1.37890625em;
  color: rgb(189, 222, 190);
}
.icon-No-Search-Results .path10:before {
  content: "\e982";
  margin-left: -1.37890625em;
  color: rgb(71, 230, 177);
}
.icon-No-Search-Results .path11:before {
  content: "\e983";
  margin-left: -1.37890625em;
  color: rgb(165, 203, 247);
}
.icon-No-Search-Results .path12:before {
  content: "\e984";
  margin-left: -1.37890625em;
  color: rgb(245, 95, 68);
  opacity: 0.5;
}
.icon-No-Search-Results .path13:before {
  content: "\e985";
  margin-left: -1.37890625em;
  color: rgb(165, 196, 247);
}
.icon-No-Search-Results .path14:before {
  content: "\e986";
  margin-left: -1.37890625em;
  color: rgb(162, 242, 216);
}
.icon-No-Search-Results-2 .path1:before {
  content: "\e987";
  color: rgb(235, 244, 235);
}
.icon-No-Search-Results-2 .path2:before {
  content: "\e988";
  margin-left: -1.404296875em;
  color: rgb(77, 138, 240);
}
.icon-No-Search-Results-2 .path3:before {
  content: "\e989";
  margin-left: -1.404296875em;
  color: rgb(253, 216, 53);
}
.icon-No-Search-Results-2 .path4:before {
  content: "\e98a";
  margin-left: -1.404296875em;
  color: rgb(71, 230, 177);
  opacity: 0.5;
}
.icon-No-Search-Results-2 .path5:before {
  content: "\e98b";
  margin-left: -1.404296875em;
  color: rgb(245, 95, 68);
}
.icon-No-Search-Results-2 .path6:before {
  content: "\e98c";
  margin-left: -1.404296875em;
  color: rgb(215, 215, 215);
  opacity: 0.5;
}
.icon-No-Search-Results-2 .path7:before {
  content: "\e98d";
  margin-left: -1.404296875em;
  color: rgb(255, 255, 255);
}
.icon-No-Search-Results-2 .path8:before {
  content: "\e98e";
  margin-left: -1.404296875em;
  color: rgb(0, 0, 0);
}
.icon-No-Search-Results-2 .path9:before {
  content: "\e98f";
  margin-left: -1.404296875em;
  color: rgb(255, 255, 255);
}
.icon-No-Search-Results-2 .path10:before {
  content: "\e990";
  margin-left: -1.404296875em;
  color: rgb(189, 189, 189);
}
.icon-No-Search-Results-2 .path11:before {
  content: "\e991";
  margin-left: -1.404296875em;
  color: rgb(224, 224, 224);
}
.icon-No-Search-Results-2 .path12:before {
  content: "\e992";
  margin-left: -1.404296875em;
  color: rgb(67, 160, 71);
  opacity: 0.9;
}
.icon-No-Search-Results-2 .path13:before {
  content: "\e994";
  margin-left: -1.404296875em;
  color: rgb(0, 0, 0);
}
.icon-No-Search-Results-2 .path14:before {
  content: "\e995";
  margin-left: -1.404296875em;
  color: rgb(255, 255, 255);
}
.icon-No-Search-Results-2 .path15:before {
  content: "\e996";
  margin-left: -1.404296875em;
  color: rgb(189, 189, 189);
}
.icon-No-Search-Results-2 .path16:before {
  content: "\e997";
  margin-left: -1.404296875em;
  color: rgb(224, 224, 224);
}
.icon-No-Search-Results-2 .path17:before {
  content: "\e998";
  margin-left: -1.404296875em;
  color: rgb(67, 160, 71);
  opacity: 0.9;
}
.icon-No-Search-Results-2 .path18:before {
  content: "\e999";
  margin-left: -1.404296875em;
  color: rgb(0, 0, 0);
}
.icon-No-Search-Results-2 .path19:before {
  content: "\e99a";
  margin-left: -1.404296875em;
  color: rgb(255, 255, 255);
}
.icon-No-Search-Results-2 .path20:before {
  content: "\e99b";
  margin-left: -1.404296875em;
  color: rgb(189, 189, 189);
}
.icon-No-Search-Results-2 .path21:before {
  content: "\e99c";
  margin-left: -1.404296875em;
  color: rgb(224, 224, 224);
}
.icon-No-Search-Results-2 .path22:before {
  content: "\e99d";
  margin-left: -1.404296875em;
  color: rgb(224, 224, 224);
}
.icon-No-Search-Results-2 .path23:before {
  content: "\e99e";
  margin-left: -1.404296875em;
  color: rgb(67, 160, 71);
  opacity: 0.9;
}
.icon-No-Search-Results-2 .path24:before {
  content: "\e99f";
  margin-left: -1.404296875em;
  color: rgb(224, 224, 224);
}
.icon-No-Search-Results-2 .path25:before {
  content: "\e9a0";
  margin-left: -1.404296875em;
  color: rgb(67, 160, 71);
}
.icon-No-Search-Results1 .path1:before {
  content: "\eedc";
  color: rgb(235, 244, 235);
  opacity: 0.997;
}
.icon-No-Search-Results1 .path2:before {
  content: "\eedd";
  margin-left: -1.37890625em;
  color: rgb(224, 224, 224);
}
.icon-No-Search-Results1 .path3:before {
  content: "\eede";
  margin-left: -1.37890625em;
  color: rgb(255, 255, 255);
}
.icon-No-Search-Results1 .path4:before {
  content: "\eedf";
  margin-left: -1.37890625em;
  color: rgb(67, 160, 71);
}
.icon-No-Search-Results1 .path5:before {
  content: "\eee0";
  margin-left: -1.37890625em;
  color: rgb(189, 222, 190);
}
.icon-No-Search-Results1 .path6:before {
  content: "\eee1";
  margin-left: -1.37890625em;
  color: rgb(224, 224, 224);
}
.icon-No-Search-Results1 .path7:before {
  content: "\eee2";
  margin-left: -1.37890625em;
  color: rgb(255, 255, 255);
}
.icon-No-Search-Results1 .path8:before {
  content: "\eee3";
  margin-left: -1.37890625em;
  color: rgb(67, 160, 71);
}
.icon-No-Search-Results1 .path9:before {
  content: "\eee4";
  margin-left: -1.37890625em;
  color: rgb(189, 222, 190);
}
.icon-No-Search-Results1 .path10:before {
  content: "\eee5";
  margin-left: -1.37890625em;
  color: rgb(71, 230, 177);
}
.icon-No-Search-Results1 .path11:before {
  content: "\eee6";
  margin-left: -1.37890625em;
  color: rgb(165, 203, 247);
}
.icon-No-Search-Results1 .path12:before {
  content: "\eee7";
  margin-left: -1.37890625em;
  color: rgb(245, 95, 68);
  opacity: 0.5;
}
.icon-No-Search-Results1 .path13:before {
  content: "\eee8";
  margin-left: -1.37890625em;
  color: rgb(165, 196, 247);
}
.icon-No-Search-Results1 .path14:before {
  content: "\eee9";
  margin-left: -1.37890625em;
  color: rgb(162, 242, 216);
}
.icon-No-sahre2 .path1:before {
  content: "\e902";
  color: rgb(0, 0, 0);
}
.icon-No-sahre2 .path2:before {
  content: "\e903";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-No-sahre2 .path3:before {
  content: "\e904";
  margin-left: -1em;
  color: rgb(255, 34, 34);
}
.icon-No-share1 .path1:before {
  content: "\e905";
  color: rgb(255, 34, 34);
}
.icon-No-share1 .path2:before {
  content: "\e915";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-No-share1 .path3:before {
  content: "\e91c";
  margin-left: -1em;
  color: rgb(255, 34, 34);
}
.icon-No_records_found_aftersearch .path1:before {
  content: "\ed45";
  color: rgb(197, 141, 11);
  opacity: 0.2040;
}
.icon-No_records_found_aftersearch .path2:before {
  content: "\ed46";
  margin-left: -1.5em;
  color: rgb(224, 224, 224);
}
.icon-No_records_found_aftersearch .path3:before {
  content: "\ed47";
  margin-left: -1.5em;
  color: rgb(108, 99, 255);
  opacity: 0.7;
}
.icon-No_records_found_aftersearch .path4:before {
  content: "\ed48";
  margin-left: -1.5em;
  color: rgb(224, 224, 224);
}
.icon-No_records_found_aftersearch .path5:before {
  content: "\ed49";
  margin-left: -1.5em;
  color: rgb(224, 224, 224);
}
.icon-No_records_found_aftersearch .path6:before {
  content: "\ed4a";
  margin-left: -1.5em;
  color: rgb(224, 224, 224);
}
.icon-No_records_found_aftersearch .path7:before {
  content: "\ed4b";
  margin-left: -1.5em;
  color: rgb(224, 224, 224);
}
.icon-No_records_found_aftersearch .path8:before {
  content: "\ed4c";
  margin-left: -1.5em;
  color: rgb(224, 224, 224);
}
.icon-No_records_found_aftersearch .path9:before {
  content: "\ed4d";
  margin-left: -1.5em;
  color: rgb(224, 224, 224);
}
.icon-No_records_found_aftersearch .path10:before {
  content: "\ed4e";
  margin-left: -1.5em;
  color: rgb(224, 224, 224);
}
.icon-No_records_found_aftersearch .path11:before {
  content: "\ed4f";
  margin-left: -1.5em;
  color: rgb(0, 0, 0);
  opacity: 0.5;
}
.icon-No_records_found_aftersearch .path12:before {
  content: "\ed50";
  margin-left: -1.5em;
  color: rgb(245, 245, 245);
}
.icon-No_records_found_aftersearch .path13:before {
  content: "\ed51";
  margin-left: -1.5em;
  color: rgb(0, 0, 0);
  opacity: 0.5;
}
.icon-No_records_found_aftersearch .path14:before {
  content: "\ed52";
  margin-left: -1.5em;
  color: rgb(255, 255, 255);
}
.icon-No_records_found_aftersearch .path15:before {
  content: "\ed53";
  margin-left: -1.5em;
  color: rgb(108, 99, 255);
  opacity: 0.4;
}
.icon-No_records_found_aftersearch .path16:before {
  content: "\ed54";
  margin-left: -1.5em;
  color: rgb(108, 99, 255);
  opacity: 0.4;
}
.icon-No_records_found_aftersearch .path17:before {
  content: "\ed55";
  margin-left: -1.5em;
  color: rgb(0, 0, 0);
  opacity: 0.5;
}
.icon-No_records_found_aftersearch .path18:before {
  content: "\ed56";
  margin-left: -1.5em;
  color: rgb(245, 245, 245);
}
.icon-No_records_found_aftersearch .path19:before {
  content: "\ed57";
  margin-left: -1.5em;
  color: rgb(0, 0, 0);
  opacity: 0.5;
}
.icon-No_records_found_aftersearch .path20:before {
  content: "\ed58";
  margin-left: -1.5em;
  color: rgb(255, 255, 255);
}
.icon-No_records_found_aftersearch .path21:before {
  content: "\ed59";
  margin-left: -1.5em;
  color: rgb(189, 189, 189);
  opacity: 0.4;
}
.icon-No_records_found_aftersearch .path22:before {
  content: "\ed5a";
  margin-left: -1.5em;
  color: rgb(189, 189, 189);
  opacity: 0.4;
}
.icon-No_records_found_aftersearch .path23:before {
  content: "\ed5b";
  margin-left: -1.5em;
  color: rgb(0, 0, 0);
  opacity: 0.5;
}
.icon-No_records_found_aftersearch .path24:before {
  content: "\ed5c";
  margin-left: -1.5em;
  color: rgb(0, 0, 0);
  opacity: 0.5;
}
.icon-No_records_found_aftersearch .path25:before {
  content: "\ed5d";
  margin-left: -1.5em;
  color: rgb(255, 255, 255);
}
.icon-No_records_found_aftersearch .path26:before {
  content: "\ed5e";
  margin-left: -1.5em;
  color: rgb(255, 255, 255);
}
.icon-No_records_found_aftersearch .path27:before {
  content: "\ed5f";
  margin-left: -1.5em;
  color: rgb(0, 0, 0);
}
.icon-No_records_found_aftersearch .path28:before {
  content: "\ed60";
  margin-left: -1.5em;
  color: rgb(0, 0, 0);
}
.icon-No_records_found_aftersearch .path29:before {
  content: "\ed61";
  margin-left: -1.5em;
  color: rgb(255, 255, 255);
}
.icon-No_records_found_aftersearch .path30:before {
  content: "\ed62";
  margin-left: -1.5em;
  color: rgb(189, 189, 189);
}
.icon-No_records_found_aftersearch .path31:before {
  content: "\ed63";
  margin-left: -1.5em;
  color: rgb(224, 224, 224);
}
.icon-No_records_found_aftersearch .path32:before {
  content: "\ed64";
  margin-left: -1.5em;
  color: rgb(224, 224, 224);
}
.icon-No_records_found_aftersearch .path33:before {
  content: "\ed65";
  margin-left: -1.5em;
  color: rgb(224, 224, 224);
}
.icon-No_records_found_aftersearch .path34:before {
  content: "\ed66";
  margin-left: -1.5em;
  color: rgb(224, 224, 224);
}
.icon-No_records_found_aftersearch .path35:before {
  content: "\ed67";
  margin-left: -1.5em;
  color: rgb(197, 141, 11);
  opacity: 0.9;
}
.icon-No_records_found_aftersearch .path36:before {
  content: "\ed68";
  margin-left: -1.5em;
  color: rgb(0, 0, 0);
}
.icon-No_records_found_aftersearch .path37:before {
  content: "\ed69";
  margin-left: -1.5em;
  color: rgb(255, 255, 255);
}
.icon-No_records_found_aftersearch .path38:before {
  content: "\ed6a";
  margin-left: -1.5em;
  color: rgb(189, 189, 189);
}
.icon-No_records_found_aftersearch .path39:before {
  content: "\ed6b";
  margin-left: -1.5em;
  color: rgb(224, 224, 224);
}
.icon-No_records_found_aftersearch .path40:before {
  content: "\ed6c";
  margin-left: -1.5em;
  color: rgb(224, 224, 224);
}
.icon-No_records_found_aftersearch .path41:before {
  content: "\ed6d";
  margin-left: -1.5em;
  color: rgb(224, 224, 224);
}
.icon-No_records_found_aftersearch .path42:before {
  content: "\ed6e";
  margin-left: -1.5em;
  color: rgb(224, 224, 224);
}
.icon-No_records_found_aftersearch .path43:before {
  content: "\ed6f";
  margin-left: -1.5em;
  color: rgb(197, 141, 11);
  opacity: 0.9;
}
.icon-No_records_found_aftersearch .path44:before {
  content: "\ed70";
  margin-left: -1.5em;
  color: rgb(0, 0, 0);
}
.icon-No_records_found_aftersearch .path45:before {
  content: "\ed71";
  margin-left: -1.5em;
  color: rgb(255, 255, 255);
}
.icon-No_records_found_aftersearch .path46:before {
  content: "\ed72";
  margin-left: -1.5em;
  color: rgb(189, 189, 189);
}
.icon-No_records_found_aftersearch .path47:before {
  content: "\ed73";
  margin-left: -1.5em;
  color: rgb(224, 224, 224);
}
.icon-No_records_found_aftersearch .path48:before {
  content: "\ed74";
  margin-left: -1.5em;
  color: rgb(224, 224, 224);
}
.icon-No_records_found_aftersearch .path49:before {
  content: "\ed75";
  margin-left: -1.5em;
  color: rgb(224, 224, 224);
}
.icon-No_records_found_aftersearch .path50:before {
  content: "\ed76";
  margin-left: -1.5em;
  color: rgb(224, 224, 224);
}
.icon-No_records_found_aftersearch .path51:before {
  content: "\ed77";
  margin-left: -1.5em;
  color: rgb(197, 141, 11);
  opacity: 0.9;
}
.icon-No_records_found_aftersearch .path52:before {
  content: "\ed78";
  margin-left: -1.5em;
  color: rgb(0, 0, 0);
}
.icon-No_records_found_aftersearch .path53:before {
  content: "\ed79";
  margin-left: -1.5em;
  color: rgb(0, 0, 0);
}
.icon-No_records_found_aftersearch .path54:before {
  content: "\ed7a";
  margin-left: -1.5em;
  color: rgb(197, 141, 11);
}
.icon-No_records_found_aftersearch .path55:before {
  content: "\ed7b";
  margin-left: -1.5em;
  color: rgb(197, 141, 11);
}
.icon-No_records_found_aftersearch .path56:before {
  content: "\ed7c";
  margin-left: -1.5em;
  color: rgb(197, 141, 11);
}
.icon-No_records_found_aftersearch .path57:before {
  content: "\ed7d";
  margin-left: -1.5em;
  color: rgb(71, 230, 177);
}
.icon-No_records_found_aftersearch .path58:before {
  content: "\ed7e";
  margin-left: -1.5em;
  color: rgb(71, 230, 177);
}
.icon-No_records_found_aftersearch .path59:before {
  content: "\ed7f";
  margin-left: -1.5em;
  color: rgb(77, 138, 240);
}
.icon-No_records_found_aftersearch .path60:before {
  content: "\ed80";
  margin-left: -1.5em;
  color: rgb(253, 216, 53);
}
.icon-No_records_found_aftersearch .path61:before {
  content: "\ed81";
  margin-left: -1.5em;
  color: rgb(253, 216, 53);
}
.icon-No_records_found_aftersearch .path62:before {
  content: "\ed82";
  margin-left: -1.5em;
  color: rgb(245, 95, 68);
}
.icon-No_records_found_aftersearch .path63:before {
  content: "\ed83";
  margin-left: -1.5em;
  color: rgb(245, 95, 68);
}
.icon-No_records_found_aftersearch .path64:before {
  content: "\ed84";
  margin-left: -1.5em;
  color: rgb(77, 138, 240);
}
.icon-No_records_found_aftersearch .path65:before {
  content: "\ed85";
  margin-left: -1.5em;
  color: rgb(71, 230, 177);
  opacity: 0.5;
}
.icon-No_records_found_aftersearch .path66:before {
  content: "\ed86";
  margin-left: -1.5em;
  color: rgb(77, 138, 240);
}
.icon-No_share-1 .path1:before {
  content: "\f321";
  color: rgb(255, 34, 34);
}
.icon-No_share-1 .path2:before {
  content: "\f322";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-No_share-1 .path3:before {
  content: "\f323";
  margin-left: -1em;
  color: rgb(255, 34, 34);
}
.icon-Notifications:before {
  content: "\e93c";
  color: #fff;
}
.icon-Others:before {
  content: "\e91d";
}
.icon-Path-490:before {
  content: "\e93f";
  color: #666;
}
.icon-Path-495:before {
  content: "\e943";
}
.icon-Path-501:before {
  content: "\e945";
  color: #959798;
}
.icon-Path-502:before {
  content: "\e946";
  color: #959798;
}
.icon-Path-503:before {
  content: "\e941";
  color: #21935c;
}
.icon-Path-506:before {
  content: "\e948";
  color: #666;
}
.icon-Path-507:before {
  content: "\e94a";
}
.icon-Payment:before {
  content: "\e92e";
  color: #666;
}
.icon-Peanuts:before {
  content: "\e965";
  color: #1da664;
}
.icon-Phone:before {
  content: "\e91e";
}
.icon-Player-worth_disabled:before {
  content: "\ecc0";
  color: #626262;
}
.icon-Player-worth_enabled:before {
  content: "\ecc3";
  color: #c2a653;
}
.icon-Plus:before {
  content: "\e9dc";
}
.icon-Refresh_icon .path1:before {
  content: "\e9f7";
  color: rgb(29, 166, 100);
}
.icon-Refresh_icon .path2:before {
  content: "\e9f8";
  margin-left: -1em;
  color: rgb(29, 166, 100);
}
.icon-Refresh_icon .path3:before {
  content: "\e9f9";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Refresh_icon .path4:before {
  content: "\e9fa";
  margin-left: -1em;
  color: rgb(29, 166, 100);
}
.icon-Reopen:before {
  content: "\edb8";
}
.icon-Repeat-Grid-39:before {
  content: "\e925";
}
.icon-Revenue:before {
  content: "\e944";
}
.icon-Revenue1:before {
  content: "\e94d";
}
.icon-Revenue11:before {
  content: "\eafd";
}
.icon-Round_Redeem:before {
  content: "\ecb7";
}
.icon-SMS:before {
  content: "\e9e8";
  color: #666;
}
.icon-Scheduled:before {
  content: "\e926";
}
.icon-Search:before {
  content: "\e927";
}
.icon-Seat_FloorGrey:before {
  content: "\e9ce";
  color: #fff;
}
.icon-Seat_No_Reservation_found .path1:before {
  content: "\e96f";
  color: rgb(235, 244, 235);
  opacity: 0.997;
}
.icon-Seat_No_Reservation_found .path2:before {
  content: "\e970";
  margin-left: -1.40234375em;
  color: rgb(77, 138, 240);
}
.icon-Seat_No_Reservation_found .path3:before {
  content: "\e971";
  margin-left: -1.40234375em;
  color: rgb(253, 216, 53);
}
.icon-Seat_No_Reservation_found .path4:before {
  content: "\e972";
  margin-left: -1.40234375em;
  color: rgb(71, 230, 177);
  opacity: 0.5;
}
.icon-Seat_No_Reservation_found .path5:before {
  content: "\e973";
  margin-left: -1.40234375em;
  color: rgb(245, 95, 68);
}
.icon-Seat_No_Reservation_found .path6:before {
  content: "\e974";
  margin-left: -1.40234375em;
  color: rgb(215, 215, 215);
  opacity: 0.5;
}
.icon-Seat_No_Reservation_found .path7:before {
  content: "\e975";
  margin-left: -1.40234375em;
  color: rgb(255, 255, 255);
}
.icon-Seat_No_Reservation_found .path8:before {
  content: "\e976";
  margin-left: -1.40234375em;
  color: rgb(0, 0, 0);
}
.icon-Seat_No_Reservation_found .path9:before {
  content: "\e977";
  margin-left: -1.40234375em;
  color: rgb(255, 255, 255);
}
.icon-Seat_No_Reservation_found .path10:before {
  content: "\e978";
  margin-left: -1.40234375em;
  color: rgb(189, 189, 189);
}
.icon-Seat_No_Reservation_found .path11:before {
  content: "\e9a1";
  margin-left: -1.40234375em;
  color: rgb(224, 224, 224);
}
.icon-Seat_No_Reservation_found .path12:before {
  content: "\e9a2";
  margin-left: -1.40234375em;
  color: rgb(29, 166, 100);
}
.icon-Seat_No_Reservation_found .path13:before {
  content: "\e9a3";
  margin-left: -1.40234375em;
  color: rgb(0, 0, 0);
}
.icon-Seat_No_Reservation_found .path14:before {
  content: "\e9a4";
  margin-left: -1.40234375em;
  color: rgb(255, 255, 255);
}
.icon-Seat_No_Reservation_found .path15:before {
  content: "\e9a5";
  margin-left: -1.40234375em;
  color: rgb(189, 189, 189);
}
.icon-Seat_No_Reservation_found .path16:before {
  content: "\e9a6";
  margin-left: -1.40234375em;
  color: rgb(224, 224, 224);
}
.icon-Seat_No_Reservation_found .path17:before {
  content: "\e9a7";
  margin-left: -1.40234375em;
  color: rgb(29, 166, 100);
}
.icon-Seat_No_Reservation_found .path18:before {
  content: "\e9a8";
  margin-left: -1.40234375em;
  color: rgb(0, 0, 0);
}
.icon-Seat_No_Reservation_found .path19:before {
  content: "\e9a9";
  margin-left: -1.40234375em;
  color: rgb(255, 255, 255);
}
.icon-Seat_No_Reservation_found .path20:before {
  content: "\e9aa";
  margin-left: -1.40234375em;
  color: rgb(189, 189, 189);
}
.icon-Seat_No_Reservation_found .path21:before {
  content: "\e9ab";
  margin-left: -1.40234375em;
  color: rgb(224, 224, 224);
}
.icon-Seat_No_Reservation_found .path22:before {
  content: "\e9ac";
  margin-left: -1.40234375em;
  color: rgb(224, 224, 224);
}
.icon-Seat_No_Reservation_found .path23:before {
  content: "\e9ad";
  margin-left: -1.40234375em;
  color: rgb(29, 166, 100);
}
.icon-Seat_No_Reservation_found .path24:before {
  content: "\e9ae";
  margin-left: -1.40234375em;
  color: rgb(224, 224, 224);
}
.icon-Seat_No_Reservation_found .path25:before {
  content: "\e9af";
  margin-left: -1.40234375em;
  color: rgb(29, 166, 100);
}
.icon-Settings:before {
  content: "\e9c5";
}
.icon-Shellfish:before {
  content: "\ea00";
  color: #1da664;
}
.icon-Shift-Stats .path1:before {
  content: "\e9e0";
  color: rgb(29, 166, 100);
  opacity: 0.16;
}
.icon-Shift-Stats .path2:before {
  content: "\e9e1";
  margin-left: -1em;
  color: rgb(52, 53, 58);
  opacity: 0.8;
}
.icon-Shift-Stats-2 .path1:before {
  content: "\e9e4";
  color: rgb(249, 206, 35);
  opacity: 0.1;
}
.icon-Shift-Stats-2 .path2:before {
  content: "\e9e5";
  margin-left: -1em;
  color: rgb(92, 93, 97);
}
.icon-Shift-Stats-2 .path3:before {
  content: "\e9e6";
  margin-left: -1em;
  color: rgb(92, 93, 97);
}
.icon-Shift-stats-1 .path1:before {
  content: "\e9e2";
  color: rgb(255, 160, 90);
  opacity: 0.16;
}
.icon-Shift-stats-1 .path2:before {
  content: "\e9e3";
  margin-left: -1em;
  color: rgb(92, 93, 97);
}
.icon-Shop_nocart .path1:before {
  content: "\e9d0";
  color: rgb(197, 141, 11);
  opacity: 0.1;
}
.icon-Shop_nocart .path2:before {
  content: "\e9d1";
  margin-left: -1.6533203125em;
  color: rgb(197, 141, 11);
  opacity: 0.1;
}
.icon-Shop_nocart .path3:before {
  content: "\e9d2";
  margin-left: -1.6533203125em;
  color: rgb(197, 141, 11);
  opacity: 0.1;
}
.icon-Shop_nocart .path4:before {
  content: "\e9d3";
  margin-left: -1.6533203125em;
  color: rgb(197, 141, 11);
  opacity: 0.1;
}
.icon-Shop_nocart .path5:before {
  content: "\e9d4";
  margin-left: -1.6533203125em;
  color: rgb(197, 141, 11);
  opacity: 0.1;
}
.icon-Shop_nocart .path6:before {
  content: "\e9d5";
  margin-left: -1.6533203125em;
  color: rgb(197, 141, 11);
}
.icon-Shop_nocart .path7:before {
  content: "\e9d6";
  margin-left: -1.6533203125em;
  color: rgb(197, 141, 11);
}
.icon-Shop_nocart .path8:before {
  content: "\e9d7";
  margin-left: -1.6533203125em;
  color: rgb(197, 141, 11);
}
.icon-Shop_nocart .path9:before {
  content: "\e9d8";
  margin-left: -1.6533203125em;
  color: rgb(197, 141, 11);
}
.icon-Shop_nocart .path10:before {
  content: "\e9d9";
  margin-left: -1.6533203125em;
  color: rgb(197, 141, 11);
}
.icon-Shop_nocart .path11:before {
  content: "\e9da";
  margin-left: -1.6533203125em;
  color: rgb(197, 141, 11);
}
.icon-Shrink .path1:before {
  content: "\e9bf";
  color: rgb(29, 166, 100);
}
.icon-Shrink .path2:before {
  content: "\e9c0";
  margin-left: -1em;
  color: none;
}
.icon-Shrink .path3:before {
  content: "\e9c1";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Sort-Down:before {
  content: "\e960";
  color: #acaeb0;
}
.icon-Sort-Up:before {
  content: "\e961";
  color: #acaeb0;
}
.icon-Success:before {
  content: "\e930";
}
.icon-Switch-Property:before {
  content: "\e9fc";
  color: #fff;
}
.icon-TableServer:before {
  content: "\e9bb";
  color: #95989a;
}
.icon-Tables:before {
  content: "\e937";
  color: #fff;
}
.icon-Union-17:before {
  content: "\e950";
  color: #fff;
}
.icon-Union-18:before {
  content: "\e951";
}
.icon-Union-20:before {
  content: "\e928";
}
.icon-Unkown-Card .path1:before {
  content: "\ea6d";
  color: rgb(129, 136, 146);
}
.icon-Unkown-Card .path2:before {
  content: "\ea6e";
  margin-left: -1.49609375em;
  color: rgb(116, 109, 109);
}
.icon-Up-Arrow:before {
  content: "\e934";
}
.icon-VIP:before {
  content: "\e9e7";
  color: #a8a8a8;
}
.icon-Vegetarian:before {
  content: "\e96e";
  color: #fff;
}
.icon-Vip:before {
  content: "\ea01";
  color: #1da664;
}
.icon-Voice-message:before {
  content: "\e938";
  color: #666;
}
.icon-accounts:before {
  content: "\ecae";
}
.icon-active-user:before {
  content: "\ea3d";
}
.icon-add:before {
  content: "\ed8c";
}
.icon-add1:before {
  content: "\e957";
}
.icon-add-contact:before {
  content: "\e935";
}
.icon-add-without-commission:before {
  content: "\edb2";
}
.icon-add-without-commission1:before {
  content: "\ee6d";
}
.icon-add11:before {
  content: "\eb2b";
}
.icon-add2:before {
  content: "\eb4b";
  color: #2665c0;
}
.icon-add3:before {
  content: "\e936";
}
.icon-adddeposit:before {
  content: "\e939";
}
.icon-adddeposit1:before {
  content: "\e94b";
}
.icon-addons:before {
  content: "\e93a";
}
.icon-advance-search:before {
  content: "\eaa9";
}
.icon-advanced-search:before {
  content: "\eaaa";
}
.icon-alert:before {
  content: "\e9b0";
}
.icon-appointment-calender:before {
  content: "\e9c6";
}
.icon-arrow-pointing-to-right:before {
  content: "\ed8b";
}
.icon-audio-video:before {
  content: "\ecb6";
}
.icon-audit:before {
  content: "\e901";
}
.icon-availability:before {
  content: "\ecaf";
}
.icon-avatar .path1:before {
  content: "\e93b";
  color: rgb(126, 126, 126);
}
.icon-avatar .path2:before {
  content: "\eda4";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-bag-number:before {
  content: "\eb4a";
}
.icon-bell:before {
  content: "\eb05";
}
.icon-bevarage:before {
  content: "\ecb8";
}
.icon-block-user:before {
  content: "\ea3e";
}
.icon-body:before {
  content: "\e953";
}
.icon-bookings:before {
  content: "\ecb1";
}
.icon-bookmark:before {
  content: "\ecad";
  color: #2665c0;
}
.icon-brokencloud .path1:before {
  content: "\ec65";
  color: rgb(159, 222, 247);
}
.icon-brokencloud .path2:before {
  content: "\ec66";
  margin-left: -1em;
  color: rgb(190, 234, 252);
}
.icon-brokencloud .path3:before {
  content: "\ec67";
  margin-left: -1em;
  color: rgb(174, 228, 255);
}
.icon-brokencloud .path4:before {
  content: "\ec68";
  margin-left: -1em;
  color: rgb(131, 212, 237);
}
.icon-brokencloud .path5:before {
  content: "\ec69";
  margin-left: -1em;
  color: rgb(228, 242, 249);
}
.icon-brokencloud .path6:before {
  content: "\ec6a";
  margin-left: -1em;
  color: rgb(215, 237, 249);
}
.icon-business-cards-database:before {
  content: "\e952";
  color: #fff;
}
.icon-calendar:before {
  content: "\eb42";
}
.icon-camera .path1:before {
  content: "\e93d";
  color: rgb(252, 253, 255);
}
.icon-camera .path2:before {
  content: "\e940";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.45;
}
.icon-camera .path3:before {
  content: "\e947";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.45;
}
.icon-cancel:before {
  content: "\e94e";
}
.icon-cancel-players:before {
  content: "\ed8a";
}
.icon-cancel1:before {
  content: "\eaf2";
}
.icon-card:before {
  content: "\eb11";
}
.icon-card-type:before {
  content: "\eb30";
}
.icon-cash:before {
  content: "\eb13";
}
.icon-cash1:before {
  content: "\eb14";
}
.icon-changeservice:before {
  content: "\e94f";
}
.icon-charts:before {
  content: "\ebd4";
  color: #f25b5b;
}
.icon-checkedin:before {
  content: "\e9b1";
}
.icon-checkedout:before {
  content: "\e9b2";
}
.icon-cheque:before {
  content: "\eb0b";
}
.icon-cheque1:before {
  content: "\eb0c";
}
.icon-clear-players:before {
  content: "\eb4f";
}
.icon-clearsky .path1:before {
  content: "\ec6b";
  color: rgb(255, 193, 7);
}
.icon-clearsky .path2:before {
  content: "\ec6c";
  margin-left: -1em;
  color: rgb(255, 213, 79);
}
.icon-clearsky .path3:before {
  content: "\ec6d";
  margin-left: -1em;
  color: rgb(255, 213, 79);
}
.icon-clearsky .path4:before {
  content: "\ec6e";
  margin-left: -1em;
  color: rgb(255, 213, 79);
}
.icon-clearsky .path5:before {
  content: "\ec6f";
  margin-left: -1em;
  color: rgb(255, 213, 79);
}
.icon-clearsky .path6:before {
  content: "\ec70";
  margin-left: -1em;
  color: rgb(255, 213, 79);
}
.icon-clearsky .path7:before {
  content: "\ec71";
  margin-left: -1em;
  color: rgb(255, 213, 79);
}
.icon-clearsky .path8:before {
  content: "\ec72";
  margin-left: -1em;
  color: rgb(255, 213, 79);
}
.icon-clearsky .path9:before {
  content: "\ec73";
  margin-left: -1em;
  color: rgb(255, 213, 79);
}
.icon-clearsky .path10:before {
  content: "\ec74";
  margin-left: -1em;
  color: rgb(255, 213, 79);
}
.icon-clearsky .path11:before {
  content: "\ec75";
  margin-left: -1em;
  color: rgb(255, 213, 79);
}
.icon-clearsky .path12:before {
  content: "\ec76";
  margin-left: -1em;
  color: rgb(255, 213, 79);
}
.icon-clearsky .path13:before {
  content: "\ec77";
  margin-left: -1em;
  color: rgb(255, 213, 79);
}
.icon-client:before {
  content: "\e9b3";
}
.icon-clock:before {
  content: "\e956";
}
.icon-close:before {
  content: "\e95a";
}
.icon-close1:before {
  content: "\e9f0";
  color: #666;
}
.icon-cloudy .path1:before {
  content: "\ea42";
  color: rgb(255, 216, 0);
}
.icon-cloudy .path2:before {
  content: "\ea43";
  margin-left: -1.4111328125em;
  color: rgb(255, 255, 255);
}
.icon-coins:before {
  content: "\eb03";
}
.icon-comments:before {
  content: "\e954";
}
.icon-commission:before {
  content: "\e9b4";
}
.icon-communal:before {
  content: "\e9b7";
  color: #6a6a6a;
}
.icon-comp-slip-redemption:before {
  content: "\edb0";
}
.icon-comp-slip-redemption1:before {
  content: "\edb1";
}
.icon-confirmed:before {
  content: "\e95d";
}
.icon-contacts:before {
  content: "\ecb4";
}
.icon-copy:before {
  content: "\e95f";
}
.icon-copy-to-many:before {
  content: "\eb50";
}
.icon-copy1:before {
  content: "\eaf7";
}
.icon-copy11:before {
  content: "\eb51";
}
.icon-copy12:before {
  content: "\ef5b";
}
.icon-copy2:before {
  content: "\efdc";
}
.icon-course-flag:before {
  content: "\eb52";
}
.icon-current-booking:before {
  content: "\ed12";
  color: #818892;
}
.icon-dashboard-arrow-left:before {
  content: "\ed89";
}
.icon-dashboard-calendar:before {
  content: "\ebd3";
  color: #434a54;
}
.icon-dashboard-clock:before {
  content: "\ebd5";
  color: #434a54;
}
.icon-dashboard-course:before {
  content: "\ebd6";
  color: #e84949;
}
.icon-dashboard-cross:before {
  content: "\ebd7";
}
.icon-dashboard-down:before {
  content: "\ed88";
  color: #abb7b7;
}
.icon-dashboard-flag:before {
  content: "\ed87";
  color: #2fb2f7;
}
.icon-dashboard-left-arrow:before {
  content: "\eaab";
}
.icon-dashboard-lessons:before {
  content: "\ebdb";
  color: #1fb29c;
}
.icon-dashboard-right-arrow:before {
  content: "\eaac";
}
.icon-dashboard-tick:before {
  content: "\ebe1";
}
.icon-dashboard-waitlist:before {
  content: "\ebe2";
  color: #ffa900;
}
.icon-delay:before {
  content: "\eb53";
}
.icon-delete:before {
  content: "\eb19";
}
.icon-deposit:before {
  content: "\e962";
}
.icon-deposit1:before {
  content: "\ed08";
}
.icon-device:before {
  content: "\eb2f";
}
.icon-dial-pad:before {
  content: "\e9cd";
}
.icon-disabled-user:before {
  content: "\e963";
  color: #a73535;
}
.icon-done:before {
  content: "\e964";
}
.icon-donot-move:before {
  content: "\eb16";
}
.icon-down-arrow:before {
  content: "\eb20";
}
.icon-drag-drop:before {
  content: "\eb26";
}
.icon-dropdown .path1:before {
  content: "\ecd9";
  color: rgb(238, 238, 238);
}
.icon-dropdown .path2:before {
  content: "\ecda";
  margin-left: -1em;
  color: rgb(129, 136, 146);
}
.icon-edit:before {
  content: "\eb2a";
}
.icon-edit1:before {
  content: "\e9b5";
  color: #666;
}
.icon-edit-account:before {
  content: "\ed0d";
  color: #818892;
}
.icon-edit-booking:before {
  content: "\ed0e";
  color: #818892;
}
.icon-edit-function:before {
  content: "\ed0f";
  color: #818892;
}
.icon-edit-new:before {
  content: "\eb40";
}
.icon-edit11:before {
  content: "\edb4";
}
.icon-emptyshop .path1:before {
  content: "\e9b6";
  color: rgb(197, 141, 11);
  opacity: 0.1;
}
.icon-emptyshop .path2:before {
  content: "\e9b8";
  margin-left: -1.494140625em;
  color: rgb(197, 141, 11);
  opacity: 0.1;
}
.icon-emptyshop .path3:before {
  content: "\e9b9";
  margin-left: -1.494140625em;
  color: rgb(197, 141, 11);
  opacity: 0.1;
}
.icon-emptyshop .path4:before {
  content: "\e9ba";
  margin-left: -1.494140625em;
  color: rgb(197, 141, 11);
  opacity: 0.1;
}
.icon-emptyshop .path5:before {
  content: "\e9c7";
  margin-left: -1.494140625em;
  color: rgb(197, 141, 11);
  opacity: 0.1;
}
.icon-emptyshop .path6:before {
  content: "\e9c8";
  margin-left: -1.494140625em;
  color: rgb(197, 141, 11);
}
.icon-emptyshop .path7:before {
  content: "\e9cb";
  margin-left: -1.494140625em;
  color: rgb(197, 141, 11);
}
.icon-emptyshop .path8:before {
  content: "\e9cc";
  margin-left: -1.494140625em;
  color: rgb(197, 141, 11);
}
.icon-emptyshop .path9:before {
  content: "\e9cf";
  margin-left: -1.494140625em;
  color: rgb(197, 141, 11);
}
.icon-emptyshop .path10:before {
  content: "\e9db";
  margin-left: -1.494140625em;
  color: rgb(197, 141, 11);
}
.icon-emptyshop .path11:before {
  content: "\e9e9";
  margin-left: -1.494140625em;
  color: rgb(255, 255, 255);
}
.icon-emptyshop .path12:before {
  content: "\e9ea";
  margin-left: -1.494140625em;
  color: rgb(197, 141, 11);
}
.icon-emptyshop .path13:before {
  content: "\e9eb";
  margin-left: -1.494140625em;
  color: rgb(197, 141, 11);
}
.icon-emptyshop .path14:before {
  content: "\e9ec";
  margin-left: -1.494140625em;
  color: rgb(197, 141, 11);
}
.icon-enabled-user:before {
  content: "\e9ed";
  color: #56a948;
}
.icon-enforce-gender:before {
  content: "\e9ee";
}
.icon-envelope:before {
  content: "\e9ef";
}
.icon-error-icon:before {
  content: "\e9f1";
}
.icon-event_busy:before {
  content: "\f32f";
}
.icon-exclamation .path1:before {
  content: "\e9f2";
  color: rgb(29, 166, 100);
}
.icon-exclamation .path2:before {
  content: "\e9fd";
  margin-left: -1em;
  color: rgb(236, 239, 241);
}
.icon-exclamation .path3:before {
  content: "\ea02";
  margin-left: -1em;
  color: rgb(250, 250, 250);
}
.icon-expand:before {
  content: "\ea03";
  color: #6a6a6a;
}
.icon-export:before {
  content: "\edbb";
}
.icon-external-gift-card:before {
  content: "\ed8d";
}
.icon-eye:before {
  content: "\ea04";
}
.icon-face:before {
  content: "\ea05";
}
.icon-female-staff:before {
  content: "\ea06";
}
.icon-fewclouds .path1:before {
  content: "\ec78";
  color: rgb(80, 209, 239);
}
.icon-fewclouds .path2:before {
  content: "\ec79";
  margin-left: -1em;
  color: rgb(255, 237, 0);
}
.icon-file_upload:before {
  content: "\ecdc";
  color: #2665c0;
}
.icon-filled-down-arrow:before {
  content: "\ebd8";
}
.icon-filled-more:before {
  content: "\eb3a";
}
.icon-filled-notes .path1:before {
  content: "\ea07";
  color: rgb(255, 255, 255);
}
.icon-filled-notes .path2:before {
  content: "\ea08";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-filled-notes .path3:before {
  content: "\ea09";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-filled-notes .path4:before {
  content: "\ea0a";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-filled-notes .path5:before {
  content: "\ea0b";
  margin-left: -1em;
  color: rgba(255, 255, 255, 0.3);
}
.icon-filled-notes .path6:before {
  content: "\ea0c";
  margin-left: -1em;
  color: none;
}
.icon-filled-up-arrow:before {
  content: "\ea0d";
  color: #6b6b6b;
}
.icon-filled-up-arrow1:before {
  content: "\ea0e";
}
.icon-filled-up-arrow2:before {
  content: "\ef80";
  color: #6b6b6b;
}
.icon-filter:before {
  content: "\ea0f";
}
.icon-foggy .path1:before {
  content: "\ec7a";
  color: rgb(110, 154, 224);
}
.icon-foggy .path2:before {
  content: "\ec7b";
  margin-left: -1em;
  color: rgb(110, 154, 224);
}
.icon-foggy .path3:before {
  content: "\ec7c";
  margin-left: -1em;
  color: rgb(110, 154, 224);
}
.icon-foggy .path4:before {
  content: "\ec7d";
  margin-left: -1em;
  color: rgb(220, 220, 218);
}
.icon-foggy .path5:before {
  content: "\ec7e";
  margin-left: -1em;
  color: rgb(171, 174, 167);
}
.icon-frost-delay:before {
  content: "\eb54";
}
.icon-function-delete:before {
  content: "\ed0c";
  color: #818892;
}
.icon-function-menu:before {
  content: "\ed0b";
}
.icon-function-summary:before {
  content: "\ed10";
  color: #818892;
}
.icon-garbage:before {
  content: "\ea10";
}
.icon-giftcard:before {
  content: "\eb0a";
}
.icon-gratuity:before {
  content: "\ea11";
}
.icon-gratuity1:before {
  content: "\edc8";
}
.icon-group-add .path1:before {
  content: "\ecb3";
  color: rgb(255, 255, 255);
}
.icon-group-add .path2:before {
  content: "\ecb5";
  margin-left: -1em;
  color: rgb(37, 98, 187);
}
.icon-group-add .path3:before {
  content: "\ecb9";
  margin-left: -1em;
  color: rgb(37, 98, 187);
}
.icon-guest:before {
  content: "\eb35";
}
.icon-guest-no-data-found .path1:before {
  content: "\ec3a";
  color: rgb(235, 244, 235);
}
.icon-guest-no-data-found .path2:before {
  content: "\ec3b";
  margin-left: -1.37890625em;
  color: rgb(71, 230, 177);
}
.icon-guest-no-data-found .path3:before {
  content: "\ec3c";
  margin-left: -1.37890625em;
  color: rgb(71, 230, 177);
}
.icon-guest-no-data-found .path4:before {
  content: "\ec3d";
  margin-left: -1.37890625em;
  color: rgb(71, 230, 177);
}
.icon-guest-no-data-found .path5:before {
  content: "\ec3e";
  margin-left: -1.37890625em;
  color: rgb(71, 230, 177);
}
.icon-guest-no-data-found .path6:before {
  content: "\ec3f";
  margin-left: -1.37890625em;
  color: rgb(166, 196, 247);
}
.icon-guest-no-data-found .path7:before {
  content: "\ec40";
  margin-left: -1.37890625em;
  color: rgb(244, 230, 144);
}
.icon-guest-no-data-found .path8:before {
  content: "\ec41";
  margin-left: -1.37890625em;
  color: rgb(254, 235, 154);
}
.icon-guest-no-data-found .path9:before {
  content: "\ec42";
  margin-left: -1.37890625em;
  color: rgb(241, 170, 152);
}
.icon-guest-no-data-found .path10:before {
  content: "\ec43";
  margin-left: -1.37890625em;
  color: rgb(244, 170, 157);
}
.icon-guest-no-data-found .path11:before {
  content: "\ec44";
  margin-left: -1.37890625em;
  color: rgb(241, 170, 152);
}
.icon-guest-no-data-found .path12:before {
  content: "\ec45";
  margin-left: -1.37890625em;
  color: rgb(156, 191, 237);
}
.icon-guest-no-data-found .path13:before {
  content: "\ec46";
  margin-left: -1.37890625em;
  color: rgb(153, 237, 206);
}
.icon-guest-no-data-found .path14:before {
  content: "\ec47";
  margin-left: -1.37890625em;
  color: rgb(228, 228, 228);
}
.icon-guest-no-data-found .path15:before {
  content: "\ec48";
  margin-left: -1.37890625em;
  color: rgb(255, 255, 255);
}
.icon-guest-no-data-found .path16:before {
  content: "\ec49";
  margin-left: -1.37890625em;
  color: rgb(67, 160, 71);
}
.icon-guest-no-data-found .path17:before {
  content: "\ec4a";
  margin-left: -1.37890625em;
  color: rgb(67, 160, 71);
}
.icon-guest-no-data-found .path18:before {
  content: "\ec4b";
  margin-left: -1.37890625em;
  color: rgb(67, 160, 71);
}
.icon-guest-no-data-found .path19:before {
  content: "\ec4c";
  margin-left: -1.37890625em;
  color: rgb(189, 222, 190);
}
.icon-guest-no-data-found .path20:before {
  content: "\ec4d";
  margin-left: -1.37890625em;
  color: rgb(189, 222, 190);
}
.icon-guest-no-data-found .path21:before {
  content: "\ec4e";
  margin-left: -1.37890625em;
  color: rgb(189, 222, 190);
}
.icon-guest-no-data-found .path22:before {
  content: "\ec4f";
  margin-left: -1.37890625em;
  color: rgb(189, 222, 190);
}
.icon-guest-no-data-found .path23:before {
  content: "\ec50";
  margin-left: -1.37890625em;
  color: rgb(189, 222, 190);
}
.icon-guest-no-data-found .path24:before {
  content: "\ec51";
  margin-left: -1.37890625em;
  color: rgb(189, 222, 190);
}
.icon-guest-no-data-found .path25:before {
  content: "\ec52";
  margin-left: -1.37890625em;
  color: rgb(67, 160, 71);
}
.icon-guest1:before {
  content: "\ed16";
}
.icon-hair:before {
  content: "\ea12";
}
.icon-hamburger:before {
  content: "\ebda";
  color: #707070;
}
.icon-hash:before {
  content: "\ea13";
}
.icon-help_question:before {
  content: "\eafa";
  color: #b1b3b5;
}
.icon-hold:before {
  content: "\eb45";
}
.icon-home:before {
  content: "\eb33";
}
.icon-hotel-guest:before {
  content: "\ea14";
}
.icon-hsbc .path1:before {
  content: "\ea65";
  color: rgb(0, 0, 0);
}
.icon-hsbc .path2:before {
  content: "\ea66";
  margin-left: -5.662109375em;
  color: rgb(0, 0, 0);
}
.icon-hsbc .path3:before {
  content: "\ea67";
  margin-left: -5.662109375em;
  color: rgb(0, 0, 0);
}
.icon-hsbc .path4:before {
  content: "\ea68";
  margin-left: -5.662109375em;
  color: rgb(0, 0, 0);
}
.icon-hsbc .path5:before {
  content: "\ea69";
  margin-left: -5.662109375em;
  color: rgb(237, 55, 37);
}
.icon-hsbc .path6:before {
  content: "\ea6a";
  margin-left: -5.662109375em;
  color: rgb(237, 55, 37);
}
.icon-hsbc .path7:before {
  content: "\ea6b";
  margin-left: -5.662109375em;
  color: rgb(237, 55, 37);
}
.icon-hsbc .path8:before {
  content: "\ea6c";
  margin-left: -5.662109375em;
  color: rgb(237, 55, 37);
}
.icon-ic_call_24px:before {
  content: "\ea15";
  color: #fff;
}
.icon-ic_create_24px:before {
  content: "\ea16";
  color: #6a6a6a;
}
.icon-ic_delete_24px:before {
  content: "\ea17";
  color: #6a6a6a;
}
.icon-ic_mail_outline_24px:before {
  content: "\ea18";
  color: #a1a6b4;
}
.icon-ic_stay_current_portrait_24px:before {
  content: "\ea19";
  color: #666;
}
.icon-ic_update_24px:before {
  content: "\ea1a";
  color: #fff;
}
.icon-icon:before {
  content: "\ea1b";
}
.icon-info .path1:before {
  content: "\ebdf";
  color: rgb(89, 89, 89);
}
.icon-info .path2:before {
  content: "\ebe0";
  margin-left: -0.9990234375em;
  color: rgb(255, 255, 255);
}
.icon-information-button:before {
  content: "\ea1c";
  color: #f7af00;
}
.icon-internal:before {
  content: "\ed11";
  color: #818892;
}
.icon-items:before {
  content: "\ea1d";
}
.icon-items1:before {
  content: "\eb5f";
}
.icon-kids-couple:before {
  content: "\ec53";
}
.icon-last-Page:before {
  content: "\ea1e";
}
.icon-left-arrow:before {
  content: "\ea1f";
}
.icon-left-arrow-dashboard:before {
  content: "\ea20";
}
.icon-left-arrow1:before {
  content: "\ea21";
}
.icon-left-arrow2:before {
  content: "\eb0d";
}
.icon-left-dashboard-arrow:before {
  content: "\eb31";
}
.icon-legends:before {
  content: "\ea22";
}
.icon-lessons:before {
  content: "\ea3f";
}
.icon-light .path1:before {
  content: "\ea48";
  color: rgb(255, 216, 59);
}
.icon-light .path2:before {
  content: "\ea49";
  margin-left: -1.0595703125em;
  color: rgb(0, 0, 0);
}
.icon-light .path3:before {
  content: "\ea4a";
  margin-left: -1.0595703125em;
  color: rgb(0, 0, 0);
}
.icon-link:before {
  content: "\ea23";
}
.icon-location:before {
  content: "\ea24";
}
.icon-login-user:before {
  content: "\ea25";
  color: #818892;
}
.icon-lost_found:before {
  content: "\ea26";
}
.icon-loud:before {
  content: "\ecdf";
}
.icon-lunch:before {
  content: "\ea27";
}
.icon-mail:before {
  content: "\ea28";
}
.icon-male-staff:before {
  content: "\ea29";
}
.icon-master-card .path1:before {
  content: "\ea2a";
  color: rgb(252, 177, 49);
}
.icon-master-card .path2:before {
  content: "\ea2b";
  margin-left: -1.6611328125em;
  color: rgb(252, 177, 49);
}
.icon-master-card .path3:before {
  content: "\ea2c";
  margin-left: -1.6611328125em;
  color: rgb(237, 27, 46);
}
.icon-master-card .path4:before {
  content: "\ea2d";
  margin-left: -1.6611328125em;
  color: rgb(255, 255, 255);
}
.icon-master-card .path5:before {
  content: "\ea2e";
  margin-left: -1.6611328125em;
  color: rgb(0, 52, 115);
}
.icon-master-card .path6:before {
  content: "\ea2f";
  margin-left: -1.6611328125em;
  color: rgb(0, 52, 115);
}
.icon-master-card .path7:before {
  content: "\ea30";
  margin-left: -1.6611328125em;
  color: rgb(0, 52, 115);
}
.icon-master-card .path8:before {
  content: "\ea31";
  margin-left: -1.6611328125em;
  color: rgb(0, 52, 115);
}
.icon-master-card .path9:before {
  content: "\ea32";
  margin-left: -1.6611328125em;
  color: rgb(0, 52, 115);
}
.icon-master-card .path10:before {
  content: "\ea33";
  margin-left: -1.6611328125em;
  color: rgb(0, 52, 115);
}
.icon-master-card .path11:before {
  content: "\ea34";
  margin-left: -1.6611328125em;
  color: rgb(0, 52, 115);
}
.icon-master-card .path12:before {
  content: "\ea35";
  margin-left: -1.6611328125em;
  color: rgb(0, 52, 115);
}
.icon-master-card .path13:before {
  content: "\ea36";
  margin-left: -1.6611328125em;
  color: rgb(255, 255, 255);
}
.icon-master-card .path14:before {
  content: "\ea37";
  margin-left: -1.6611328125em;
  color: rgb(255, 255, 255);
}
.icon-master-card .path15:before {
  content: "\ea38";
  margin-left: -1.6611328125em;
  color: rgb(255, 255, 255);
}
.icon-master-card .path16:before {
  content: "\ea39";
  margin-left: -1.6611328125em;
  color: rgb(255, 255, 255);
}
.icon-master-card .path17:before {
  content: "\ea3a";
  margin-left: -1.6611328125em;
  color: rgb(255, 255, 255);
}
.icon-master-card .path18:before {
  content: "\ea3b";
  margin-left: -1.6611328125em;
  color: rgb(255, 255, 255);
}
.icon-master-card .path19:before {
  content: "\ea3c";
  margin-left: -1.6611328125em;
  color: rgb(255, 255, 255);
}
.icon-master-card .path20:before {
  content: "\ea40";
  margin-left: -1.6611328125em;
  color: rgb(255, 255, 255);
}
.icon-master-card .path21:before {
  content: "\ea41";
  margin-left: -1.6611328125em;
  color: rgb(255, 255, 255);
}
.icon-master-card .path22:before {
  content: "\ea44";
  margin-left: -1.6611328125em;
  color: rgb(255, 255, 255);
}
.icon-member:before {
  content: "\ea45";
}
.icon-member11:before {
  content: "\eb55";
}
.icon-member12:before {
  content: "\ef51";
}
.icon-menu:before {
  content: "\ecba";
}
.icon-menu-search:before {
  content: "\edad";
}
.icon-minus:before {
  content: "\eb2e";
}
.icon-misc:before {
  content: "\ecbb";
}
.icon-more:before {
  content: "\eb48";
}
.icon-move:before {
  content: "\ea46";
}
.icon-mug:before {
  content: "\ea47";
}
.icon-multi-appointments:before {
  content: "\ea4b";
}
.icon-multi-paging_lg:before {
  content: "\ea4c";
  color: #666;
}
.icon-my_location_FILL0_wght400_GRAD0_opsz48:before {
  content: "\ea4d";
}
.icon-new-user:before {
  content: "\ebdd";
}
.icon-newclients:before {
  content: "\ea4e";
}
.icon-next:before {
  content: "\ea4f";
}
.icon-no-appointment-booked .path1:before {
  content: "\ea50";
  color: rgb(67, 160, 71);
  opacity: 0.1;
}
.icon-no-appointment-booked .path2:before {
  content: "\ea51";
  margin-left: -1.494140625em;
  color: rgb(108, 99, 255);
}
.icon-no-appointment-booked .path3:before {
  content: "\ea52";
  margin-left: -1.494140625em;
  color: rgb(197, 141, 11);
  opacity: 0.354;
}
.icon-no-appointment-booked .path4:before {
  content: "\ea53";
  margin-left: -1.494140625em;
  color: rgb(197, 141, 11);
  opacity: 0.354;
}
.icon-no-appointment-booked .path5:before {
  content: "\ea54";
  margin-left: -1.494140625em;
  color: rgb(197, 141, 11);
  opacity: 0.354;
}
.icon-no-appointment-booked .path6:before {
  content: "\ea55";
  margin-left: -1.494140625em;
  color: rgb(197, 141, 11);
  opacity: 0.354;
}
.icon-no-appointment-booked .path7:before {
  content: "\ea56";
  margin-left: -1.494140625em;
  color: rgb(197, 141, 11);
  opacity: 0.354;
}
.icon-no-appointment-booked .path8:before {
  content: "\ea57";
  margin-left: -1.494140625em;
  color: rgb(197, 141, 11);
  opacity: 0.354;
}
.icon-no-appointment-booked .path9:before {
  content: "\ea58";
  margin-left: -1.494140625em;
  color: rgb(197, 141, 11);
  opacity: 0.354;
}
.icon-no-appointment-booked .path10:before {
  content: "\ea59";
  margin-left: -1.494140625em;
  color: rgb(197, 141, 11);
  opacity: 0.354;
}
.icon-no-appointment-booked .path11:before {
  content: "\ea5a";
  margin-left: -1.494140625em;
  color: rgb(197, 141, 11);
  opacity: 0.354;
}
.icon-no-appointment-booked .path12:before {
  content: "\ea5b";
  margin-left: -1.494140625em;
  color: rgb(197, 141, 11);
  opacity: 0.354;
}
.icon-no-appointment-booked .path13:before {
  content: "\ea5c";
  margin-left: -1.494140625em;
  color: rgb(197, 141, 11);
  opacity: 0.354;
}
.icon-no-appointment-booked .path14:before {
  content: "\ea5d";
  margin-left: -1.494140625em;
  color: rgb(197, 141, 11);
  opacity: 0.354;
}
.icon-no-appointment-booked .path15:before {
  content: "\ea5e";
  margin-left: -1.494140625em;
  color: rgb(197, 141, 11);
  opacity: 0.354;
}
.icon-no-appointment-booked .path16:before {
  content: "\ea5f";
  margin-left: -1.494140625em;
  color: rgb(197, 141, 11);
  opacity: 0.354;
}
.icon-no-appointment-booked .path17:before {
  content: "\ea60";
  margin-left: -1.494140625em;
  color: rgb(197, 141, 11);
  opacity: 0.354;
}
.icon-no-appointment-booked .path18:before {
  content: "\ea61";
  margin-left: -1.494140625em;
  color: rgb(197, 141, 11);
  opacity: 0.354;
}
.icon-no-appointment-booked .path19:before {
  content: "\ea62";
  margin-left: -1.494140625em;
  color: rgb(197, 141, 11);
  opacity: 0.354;
}
.icon-no-appointment-booked .path20:before {
  content: "\ea63";
  margin-left: -1.494140625em;
  color: rgb(197, 141, 11);
  opacity: 0.354;
}
.icon-no-appointment-booked .path21:before {
  content: "\ea64";
  margin-left: -1.494140625em;
  color: rgb(197, 141, 11);
  opacity: 0.354;
}
.icon-no-appointment-booked .path22:before {
  content: "\ea79";
  margin-left: -1.494140625em;
  color: rgb(197, 141, 11);
  opacity: 0.354;
}
.icon-no-appointment-booked .path23:before {
  content: "\ea7a";
  margin-left: -1.494140625em;
  color: rgb(197, 141, 11);
  opacity: 0.354;
}
.icon-no-appointment-booked .path24:before {
  content: "\ea7b";
  margin-left: -1.494140625em;
  color: rgb(197, 141, 11);
  opacity: 0.354;
}
.icon-no-appointment-booked .path25:before {
  content: "\eae7";
  margin-left: -1.494140625em;
  color: rgb(197, 141, 11);
  opacity: 0.354;
}
.icon-no-appointment-booked .path26:before {
  content: "\eae8";
  margin-left: -1.494140625em;
  color: rgb(197, 141, 11);
  opacity: 0.354;
}
.icon-no-appointment-booked .path27:before {
  content: "\eae9";
  margin-left: -1.494140625em;
  color: rgb(197, 141, 11);
  opacity: 0.354;
}
.icon-no-appointment-booked .path28:before {
  content: "\eaea";
  margin-left: -1.494140625em;
  color: rgb(197, 141, 11);
  opacity: 0.354;
}
.icon-no-appointment-booked .path29:before {
  content: "\eaeb";
  margin-left: -1.494140625em;
  color: rgb(197, 141, 11);
  opacity: 0.354;
}
.icon-no-appointment-booked .path30:before {
  content: "\eaec";
  margin-left: -1.494140625em;
  color: rgb(197, 141, 11);
  opacity: 0.354;
}
.icon-no-appointment-booked .path31:before {
  content: "\eaed";
  margin-left: -1.494140625em;
  color: rgb(197, 141, 11);
  opacity: 0.354;
}
.icon-no-appointment-booked .path32:before {
  content: "\eaee";
  margin-left: -1.494140625em;
  color: rgb(197, 141, 11);
  opacity: 0.354;
}
.icon-no-appointment-booked .path33:before {
  content: "\eaef";
  margin-left: -1.494140625em;
  color: rgb(197, 141, 11);
  opacity: 0.354;
}
.icon-no-appointment-booked .path34:before {
  content: "\eaf0";
  margin-left: -1.494140625em;
  color: rgb(197, 141, 11);
  opacity: 0.354;
}
.icon-no-appointment-booked .path35:before {
  content: "\eaf1";
  margin-left: -1.494140625em;
  color: rgb(68, 64, 83);
}
.icon-no-appointment-booked .path36:before {
  content: "\eaf3";
  margin-left: -1.494140625em;
  color: rgb(68, 64, 83);
}
.icon-no-appointment-booked .path37:before {
  content: "\eaf4";
  margin-left: -1.494140625em;
  color: rgb(68, 64, 83);
}
.icon-no-appointment-booked .path38:before {
  content: "\eaf5";
  margin-left: -1.494140625em;
  color: rgb(67, 160, 71);
}
.icon-no-appointment-booked .path39:before {
  content: "\eaf6";
  margin-left: -1.494140625em;
  color: rgb(63, 61, 86);
}
.icon-no-appointment-booked .path40:before {
  content: "\eaf8";
  margin-left: -1.494140625em;
  color: rgb(63, 61, 86);
}
.icon-no-appointment-booked .path41:before {
  content: "\eaf9";
  margin-left: -1.494140625em;
  color: rgb(63, 61, 86);
}
.icon-no-appointment-booked .path42:before {
  content: "\eafb";
  margin-left: -1.494140625em;
  color: rgb(63, 61, 86);
}
.icon-no-appointment-booked .path43:before {
  content: "\eafc";
  margin-left: -1.494140625em;
  color: rgb(63, 61, 86);
}
.icon-no-appointment-booked .path44:before {
  content: "\eafe";
  margin-left: -1.494140625em;
  color: rgb(63, 61, 86);
}
.icon-no-appointment-booked .path45:before {
  content: "\eaff";
  margin-left: -1.494140625em;
  color: rgb(63, 61, 86);
}
.icon-no-appointment-booked .path46:before {
  content: "\eb00";
  margin-left: -1.494140625em;
  color: rgb(67, 160, 71);
}
.icon-no-appointment-booked .path47:before {
  content: "\eb01";
  margin-left: -1.494140625em;
  color: rgb(197, 141, 11);
  opacity: 0.1;
}
.icon-no-appointment-booked .path48:before {
  content: "\eb02";
  margin-left: -1.494140625em;
  color: rgb(255, 255, 255);
}
.icon-no-appointment-booked .path49:before {
  content: "\eb04";
  margin-left: -1.494140625em;
  color: rgb(0, 0, 0);
  opacity: 0.1;
}
.icon-no-appointment-booked .path50:before {
  content: "\eb06";
  margin-left: -1.494140625em;
  color: rgb(0, 0, 0);
  opacity: 0.1;
}
.icon-no-appointment-booked .path51:before {
  content: "\eb07";
  margin-left: -1.494140625em;
  color: rgb(236, 235, 237);
  opacity: 0.343;
}
.icon-no-appointment-booked .path52:before {
  content: "\eb08";
  margin-left: -1.494140625em;
  color: rgb(68, 64, 83);
  opacity: 0.1;
}
.icon-no-appointment-booked .path53:before {
  content: "\eb09";
  margin-left: -1.494140625em;
  color: rgb(68, 64, 83);
  opacity: 0.1;
}
.icon-no-appointment-booked .path54:before {
  content: "\eb0e";
  margin-left: -1.494140625em;
  color: rgb(68, 64, 83);
  opacity: 0.1;
}
.icon-no-appointment-booked .path55:before {
  content: "\eb0f";
  margin-left: -1.494140625em;
  color: rgb(67, 160, 71);
}
.icon-no-appointment-booked .path56:before {
  content: "\eb10";
  margin-left: -1.494140625em;
  color: rgb(68, 64, 83);
  opacity: 0.1;
}
.icon-no-appointment-booked .path57:before {
  content: "\eb12";
  margin-left: -1.494140625em;
  color: rgb(68, 64, 83);
  opacity: 0.1;
}
.icon-no-appointment-booked .path58:before {
  content: "\eb15";
  margin-left: -1.494140625em;
  color: rgb(68, 64, 83);
  opacity: 0.1;
}
.icon-no-appointment-booked .path59:before {
  content: "\eb17";
  margin-left: -1.494140625em;
  color: rgb(67, 160, 71);
}
.icon-no-appointment-booked .path60:before {
  content: "\eb18";
  margin-left: -1.494140625em;
  color: rgb(0, 0, 0);
  opacity: 0.1;
}
.icon-no-appointment-booked .path61:before {
  content: "\eb1a";
  margin-left: -1.494140625em;
  color: rgb(0, 0, 0);
  opacity: 0.1;
}
.icon-no-appointment-booked .path62:before {
  content: "\eb1b";
  margin-left: -1.494140625em;
  color: rgb(67, 160, 71);
  opacity: 0.1;
}
.icon-no-appointment-booked .path63:before {
  content: "\eb1c";
  margin-left: -1.494140625em;
  color: rgb(67, 160, 71);
  opacity: 0.1;
}
.icon-no-appointment-booked .path64:before {
  content: "\eb1d";
  margin-left: -1.494140625em;
  color: rgb(67, 160, 71);
  opacity: 0.1;
}
.icon-no-appointment-booked .path65:before {
  content: "\eb1e";
  margin-left: -1.494140625em;
  color: rgb(67, 160, 71);
  opacity: 0.1;
}
.icon-no-item-default .path1:before {
  content: "\ed33";
  color: rgb(176, 221, 233);
}
.icon-no-item-default .path2:before {
  content: "\ed34";
  margin-left: -1.03125em;
  color: rgb(149, 188, 201);
}
.icon-no-item-default .path3:before {
  content: "\ed35";
  margin-left: -1.03125em;
  color: rgb(220, 160, 80);
}
.icon-no-item-default .path4:before {
  content: "\ed36";
  margin-left: -1.03125em;
  color: rgb(235, 174, 86);
}
.icon-no-item-default .path5:before {
  content: "\ed37";
  margin-left: -1.03125em;
  color: rgb(206, 144, 71);
}
.icon-no-item-default .path6:before {
  content: "\ed38";
  margin-left: -1.03125em;
  color: rgb(255, 242, 180);
}
.icon-no-item-default .path7:before {
  content: "\ed39";
  margin-left: -1.03125em;
  color: rgb(115, 211, 122);
}
.icon-no-item-default .path8:before {
  content: "\ed3a";
  margin-left: -1.03125em;
  color: rgb(73, 203, 92);
}
.icon-no-item-default .path9:before {
  content: "\ed3b";
  margin-left: -1.03125em;
  color: rgb(221, 244, 186);
}
.icon-no-item-default .path10:before {
  content: "\ed3c";
  margin-left: -1.03125em;
  color: rgb(253, 122, 122);
}
.icon-no-item-default .path11:before {
  content: "\ed3d";
  margin-left: -1.03125em;
  color: rgb(253, 87, 87);
}
.icon-no-item-default .path12:before {
  content: "\ed3e";
  margin-left: -1.03125em;
  color: rgb(253, 203, 187);
}
.icon-no-item-default .path13:before {
  content: "\ed3f";
  margin-left: -1.03125em;
  color: rgb(100, 158, 176);
}
.icon-no-item-default .path14:before {
  content: "\ed40";
  margin-left: -1.03125em;
  color: rgb(69, 143, 164);
}
.icon-no-item-default .path15:before {
  content: "\ed41";
  margin-left: -1.03125em;
  color: rgb(176, 221, 233);
}
.icon-no-item-default .path16:before {
  content: "\ed42";
  margin-left: -1.03125em;
  color: rgb(142, 208, 226);
}
.icon-no-item-default .path17:before {
  content: "\ed43";
  margin-left: -1.03125em;
  color: rgb(241, 242, 242);
}
.icon-no-item-default .path18:before {
  content: "\ed44";
  margin-left: -1.03125em;
  color: rgb(95, 219, 252);
}
.icon-no-record .path1:before {
  content: "\ecbc";
  color: rgb(255, 184, 184);
  opacity: 0.6;
}
.icon-no-record .path2:before {
  content: "\ecbd";
  margin-left: -1.0458984375em;
  color: rgb(207, 204, 224);
  opacity: 0.6;
}
.icon-no-record .path3:before {
  content: "\ecbe";
  margin-left: -1.0458984375em;
  color: rgb(255, 184, 184);
  opacity: 0.6;
}
.icon-no-record .path4:before {
  content: "\ecbf";
  margin-left: -1.0458984375em;
  color: rgb(255, 184, 184);
  opacity: 0.6;
}
.icon-no-record .path5:before {
  content: "\ecc4";
  margin-left: -1.0458984375em;
  color: rgb(47, 46, 65);
  opacity: 0.6;
}
.icon-no-record .path6:before {
  content: "\ecc5";
  margin-left: -1.0458984375em;
  color: rgb(47, 46, 65);
  opacity: 0.6;
}
.icon-no-record .path7:before {
  content: "\ecc6";
  margin-left: -1.0458984375em;
  color: rgb(47, 46, 65);
  opacity: 0.6;
}
.icon-no-record .path8:before {
  content: "\ecc7";
  margin-left: -1.0458984375em;
  color: rgb(255, 184, 184);
  opacity: 0.6;
}
.icon-no-record .path9:before {
  content: "\ecc8";
  margin-left: -1.0458984375em;
  color: rgb(255, 184, 184);
  opacity: 0.6;
}
.icon-no-record .path10:before {
  content: "\ecc9";
  margin-left: -1.0458984375em;
  color: rgb(207, 204, 224);
  opacity: 0.6;
}
.icon-no-record .path11:before {
  content: "\ecca";
  margin-left: -1.0458984375em;
  color: rgb(87, 90, 137);
  opacity: 0.6;
}
.icon-no-record .path12:before {
  content: "\eccb";
  margin-left: -1.0458984375em;
  color: rgb(87, 90, 137);
  opacity: 0.6;
}
.icon-no-record .path13:before {
  content: "\eccc";
  margin-left: -1.0458984375em;
  color: rgb(87, 90, 137);
  opacity: 0.6;
}
.icon-no-record .path14:before {
  content: "\eccd";
  margin-left: -1.0458984375em;
  color: rgb(255, 184, 184);
  opacity: 0.6;
}
.icon-no-record .path15:before {
  content: "\ecce";
  margin-left: -1.0458984375em;
  color: rgb(207, 204, 224);
  opacity: 0.6;
}
.icon-no-record .path16:before {
  content: "\eccf";
  margin-left: -1.0458984375em;
  color: rgb(87, 90, 137);
  opacity: 0.6;
}
.icon-no-record .path17:before {
  content: "\ecd0";
  margin-left: -1.0458984375em;
  color: rgb(47, 46, 65);
  opacity: 0.6;
}
.icon-no-record .path18:before {
  content: "\ecd1";
  margin-left: -1.0458984375em;
  color: rgb(255, 184, 184);
  opacity: 0.6;
}
.icon-no-record .path19:before {
  content: "\ecd2";
  margin-left: -1.0458984375em;
  color: rgb(108, 99, 255);
  opacity: 0.6;
}
.icon-no-record .path20:before {
  content: "\ecd3";
  margin-left: -1.0458984375em;
  color: rgb(63, 61, 86);
  opacity: 0.6;
}
.icon-no-record .path21:before {
  content: "\ecd4";
  margin-left: -1.0458984375em;
  color: rgb(108, 99, 255);
  opacity: 0.6;
}
.icon-no-record .path22:before {
  content: "\ecd5";
  margin-left: -1.0458984375em;
  color: rgb(63, 61, 86);
  opacity: 0.6;
}
.icon-no-record .path23:before {
  content: "\ecd6";
  margin-left: -1.0458984375em;
  color: rgb(108, 99, 255);
  opacity: 0.6;
}
.icon-no-record .path24:before {
  content: "\ecd7";
  margin-left: -1.0458984375em;
  color: rgb(63, 61, 86);
  opacity: 0.6;
}
.icon-no-record .path25:before {
  content: "\ecd8";
  margin-left: -1.0458984375em;
  color: rgb(63, 61, 86);
  opacity: 0.6;
}
.icon-no-record .path26:before {
  content: "\ecdd";
  margin-left: -1.0458984375em;
  color: rgb(108, 99, 255);
  opacity: 0.6;
}
.icon-no-record .path27:before {
  content: "\ecde";
  margin-left: -1.0458984375em;
  color: rgb(63, 61, 86);
  opacity: 0.6;
}
.icon-no-record .path28:before {
  content: "\ece0";
  margin-left: -1.0458984375em;
  color: rgb(108, 99, 255);
  opacity: 0.6;
}
.icon-no-record .path29:before {
  content: "\ece1";
  margin-left: -1.0458984375em;
  color: rgb(63, 61, 86);
  opacity: 0.6;
}
.icon-no-record .path30:before {
  content: "\ece2";
  margin-left: -1.0458984375em;
  color: rgb(108, 99, 255);
  opacity: 0.6;
}
.icon-no-schedule-found .path1:before {
  content: "\eb1f";
  color: rgb(217, 217, 217);
}
.icon-no-schedule-found .path2:before {
  content: "\eb21";
  margin-left: -1.267578125em;
  color: rgb(255, 255, 255);
}
.icon-no-schedule-found .path3:before {
  content: "\eb22";
  margin-left: -1.267578125em;
  color: rgb(67, 160, 71);
}
.icon-no-schedule-found .path4:before {
  content: "\eb23";
  margin-left: -1.267578125em;
  color: rgb(242, 242, 242);
}
.icon-no-schedule-found .path5:before {
  content: "\eb24";
  margin-left: -1.267578125em;
  color: rgb(160, 207, 162);
}
.icon-no-schedule-found .path6:before {
  content: "\eb25";
  margin-left: -1.267578125em;
  color: rgb(255, 255, 255);
}
.icon-no-schedule-found .path7:before {
  content: "\eb27";
  margin-left: -1.267578125em;
  color: rgb(217, 236, 218);
}
.icon-no-schedule-found .path8:before {
  content: "\eb28";
  margin-left: -1.267578125em;
  color: rgb(255, 255, 255);
}
.icon-no-schedule-found .path9:before {
  content: "\eb29";
  margin-left: -1.267578125em;
  color: rgb(255, 255, 255);
}
.icon-no-schedule-found .path10:before {
  content: "\eb2c";
  margin-left: -1.267578125em;
  color: rgb(160, 207, 162);
}
.icon-no-schedule-found .path11:before {
  content: "\eb2d";
  margin-left: -1.267578125em;
  color: rgb(255, 255, 255);
}
.icon-no-schedule-found .path12:before {
  content: "\eb32";
  margin-left: -1.267578125em;
  color: rgb(217, 236, 218);
}
.icon-no-schedule-found .path13:before {
  content: "\eb34";
  margin-left: -1.267578125em;
  color: rgb(217, 236, 218);
}
.icon-no-schedule-found .path14:before {
  content: "\eb36";
  margin-left: -1.267578125em;
  color: rgb(217, 236, 218);
}
.icon-no-schedule-found .path15:before {
  content: "\eb37";
  margin-left: -1.267578125em;
  color: rgb(255, 255, 255);
}
.icon-no-schedule-found .path16:before {
  content: "\eb38";
  margin-left: -1.267578125em;
  color: rgb(160, 207, 162);
}
.icon-no-schedule-found .path17:before {
  content: "\eb3b";
  margin-left: -1.267578125em;
  color: rgb(255, 255, 255);
}
.icon-no-schedule-found .path18:before {
  content: "\eb3d";
  margin-left: -1.267578125em;
  color: rgb(217, 236, 218);
}
.icon-no-schedule-found .path19:before {
  content: "\eb3f";
  margin-left: -1.267578125em;
  color: rgb(160, 207, 162);
}
.icon-no-schedule-found .path20:before {
  content: "\eb41";
  margin-left: -1.267578125em;
  color: rgb(255, 255, 255);
}
.icon-no-schedule-found .path21:before {
  content: "\eb43";
  margin-left: -1.267578125em;
  color: rgb(255, 255, 255);
}
.icon-no-schedule-found .path22:before {
  content: "\eb44";
  margin-left: -1.267578125em;
  color: rgb(255, 255, 255);
}
.icon-no-schedule-found .path23:before {
  content: "\eb46";
  margin-left: -1.267578125em;
  color: rgb(217, 236, 218);
}
.icon-no-schedule-found .path24:before {
  content: "\eb47";
  margin-left: -1.267578125em;
  color: rgb(255, 255, 255);
}
.icon-no-schedule-found .path25:before {
  content: "\eb49";
  margin-left: -1.267578125em;
  color: rgb(217, 236, 218);
}
.icon-no-schedule-found .path26:before {
  content: "\eb4c";
  margin-left: -1.267578125em;
  color: rgb(255, 255, 255);
}
.icon-no-schedule-found .path27:before {
  content: "\eb4d";
  margin-left: -1.267578125em;
  color: rgb(255, 255, 255);
}
.icon-no-schedule-found .path28:before {
  content: "\eb4e";
  margin-left: -1.267578125em;
  color: rgb(217, 236, 218);
}
.icon-no-schedule-found .path29:before {
  content: "\eb56";
  margin-left: -1.267578125em;
  color: rgb(255, 255, 255);
}
.icon-no-schedule-found .path30:before {
  content: "\eb57";
  margin-left: -1.267578125em;
  color: rgb(160, 207, 162);
}
.icon-no-schedule-found .path31:before {
  content: "\eb58";
  margin-left: -1.267578125em;
  color: rgb(160, 207, 162);
}
.icon-no-schedule-found .path32:before {
  content: "\eb59";
  margin-left: -1.267578125em;
  color: rgb(255, 255, 255);
}
.icon-no-schedule-found .path33:before {
  content: "\eb5a";
  margin-left: -1.267578125em;
  color: rgb(255, 255, 255);
}
.icon-no-schedule-found .path34:before {
  content: "\eb5b";
  margin-left: -1.267578125em;
  color: rgb(160, 207, 162);
}
.icon-no-schedule-found .path35:before {
  content: "\eb5c";
  margin-left: -1.267578125em;
  color: rgb(255, 255, 255);
}
.icon-no-schedule-found .path36:before {
  content: "\eb5d";
  margin-left: -1.267578125em;
  color: rgb(160, 207, 162);
}
.icon-no-schedule-found .path37:before {
  content: "\eb5e";
  margin-left: -1.267578125em;
  color: rgb(160, 207, 162);
}
.icon-no-schedule-found .path38:before {
  content: "\eb60";
  margin-left: -1.267578125em;
  color: rgb(217, 236, 218);
}
.icon-no-schedule-found .path39:before {
  content: "\eb61";
  margin-left: -1.267578125em;
  color: rgb(255, 255, 255);
}
.icon-no-schedule-found .path40:before {
  content: "\eb62";
  margin-left: -1.267578125em;
  color: rgb(160, 207, 162);
}
.icon-no-schedule-found .path41:before {
  content: "\eb63";
  margin-left: -1.267578125em;
  color: rgb(217, 236, 218);
}
.icon-no-schedule-found .path42:before {
  content: "\eb64";
  margin-left: -1.267578125em;
  color: rgb(255, 255, 255);
}
.icon-no-schedule-found .path43:before {
  content: "\eb65";
  margin-left: -1.267578125em;
  color: rgb(217, 236, 218);
}
.icon-no-schedule-found .path44:before {
  content: "\eb66";
  margin-left: -1.267578125em;
  color: rgb(255, 255, 255);
}
.icon-no-schedule-found .path45:before {
  content: "\eb67";
  margin-left: -1.267578125em;
  color: rgb(105, 240, 174);
}
.icon-no-schedule-found .path46:before {
  content: "\eb68";
  margin-left: -1.267578125em;
  color: rgb(105, 240, 174);
}
.icon-no-schedule-found .path47:before {
  content: "\eb69";
  margin-left: -1.267578125em;
  color: rgb(105, 240, 174);
}
.icon-no-schedule-found .path48:before {
  content: "\eb6a";
  margin-left: -1.267578125em;
  color: rgb(105, 240, 174);
}
.icon-no-schedule-found .path49:before {
  content: "\eb6b";
  margin-left: -1.267578125em;
  color: rgb(105, 240, 174);
}
.icon-no-schedule-found .path50:before {
  content: "\eb6c";
  margin-left: -1.267578125em;
  color: rgb(67, 160, 71);
  opacity: 0.5;
}
.icon-no-schedule-found .path51:before {
  content: "\eb6d";
  margin-left: -1.267578125em;
  color: rgb(67, 160, 71);
  opacity: 0.2;
}
.icon-no-schedule-found .path52:before {
  content: "\eb6e";
  margin-left: -1.267578125em;
  color: rgb(242, 242, 242);
}
.icon-no-schedule-found .path53:before {
  content: "\eb6f";
  margin-left: -1.267578125em;
  color: rgb(67, 160, 71);
}
.icon-no-schedule-found .path54:before {
  content: "\eb70";
  margin-left: -1.267578125em;
  color: rgb(255, 255, 255);
}
.icon-no-schedule-found .path55:before {
  content: "\eb71";
  margin-left: -1.267578125em;
  color: rgb(0, 0, 0);
}
.icon-no-schedule-found .path56:before {
  content: "\eb72";
  margin-left: -1.267578125em;
  color: rgb(0, 0, 0);
}
.icon-no-schedule-found .path57:before {
  content: "\eb73";
  margin-left: -1.267578125em;
  color: rgb(33, 150, 243);
}
.icon-no-schedule-found .path58:before {
  content: "\eb74";
  margin-left: -1.267578125em;
  color: rgb(0, 0, 0);
}
.icon-no-schedule-found .path59:before {
  content: "\eb75";
  margin-left: -1.267578125em;
  color: rgb(33, 150, 243);
}
.icon-no-schedule-found .path60:before {
  content: "\eb76";
  margin-left: -1.267578125em;
  color: rgb(67, 160, 71);
}
.icon-no-schedule-found .path61:before {
  content: "\eb77";
  margin-left: -1.267578125em;
  color: rgb(0, 0, 0);
}
.icon-no-schedule-found .path62:before {
  content: "\eb78";
  margin-left: -1.267578125em;
  color: rgb(0, 0, 0);
}
.icon-no-schedule-found .path63:before {
  content: "\eb79";
  margin-left: -1.267578125em;
  color: rgb(0, 0, 0);
}
.icon-no-schedule-found .path64:before {
  content: "\eb7a";
  margin-left: -1.267578125em;
  color: rgb(0, 0, 0);
}
.icon-no-schedule-found .path65:before {
  content: "\eb7b";
  margin-left: -1.267578125em;
  color: rgb(0, 0, 0);
}
.icon-no-schedule-found .path66:before {
  content: "\eb7c";
  margin-left: -1.267578125em;
  color: rgb(0, 0, 0);
}
.icon-no-schedule-found .path67:before {
  content: "\eb7d";
  margin-left: -1.267578125em;
  color: rgb(0, 0, 0);
}
.icon-no-schedule-found .path68:before {
  content: "\eb7e";
  margin-left: -1.267578125em;
  color: rgb(105, 240, 174);
}
.icon-no-schedule-found .path69:before {
  content: "\eb7f";
  margin-left: -1.267578125em;
  color: rgb(105, 240, 174);
}
.icon-no-schedule-found .path70:before {
  content: "\eb80";
  margin-left: -1.267578125em;
  color: rgb(105, 240, 174);
}
.icon-no-schedule-found .path71:before {
  content: "\eb81";
  margin-left: -1.267578125em;
  color: rgb(105, 240, 174);
}
.icon-no-schedule-found .path72:before {
  content: "\eb82";
  margin-left: -1.267578125em;
  color: rgb(105, 240, 174);
}
.icon-no-schedule-found .path73:before {
  content: "\eb83";
  margin-left: -1.267578125em;
  color: rgb(105, 240, 174);
}
.icon-no-schedule-found .path74:before {
  content: "\eb84";
  margin-left: -1.267578125em;
  color: rgb(105, 240, 174);
}
.icon-no-schedule-found .path75:before {
  content: "\eb85";
  margin-left: -1.267578125em;
  color: rgb(105, 240, 174);
}
.icon-no-schedule-found .path76:before {
  content: "\eb86";
  margin-left: -1.267578125em;
  color: rgb(105, 240, 174);
}
.icon-no-schedule-found .path77:before {
  content: "\eb87";
  margin-left: -1.267578125em;
  color: rgb(217, 236, 218);
}
.icon-no-schedule-found .path78:before {
  content: "\eb88";
  margin-left: -1.267578125em;
  color: rgb(0, 0, 0);
}
.icon-no-schedule-found .path79:before {
  content: "\eb89";
  margin-left: -1.267578125em;
  color: rgb(105, 240, 174);
}
.icon-no-schedule-found .path80:before {
  content: "\eb8a";
  margin-left: -1.267578125em;
  color: rgb(0, 0, 0);
}
.icon-no-schedule-found .path81:before {
  content: "\eb8b";
  margin-left: -1.267578125em;
  color: rgb(105, 240, 174);
}
.icon-no-schedule-found .path82:before {
  content: "\eb8c";
  margin-left: -1.267578125em;
  color: rgb(105, 240, 174);
}
.icon-no-schedule-found .path83:before {
  content: "\eb8d";
  margin-left: -1.267578125em;
  color: rgb(0, 0, 0);
}
.icon-no-schedule-found .path84:before {
  content: "\eb8e";
  margin-left: -1.267578125em;
  color: rgb(0, 0, 0);
}
.icon-no-schedule-found .path85:before {
  content: "\eb8f";
  margin-left: -1.267578125em;
  color: rgb(105, 240, 174);
}
.icon-no-search-results .path1:before {
  content: "\eb90";
  color: rgb(255, 255, 255);
}
.icon-no-search-results .path2:before {
  content: "\eb91";
  margin-left: -1.095703125em;
  color: rgb(214, 214, 214);
}
.icon-no-search-results .path3:before {
  content: "\eb92";
  margin-left: -1.095703125em;
  color: rgb(242, 242, 242);
}
.icon-no-search-results .path4:before {
  content: "\eb93";
  margin-left: -1.095703125em;
  color: rgb(224, 224, 224);
}
.icon-no-search-results .path5:before {
  content: "\eb94";
  margin-left: -1.095703125em;
  color: rgb(108, 99, 255);
  opacity: 0.7;
}
.icon-no-search-results .path6:before {
  content: "\eb95";
  margin-left: -1.095703125em;
  color: rgb(224, 224, 224);
}
.icon-no-search-results .path7:before {
  content: "\eb96";
  margin-left: -1.095703125em;
  color: rgb(224, 224, 224);
}
.icon-no-search-results .path8:before {
  content: "\eb97";
  margin-left: -1.095703125em;
  color: rgb(224, 224, 224);
}
.icon-no-search-results .path9:before {
  content: "\eb98";
  margin-left: -1.095703125em;
  color: rgb(224, 224, 224);
}
.icon-no-search-results .path10:before {
  content: "\eb99";
  margin-left: -1.095703125em;
  color: rgb(224, 224, 224);
}
.icon-no-search-results .path11:before {
  content: "\eb9a";
  margin-left: -1.095703125em;
  color: rgb(224, 224, 224);
}
.icon-no-search-results .path12:before {
  content: "\eb9b";
  margin-left: -1.095703125em;
  color: rgb(224, 224, 224);
}
.icon-no-search-results .path13:before {
  content: "\eb9c";
  margin-left: -1.095703125em;
  color: rgb(214, 214, 214);
}
.icon-no-search-results .path14:before {
  content: "\eb9d";
  margin-left: -1.095703125em;
  color: rgb(245, 245, 245);
}
.icon-no-search-results .path15:before {
  content: "\eb9e";
  margin-left: -1.095703125em;
  color: rgb(214, 214, 214);
}
.icon-no-search-results .path16:before {
  content: "\eb9f";
  margin-left: -1.095703125em;
  color: rgb(255, 255, 255);
}
.icon-no-search-results .path17:before {
  content: "\eba0";
  margin-left: -1.095703125em;
  color: rgb(108, 99, 255);
  opacity: 0.4;
}
.icon-no-search-results .path18:before {
  content: "\eba1";
  margin-left: -1.095703125em;
  color: rgb(108, 99, 255);
  opacity: 0.4;
}
.icon-no-search-results .path19:before {
  content: "\eba2";
  margin-left: -1.095703125em;
  color: rgb(214, 214, 214);
}
.icon-no-search-results .path20:before {
  content: "\eba3";
  margin-left: -1.095703125em;
  color: rgb(245, 245, 245);
}
.icon-no-search-results .path21:before {
  content: "\eba4";
  margin-left: -1.095703125em;
  color: rgb(214, 214, 214);
}
.icon-no-search-results .path22:before {
  content: "\eba5";
  margin-left: -1.095703125em;
  color: rgb(255, 255, 255);
}
.icon-no-search-results .path23:before {
  content: "\eba6";
  margin-left: -1.095703125em;
  color: rgb(189, 189, 189);
  opacity: 0.4;
}
.icon-no-search-results .path24:before {
  content: "\eba7";
  margin-left: -1.095703125em;
  color: rgb(189, 189, 189);
  opacity: 0.4;
}
.icon-no-search-results .path25:before {
  content: "\eba8";
  margin-left: -1.095703125em;
  color: rgb(214, 214, 214);
}
.icon-no-search-results .path26:before {
  content: "\eba9";
  margin-left: -1.095703125em;
  color: rgb(255, 255, 255);
}
.icon-no-search-results .path27:before {
  content: "\ebaa";
  margin-left: -1.095703125em;
  color: rgb(224, 224, 224);
}
.icon-no-search-results .path28:before {
  content: "\ebab";
  margin-left: -1.095703125em;
  color: rgb(224, 224, 224);
}
.icon-no-search-results .path29:before {
  content: "\ebac";
  margin-left: -1.095703125em;
  color: rgb(224, 224, 224);
}
.icon-no-search-results .path30:before {
  content: "\ebad";
  margin-left: -1.095703125em;
  color: rgb(108, 99, 255);
  opacity: 0.7;
}
.icon-no-search-results .path31:before {
  content: "\ebae";
  margin-left: -1.095703125em;
  color: rgb(108, 99, 255);
  opacity: 0.7;
}
.icon-no-search-results .path32:before {
  content: "\ebaf";
  margin-left: -1.095703125em;
  color: rgb(224, 224, 224);
}
.icon-no-search-results .path33:before {
  content: "\ebb0";
  margin-left: -1.095703125em;
  color: rgb(58, 210, 159);
  opacity: 0.7;
}
.icon-no-search-results .path34:before {
  content: "\ebb1";
  margin-left: -1.095703125em;
  color: rgb(224, 224, 224);
}
.icon-no-search-results .path35:before {
  content: "\ebb2";
  margin-left: -1.095703125em;
  color: rgb(214, 214, 214);
}
.icon-no-search-results .path36:before {
  content: "\ebb3";
  margin-left: -1.095703125em;
  color: rgb(255, 255, 255);
}
.icon-no-search-results .path37:before {
  content: "\ebb4";
  margin-left: -1.095703125em;
  color: rgb(224, 224, 224);
}
.icon-no-search-results .path38:before {
  content: "\ebb5";
  margin-left: -1.095703125em;
  color: rgb(108, 99, 255);
}
.icon-no-search-results .path39:before {
  content: "\ebb6";
  margin-left: -1.095703125em;
  color: rgb(58, 210, 159);
}
.icon-no-search-results .path40:before {
  content: "\ebb7";
  margin-left: -1.095703125em;
  color: rgb(245, 95, 68);
}
.icon-no-search-results .path41:before {
  content: "\ebb8";
  margin-left: -1.095703125em;
  color: rgb(253, 216, 53);
}
.icon-no-search-results .path42:before {
  content: "\ebb9";
  margin-left: -1.095703125em;
  color: rgb(214, 214, 214);
}
.icon-no-search-results .path43:before {
  content: "\ebba";
  margin-left: -1.095703125em;
  color: rgb(214, 214, 214);
}
.icon-no-search-results .path44:before {
  content: "\ebbb";
  margin-left: -1.095703125em;
  color: rgb(214, 214, 214);
}
.icon-no-search-results .path45:before {
  content: "\ebbc";
  margin-left: -1.095703125em;
  color: rgb(108, 99, 255);
}
.icon-no-search-results .path46:before {
  content: "\ebbd";
  margin-left: -1.095703125em;
  color: rgb(108, 99, 255);
}
.icon-no-search-results .path47:before {
  content: "\ebbe";
  margin-left: -1.095703125em;
  color: rgb(108, 99, 255);
}
.icon-no-search-results .path48:before {
  content: "\ebbf";
  margin-left: -1.095703125em;
  color: rgb(71, 230, 177);
}
.icon-no-search-results .path49:before {
  content: "\ebc0";
  margin-left: -1.095703125em;
  color: rgb(77, 138, 240);
}
.icon-no-search-results .path50:before {
  content: "\ebc1";
  margin-left: -1.095703125em;
  color: rgb(253, 216, 53);
}
.icon-no-search-results .path51:before {
  content: "\ebc2";
  margin-left: -1.095703125em;
  color: rgb(253, 216, 53);
}
.icon-no-search-results .path52:before {
  content: "\ebc3";
  margin-left: -1.095703125em;
  color: rgb(245, 95, 68);
}
.icon-no-search-results .path53:before {
  content: "\ebc4";
  margin-left: -1.095703125em;
  color: rgb(245, 95, 68);
}
.icon-no-search-results .path54:before {
  content: "\ebc5";
  margin-left: -1.095703125em;
  color: rgb(77, 138, 240);
}
.icon-no-search-results .path55:before {
  content: "\ebc6";
  margin-left: -1.095703125em;
  color: rgb(71, 230, 177);
  opacity: 0.5;
}
.icon-no-staff:before {
  content: "\ebc7";
}
.icon-no-teetime .path1:before {
  content: "\ebc8";
  color: rgb(217, 236, 218);
}
.icon-no-teetime .path2:before {
  content: "\ebc9";
  margin-left: -1.4423828125em;
  color: rgb(67, 160, 71);
}
.icon-no-teetime .path3:before {
  content: "\ebca";
  margin-left: -1.4423828125em;
  color: rgb(67, 160, 71);
}
.icon-no-teetime .path4:before {
  content: "\ebcb";
  margin-left: -1.4423828125em;
  color: rgb(67, 160, 71);
}
.icon-no-teetime .path5:before {
  content: "\ebcc";
  margin-left: -1.4423828125em;
  color: rgb(67, 160, 71);
}
.icon-no-teetime .path6:before {
  content: "\ebcd";
  margin-left: -1.4423828125em;
  color: rgb(67, 160, 71);
}
.icon-no-teetime .path7:before {
  content: "\ebce";
  margin-left: -1.4423828125em;
  color: rgb(67, 160, 71);
}
.icon-no-teetime .path8:before {
  content: "\ebcf";
  margin-left: -1.4423828125em;
  color: rgb(67, 160, 71);
}
.icon-no-teetime .path9:before {
  content: "\ebd0";
  margin-left: -1.4423828125em;
  color: rgb(67, 160, 71);
}
.icon-no-teetime .path10:before {
  content: "\ebd1";
  margin-left: -1.4423828125em;
  color: rgb(67, 160, 71);
}
.icon-no-teetime .path11:before {
  content: "\ebd2";
  margin-left: -1.4423828125em;
  color: rgb(67, 160, 71);
}
.icon-no-teetime .path12:before {
  content: "\ebd9";
  margin-left: -1.4423828125em;
  color: rgb(67, 160, 71);
}
.icon-no-teetime .path13:before {
  content: "\ebde";
  margin-left: -1.4423828125em;
  color: rgb(67, 160, 71);
}
.icon-no-teetime .path14:before {
  content: "\ebe3";
  margin-left: -1.4423828125em;
  color: rgb(67, 160, 71);
}
.icon-no-teetime .path15:before {
  content: "\ebe4";
  margin-left: -1.4423828125em;
  color: rgb(67, 160, 71);
}
.icon-no-teetime .path16:before {
  content: "\ebe5";
  margin-left: -1.4423828125em;
  color: rgb(67, 160, 71);
}
.icon-no-teetime .path17:before {
  content: "\ebe6";
  margin-left: -1.4423828125em;
  color: rgb(67, 160, 71);
}
.icon-no-teetime .path18:before {
  content: "\ebe7";
  margin-left: -1.4423828125em;
  color: rgb(67, 160, 71);
}
.icon-no-teetime .path19:before {
  content: "\ebe8";
  margin-left: -1.4423828125em;
  color: rgb(67, 160, 71);
}
.icon-no-teetime .path20:before {
  content: "\ebe9";
  margin-left: -1.4423828125em;
  color: rgb(67, 160, 71);
}
.icon-no-teetime .path21:before {
  content: "\ebea";
  margin-left: -1.4423828125em;
  color: rgb(67, 160, 71);
}
.icon-no-teetime .path22:before {
  content: "\ebeb";
  margin-left: -1.4423828125em;
  color: rgb(67, 160, 71);
}
.icon-no-teetime .path23:before {
  content: "\ebec";
  margin-left: -1.4423828125em;
  color: rgb(67, 160, 71);
}
.icon-no-teetime .path24:before {
  content: "\ebed";
  margin-left: -1.4423828125em;
  color: rgb(67, 160, 71);
}
.icon-no-teetime .path25:before {
  content: "\ebee";
  margin-left: -1.4423828125em;
  color: rgb(67, 160, 71);
}
.icon-no-teetime .path26:before {
  content: "\ebef";
  margin-left: -1.4423828125em;
  color: rgb(67, 160, 71);
}
.icon-no-teetime .path27:before {
  content: "\ebf0";
  margin-left: -1.4423828125em;
  color: rgb(67, 160, 71);
}
.icon-no-teetime .path28:before {
  content: "\ebf1";
  margin-left: -1.4423828125em;
  color: rgb(67, 160, 71);
}
.icon-no-teetime .path29:before {
  content: "\ebf2";
  margin-left: -1.4423828125em;
  color: rgb(67, 160, 71);
}
.icon-no-teetime .path30:before {
  content: "\ebf3";
  margin-left: -1.4423828125em;
  color: rgb(67, 160, 71);
}
.icon-no-teetime .path31:before {
  content: "\ebf4";
  margin-left: -1.4423828125em;
  color: rgb(67, 160, 71);
}
.icon-no-teetime .path32:before {
  content: "\ebf5";
  margin-left: -1.4423828125em;
  color: rgb(67, 160, 71);
}
.icon-no-teetime .path33:before {
  content: "\ebf6";
  margin-left: -1.4423828125em;
  color: rgb(67, 160, 71);
}
.icon-no-teetime .path34:before {
  content: "\ebf7";
  margin-left: -1.4423828125em;
  color: rgb(67, 160, 71);
}
.icon-no-teetime .path35:before {
  content: "\ebf8";
  margin-left: -1.4423828125em;
  color: rgb(68, 64, 83);
}
.icon-no-teetime .path36:before {
  content: "\ebf9";
  margin-left: -1.4423828125em;
  color: rgb(67, 160, 71);
}
.icon-no-teetime .path37:before {
  content: "\ebfa";
  margin-left: -1.4423828125em;
  color: rgb(67, 160, 71);
}
.icon-no-teetime .path38:before {
  content: "\ebfb";
  margin-left: -1.4423828125em;
  color: rgb(67, 160, 71);
}
.icon-no-teetime .path39:before {
  content: "\ebfc";
  margin-left: -1.4423828125em;
  color: rgb(63, 61, 86);
}
.icon-no-teetime .path40:before {
  content: "\ebfd";
  margin-left: -1.4423828125em;
  color: rgb(63, 61, 86);
}
.icon-no-teetime .path41:before {
  content: "\ebfe";
  margin-left: -1.4423828125em;
  color: rgb(63, 61, 86);
}
.icon-no-teetime .path42:before {
  content: "\ebff";
  margin-left: -1.4423828125em;
  color: rgb(63, 61, 86);
}
.icon-no-teetime .path43:before {
  content: "\ec00";
  margin-left: -1.4423828125em;
  color: rgb(63, 61, 86);
}
.icon-no-teetime .path44:before {
  content: "\ec01";
  margin-left: -1.4423828125em;
  color: rgb(63, 61, 86);
}
.icon-no-teetime .path45:before {
  content: "\ec02";
  margin-left: -1.4423828125em;
  color: rgb(63, 61, 86);
}
.icon-no-teetime .path46:before {
  content: "\ec03";
  margin-left: -1.4423828125em;
  color: rgb(67, 160, 71);
}
.icon-no-teetime .path47:before {
  content: "\ec04";
  margin-left: -1.4423828125em;
  color: rgb(67, 160, 71);
}
.icon-no-teetime .path48:before {
  content: "\ec05";
  margin-left: -1.4423828125em;
  color: rgb(255, 255, 255);
}
.icon-no-teetime .path49:before {
  content: "\ec06";
  margin-left: -1.4423828125em;
  color: rgb(229, 229, 229);
}
.icon-no-teetime .path50:before {
  content: "\ec07";
  margin-left: -1.4423828125em;
  color: rgb(229, 229, 229);
}
.icon-no-teetime .path51:before {
  content: "\ec08";
  margin-left: -1.4423828125em;
  color: rgb(229, 229, 229);
}
.icon-no-teetime .path52:before {
  content: "\ec09";
  margin-left: -1.4423828125em;
  color: rgb(229, 229, 229);
}
.icon-no-teetime .path53:before {
  content: "\ec0a";
  margin-left: -1.4423828125em;
  color: rgb(229, 229, 229);
}
.icon-no-teetime .path54:before {
  content: "\ec0b";
  margin-left: -1.4423828125em;
  color: rgb(67, 160, 71);
}
.icon-no-teetime .path55:before {
  content: "\ec0c";
  margin-left: -1.4423828125em;
  color: rgb(229, 229, 229);
}
.icon-no-teetime .path56:before {
  content: "\ec0d";
  margin-left: -1.4423828125em;
  color: rgb(229, 229, 229);
}
.icon-no-teetime .path57:before {
  content: "\ec0e";
  margin-left: -1.4423828125em;
  color: rgb(229, 229, 229);
}
.icon-no-teetime .path58:before {
  content: "\ec0f";
  margin-left: -1.4423828125em;
  color: rgb(67, 160, 71);
}
.icon-no-teetime .path59:before {
  content: "\ec10";
  margin-left: -1.4423828125em;
  color: rgb(56, 123, 59);
}
.icon-no-teetime .path60:before {
  content: "\ec11";
  margin-left: -1.4423828125em;
  color: rgb(56, 123, 59);
}
.icon-noshows:before {
  content: "\ec12";
}
.icon-note:before {
  content: "\ec13";
}
.icon-note1:before {
  content: "\ec14";
  color: #666;
}
.icon-notification:before {
  content: "\ec15";
}
.icon-offer-redemption:before {
  content: "\ed9d";
}
.icon-open-book-2:before {
  content: "\ec16";
}
.icon-option:before {
  content: "\ec17";
  color: #818892;
}
.icon-others:before {
  content: "\ec18";
}
.icon-overbook:before {
  content: "\ec19";
}
.icon-padlock:before {
  content: "\ec1a";
  color: #666;
}
.icon-paid:before {
  content: "\ec1b";
}
.icon-paid1:before {
  content: "\ec1c";
}
.icon-password:before {
  content: "\ec1d";
}
.icon-password1:before {
  content: "\ec1e";
  color: #818892;
}
.icon-password-hide:before {
  content: "\ec1f";
}
.icon-password-show:before {
  content: "\ec20";
}
.icon-password11:before {
  content: "\ec21";
}
.icon-pay:before {
  content: "\ec22";
}
.icon-pay-card:before {
  content: "\ed32";
}
.icon-pay-cash:before {
  content: "\ed31";
}
.icon-pay-cash1:before {
  content: "\ed8e";
}
.icon-pay-coupons:before {
  content: "\ed30";
}
.icon-pay-gift-card:before {
  content: "\ed2f";
}
.icon-pay-others:before {
  content: "\ecb0";
}
.icon-pay-others1:before {
  content: "\ecb2";
}
.icon-pay-pending-settlement:before {
  content: "\ed2d";
}
.icon-pay-points:before {
  content: "\ece3";
}
.icon-pay-points1:before {
  content: "\ed2c";
}
.icon-pay-room-charge:before {
  content: "\ed2b";
}
.icon-pdf:before {
  content: "\ec23";
}
.icon-percent:before {
  content: "\ec24";
}
.icon-phone:before {
  content: "\ec25";
}
.icon-placeholder:before {
  content: "\ec26";
}
.icon-player:before {
  content: "\ec27";
}
.icon-plus:before {
  content: "\ec28";
}
.icon-policy:before {
  content: "\edbc";
  color: #1d3faa;
}
.icon-preview:before {
  content: "\ec29";
}
.icon-previous:before {
  content: "\ec2a";
}
.icon-print:before {
  content: "\ec2b";
}
.icon-print11:before {
  content: "\ec2c";
}
.icon-print12:before {
  content: "\ef42";
}
.icon-printChit:before {
  content: "\ec2d";
}
.icon-private_class_booking_icon:before {
  content: "\ee6c";
  color: #fff;
}
.icon-profile:before {
  content: "\ec2e";
}
.icon-qr_code_scanner_FILL0_wght400_GRAD0_opsz48:before {
  content: "\f330";
}
.icon-question:before {
  content: "\ec2f";
  color: #fff;
}
.icon-radio-checked:before {
  content: "\ec30";
  color: #666;
}
.icon-radio-unchecked1:before {
  content: "\ec31";
  color: #666;
}
.icon-rain .path1:before {
  content: "\ec7f";
  color: rgb(255, 193, 7);
}
.icon-rain .path2:before {
  content: "\ec80";
  margin-left: -1em;
  color: rgb(227, 242, 253);
}
.icon-rain .path3:before {
  content: "\ec81";
  margin-left: -1em;
  color: rgb(33, 150, 243);
}
.icon-rain .path4:before {
  content: "\ec82";
  margin-left: -1em;
  color: rgb(33, 150, 243);
}
.icon-rain .path5:before {
  content: "\ec83";
  margin-left: -1em;
  color: rgb(33, 150, 243);
}
.icon-rain-check:before {
  content: "\ec32";
}
.icon-rainy:before {
  content: "\ec33";
}
.icon-rate:before {
  content: "\ec34";
}
.icon-rate1:before {
  content: "\ec35";
}
.icon-recap:before {
  content: "\ec36";
}
.icon-recent-history:before {
  content: "\ec37";
}
.icon-recurring:before {
  content: "\ec38";
}
.icon-redo:before {
  content: "\ec39";
}
.icon-refresh:before {
  content: "\ed0a";
  color: #818892;
}
.icon-refund:before {
  content: "\ec54";
  color: #1da664;
}
.icon-repeat:before {
  content: "\ec55";
}
.icon-repeat-client:before {
  content: "\ec56";
}
.icon-report:before {
  content: "\ec57";
  color: #fff;
}
.icon-reports:before {
  content: "\ec58";
}
.icon-request-staff:before {
  content: "\ec59";
}
.icon-res-payment-Paid:before {
  content: "\ec5a";
  color: #1da664;
}
.icon-res-payment-aurthorized:before {
  content: "\ec5b";
  color: #dda688;
}
.icon-res-payment-notpaid:before {
  content: "\ec5c";
  color: #f44336;
}
.icon-reset:before {
  content: "\ec5d";
}
.icon-retention:before {
  content: "\edba";
  color: #1d3faa;
}
.icon-revenue:before {
  content: "\ec5e";
}
.icon-revenue2:before {
  content: "\ec5f";
  color: #f88b2d;
}
.icon-right-arrow:before {
  content: "\ec60";
}
.icon-right-arrow-dashboard:before {
  content: "\ec61";
}
.icon-right-arrow1:before {
  content: "\ec62";
}
.icon-right-arrow2:before {
  content: "\ec63";
}
.icon-right-dashboard-arrow:before {
  content: "\ec64";
}
.icon-room-desc:before {
  content: "\ed13";
  color: #818892;
}
.icon-room-picture:before {
  content: "\ed14";
  color: #818892;
}
.icon-save:before {
  content: "\ec84";
}
.icon-scattredcloud:before {
  content: "\ec85";
  color: #a3d4f7;
}
.icon-scheduled:before {
  content: "\ec86";
}
.icon-scheduledclock:before {
  content: "\ec87";
}
.icon-search:before {
  content: "\ec88";
}
.icon-sendmail:before {
  content: "\ec89";
}
.icon-servant-outline:before {
  content: "\ec8a";
  color: #707070;
}
.icon-serviceaddon:before {
  content: "\ec8b";
}
.icon-servicecharge:before {
  content: "\ec8c";
}
.icon-settings:before {
  content: "\ec8d";
}
.icon-settings-outline:before {
  content: "\ec8e";
  color: #666;
}
.icon-setup:before {
  content: "\ece4";
}
.icon-share-1:before {
  content: "\f325";
}
.icon-shop:before {
  content: "\ec8f";
}
.icon-shop-cart:before {
  content: "\edb5";
}
.icon-shop-commission:before {
  content: "\edb6";
  color: #435444;
}
.icon-shop-gratuity:before {
  content: "\edb7";
  color: #435444;
}
.icon-shop-servicecharge:before {
  content: "\edbf";
  color: #435444;
}
.icon-shop1:before {
  content: "\ec90";
}
.icon-shop11:before {
  content: "\ec91";
}
.icon-shop12:before {
  content: "\ef15";
}
.icon-shower-rain .path1:before {
  content: "\ec92";
  color: rgb(132, 171, 193);
}
.icon-shower-rain .path2:before {
  content: "\ec93";
  margin-left: -1em;
  color: rgb(77, 135, 161);
}
.icon-shower-rain .path3:before {
  content: "\ec94";
  margin-left: -1em;
  color: rgb(132, 171, 193);
}
.icon-shower-rain .path4:before {
  content: "\ec95";
  margin-left: -1em;
  color: rgb(77, 135, 161);
}
.icon-shower-rain .path5:before {
  content: "\ec96";
  margin-left: -1em;
  color: rgb(132, 171, 193);
}
.icon-shower-rain .path6:before {
  content: "\ec97";
  margin-left: -1em;
  color: rgb(77, 135, 161);
}
.icon-shower-rain .path7:before {
  content: "\ec98";
  margin-left: -1em;
  color: rgb(132, 171, 193);
}
.icon-shower-rain .path8:before {
  content: "\ec99";
  margin-left: -1em;
  color: rgb(77, 135, 161);
}
.icon-shower-rain .path9:before {
  content: "\ec9a";
  margin-left: -1em;
  color: rgb(132, 171, 193);
}
.icon-shower-rain .path10:before {
  content: "\ec9b";
  margin-left: -1em;
  color: rgb(77, 135, 161);
}
.icon-shower-rain .path11:before {
  content: "\ec9c";
  margin-left: -1em;
  color: rgb(132, 171, 193);
}
.icon-shower-rain .path12:before {
  content: "\ec9d";
  margin-left: -1em;
  color: rgb(77, 135, 161);
}
.icon-shower-rain .path13:before {
  content: "\ec9e";
  margin-left: -1em;
  color: rgb(132, 171, 193);
}
.icon-shower-rain .path14:before {
  content: "\ec9f";
  margin-left: -1em;
  color: rgb(77, 135, 161);
}
.icon-shower-rain .path15:before {
  content: "\eca0";
  margin-left: -1em;
  color: rgb(132, 171, 193);
}
.icon-shower-rain .path16:before {
  content: "\eca1";
  margin-left: -1em;
  color: rgb(77, 135, 161);
}
.icon-shower-rain .path17:before {
  content: "\eca2";
  margin-left: -1em;
  color: rgb(132, 171, 193);
}
.icon-shower-rain .path18:before {
  content: "\eca3";
  margin-left: -1em;
  color: rgb(211, 221, 234);
}
.icon-shower-rain .path19:before {
  content: "\eca4";
  margin-left: -1em;
  color: rgb(132, 171, 193);
}
.icon-snow:before {
  content: "\eca5";
}
.icon-snows:before {
  content: "\eca6";
  color: #80c7ea;
}
.icon-sort-arrow:before {
  content: "\eca7";
  color: #6b6b6b;
}
.icon-spa-package:before {
  content: "\eca8";
}
.icon-sphere:before {
  content: "\ed2a";
}
.icon-sqeeze:before {
  content: "\eca9";
}
.icon-square:before {
  content: "\ecaa";
}
.icon-star:before {
  content: "\ecab";
  color: #eb8808;
}
.icon-success-icon:before {
  content: "\ecac";
}
.icon-sunny:before {
  content: "\ece5";
  color: #ffd800;
}
.icon-swap:before {
  content: "\ece6";
}
.icon-swap1:before {
  content: "\ece7";
}
.icon-swap2:before {
  content: "\effa";
}
.icon-sync:before {
  content: "\edaf";
  color: #525962;
}
.icon-tee-time:before {
  content: "\ece8";
}
.icon-temp_hold:before {
  content: "\ece9";
  color: #f00;
}
.icon-therapists:before {
  content: "\ecea";
}
.icon-thuderstorm .path1:before {
  content: "\eceb";
  color: rgb(160, 236, 255);
}
.icon-thuderstorm .path2:before {
  content: "\ecec";
  margin-left: -1em;
  color: rgb(160, 236, 255);
}
.icon-thuderstorm .path3:before {
  content: "\eced";
  margin-left: -1em;
  color: rgb(160, 236, 255);
}
.icon-thuderstorm .path4:before {
  content: "\ecee";
  margin-left: -1em;
  color: rgb(160, 236, 255);
}
.icon-thuderstorm .path5:before {
  content: "\ecef";
  margin-left: -1em;
  color: rgb(246, 203, 67);
}
.icon-thuderstorm .path6:before {
  content: "\ecf0";
  margin-left: -1em;
  color: rgb(250, 176, 60);
}
.icon-thuderstorm .path7:before {
  content: "\ecf1";
  margin-left: -1em;
  color: rgb(160, 236, 255);
}
.icon-thuderstorm .path8:before {
  content: "\ecf2";
  margin-left: -1em;
  color: rgb(160, 236, 255);
}
.icon-thuderstorm .path9:before {
  content: "\ecf3";
  margin-left: -1em;
  color: rgb(160, 236, 255);
}
.icon-thuderstorm .path10:before {
  content: "\ecf4";
  margin-left: -1em;
  color: rgb(109, 109, 109);
}
.icon-thuderstorm .path11:before {
  content: "\ecf5";
  margin-left: -1em;
  color: rgb(86, 86, 86);
}
.icon-thuderstorm .path12:before {
  content: "\ecf6";
  margin-left: -1em;
  color: rgb(109, 109, 109);
}
.icon-thuderstorm .path13:before {
  content: "\ecf7";
  margin-left: -1em;
  color: rgb(109, 109, 109);
}
.icon-thuderstorm .path14:before {
  content: "\ecf8";
  margin-left: -1em;
  color: rgb(160, 157, 156);
}
.icon-thuderstorm .path15:before {
  content: "\ecf9";
  margin-left: -1em;
  color: rgb(130, 128, 127);
}
.icon-thuderstorm .path16:before {
  content: "\ecfa";
  margin-left: -1em;
  color: rgb(160, 236, 255);
}
.icon-thuderstorm .path17:before {
  content: "\ecfb";
  margin-left: -1em;
  color: rgb(160, 236, 255);
}
.icon-thuderstorm .path18:before {
  content: "\ecfc";
  margin-left: -1em;
  color: rgb(160, 236, 255);
}
.icon-thunder .path1:before {
  content: "\ecfd";
  color: rgb(255, 216, 59);
}
.icon-thunder .path2:before {
  content: "\ecfe";
  margin-left: -1.0595703125em;
  color: rgb(0, 0, 0);
}
.icon-thunder .path3:before {
  content: "\ecff";
  margin-left: -1.0595703125em;
  color: rgb(0, 0, 0);
}
.icon-thunder .path4:before {
  content: "\ed00";
  margin-left: -1.0595703125em;
  color: rgb(0, 0, 0);
}
.icon-time-delay:before {
  content: "\ed01";
}
.icon-timepicker:before {
  content: "\ed02";
}
.icon-tournament-player:before {
  content: "\ed03";
}
.icon-transaction:before {
  content: "\ed04";
}
.icon-undo:before {
  content: "\ed05";
}
.icon-up-arrow:before {
  content: "\ed06";
}
.icon-up-arrow1:before {
  content: "\ed07";
}
.icon-up-arrow2:before {
  content: "\efb6";
}
.icon-user:before {
  content: "\ed09";
}
.icon-v1-gift-card:before {
  content: "\ed15";
}
.icon-viewPassword:before {
  content: "\ed17";
  color: #666;
}
.icon-vip:before {
  content: "\ed18";
}
.icon-visa .path1:before {
  content: "\ed23";
  color: rgb(0, 88, 160);
}
.icon-visa .path2:before {
  content: "\ed24";
  margin-left: -1.5em;
  color: rgb(255, 255, 255);
}
.icon-visa .path3:before {
  content: "\ed25";
  margin-left: -1.5em;
  color: rgb(0, 88, 160);
}
.icon-visa .path4:before {
  content: "\ed26";
  margin-left: -1.5em;
  color: rgb(0, 88, 160);
}
.icon-visa .path5:before {
  content: "\ed27";
  margin-left: -1.5em;
  color: rgb(0, 88, 160);
}
.icon-visa .path6:before {
  content: "\ed28";
  margin-left: -1.5em;
  color: rgb(0, 88, 160);
}
.icon-visa .path7:before {
  content: "\ed29";
  margin-left: -1.5em;
  color: rgb(250, 166, 26);
}
.icon-warning-icon:before {
  content: "\ed19";
}
.icon-warning-info-icon:before {
  content: "\ed1a";
}
.icon-warning1:before {
  content: "\ed1c";
  color: #cf000f;
}
.icon-wax:before {
  content: "\ed1d";
}
.icon-woman:before {
  content: "\ed1e";
}
.icon-zoom-in:before {
  content: "\ed1f";
}
.icon-zoom-in1:before {
  content: "\ed20";
}
.icon-zoom-in2:before {
  content: "\ed21";
}
.icon-zoom-out:before {
  content: "\ed22";
}
.icon-zoom-out1:before {
  content: "\ed2e";
}
.icon-zoom-out2:before {
  content: "\ed8f";
}
.icon-language-filled:before {
  content: "\e90d";
  color: #1da664;
}
.icon-respos-cart-error:before {
  content: "\edb9";
}
.icon-respos-package-error:before {
  content: "\edc0";
}
.icon-respos-package:before {
  content: "\edc1";
}
.icon-respos-print-ticket:before {
  content: "\edc2";
}
.icon-respos-add-ticket:before {
  content: "\edc3";
}
.icon-respos-home:before {
  content: "\edc4";
}
.icon-respos-time:before {
  content: "\edc5";
}
.icon-respos-language:before {
  content: "\edc6";
}
.icon-respos-visibility:before {
  content: "\edc7";
}
.icon-respos-visibility-off:before {
  content: "\edc9";
}
.icon-respos-cloud-upload:before {
  content: "\edca";
}
.icon-respos-image:before {
  content: "\edcb";
}
.icon-respos-menu:before {
  content: "\edcc";
}
.icon-respos-add:before {
  content: "\edcd";
}
.icon-respos-add_circle:before {
  content: "\edce";
}
.icon-respos-calendar:before {
  content: "\edcf";
}
.icon-respos-call:before {
  content: "\edd0";
}
.icon-respos-check_circle:before {
  content: "\edd1";
}
.icon-respos-chevron_left:before {
  content: "\ede6";
}
.icon-respos-chevron_right:before {
  content: "\ede7";
}
.icon-respos-close:before {
  content: "\ede3";
}
.icon-respos-dashboard:before {
  content: "\ede2";
}
.icon-respos-delete:before {
  content: "\eddf";
}
.icon-respos-edit:before {
  content: "\edde";
}
.icon-respos-error:before {
  content: "\eddd";
}
.icon-respos-expand_more:before {
  content: "\eddc";
}
.icon-respos-filter:before {
  content: "\eddb";
}
.icon-respos-help:before {
  content: "\edda";
}
.icon-respos-info:before {
  content: "\edd9";
}
.icon-respos-inventory:before {
  content: "\edd8";
}
.icon-respos-logout:before {
  content: "\edd7";
}
.icon-respos-mail:before {
  content: "\edd6";
}
.icon-respos-manufacturing:before {
  content: "\edd5";
}
.icon-respos-more_vert:before {
  content: "\edd4";
}
.icon-respos-person:before {
  content: "\edd3";
}
.icon-respos-refresh:before {
  content: "\edd2";
}
.icon-respos-remove:before {
  content: "\ede5";
}
.icon-respos-schedule:before {
  content: "\ede8";
}
.icon-respos-search:before {
  content: "\ede4";
}
.icon-respos-shopping_cart:before {
  content: "\ede1";
}
.icon-respos-warning:before {
  content: "\ede0";
}
.icon-Dining:before {
  content: "\f333";
}
.icon-golfing:before {
  content: "\f336";
}
.icon-Card-filled:before {
  content: "\edf7";
}
.icon-card-list:before {
  content: "\edf6";
}
.icon-Card-empty:before {
  content: "\edf3";
}
.icon-card-add-new:before {
  content: "\edf4";
}
.icon-Card-empty-1:before {
  content: "\edf5";
}
.icon-group:before {
  content: "\edef";
  color: #5f6368;
}
.icon-group-chat:before {
  content: "\edf1";
}
